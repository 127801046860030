import React from "react";
import UpdateCongeCard from "../../../components/organism/UpdateCongeCard";
import {
    destructFields,
    generateFields,
    Validation,
} from "../../../_helpers/FormHelpers";
import moment from "moment";
import { useHoliday } from "../../../hooks/useHolidays";
import { useNotificationContext } from "../../../Context/Notification";
import Swal from "sweetalert2";

const UpdateConge = ({
    display,
    onClose,
    conge,
    closeVacationRequest,
    siteId,
    licenseId,
}: UpdateCongeInterface) => {
    const { updateConge, getHolidays, error } = useHoliday();
    const { addNotification } = useNotificationContext();
    const [congeData, setCongeData] = React.useState<any>(null);
    React.useEffect(() => {
        if (display && conge) {
            const newState = generateFields([
                { name: "employeeId", value: conge?.user?._id },
                { name: "typeConge", value: conge?.type },
                { name: "typeday", value: conge?.allDay },
                { name: "startDate", value: conge?.fromDay },
                { name: "endDate", value: conge?.toDay },
                { name: "fromHour", value: conge?.fromHour },
                { name: "toHour", value: conge?.toHour },
                { name: "note", value: conge?.message },
            ]);

            setCongeData(newState);
            validate(newState);
        }
    }, [display]);

    const congeTypes = [
        { label: "Arrêt maladie", value: "arret_maladie" },
        { label: "Congé maternité", value: "conge_maternite" },
        { label: "Congé paternité", value: "conge_paternite" },
        { label: "Congé payé", value: "paid_leave" },
        { label: "Congé sans solde", value: "unpaid_leave" },
        { label: "Évènement familial", value: "family_event" },
    ];
    const onSubmit = () => {
        const values = destructFields(congeData);

        if (values?.typeday === false) {
            values.endDate = values.startDate;
        }

        updateConge({
            _id: conge?._id,
            userId: values?.employeeId,
            fromDay: moment(values?.startDate)?.toISOString(),
            fromHour: values?.fromHour,
            toDay: moment(values?.endDate)?.toISOString(),
            toHour: values?.toHour,
            allDay: values?.typeday,
            type: values?.typeConge,
            message: values?.note,
            licenseId: licenseId,
        })
            .unwrap()
            .then((res: any) => {
                const data: any = res.payload;
                if (data?.status === "success") {
                    addNotification({
                        title: "modification d'un congé",
                        message: "Congé modifier avec succès",
                        type: "success",
                    });
                    getHolidays(
                        null,
                        "0",
                        null,
                        null,
                        licenseId,
                        siteId ? [siteId] : null,
                    );
                }
                handleDelete();
                closeVacationRequest?.();
            })
            .catch((error: any) => {
                const errorMessage =
                    error.payload ||
                    "Une erreur s'est produite lors de la soumission.";
                Swal.fire({
                    title: "Erreur!",
                    text: errorMessage,
                    icon: "error",
                    timer: 1500,
                    timerProgressBar: true,
                });
            });
    };

    const onChange = (field: string, value: any) => {
        if (congeData) {
            const newState = {
                ...congeData,
                [field]: {
                    ...congeData[field],
                    value,
                },
            };
            setCongeData(newState);
            validate(newState);
        }
    };

    const onBlur = (field: string) => {
        if (congeData) {
            const newState = {
                ...congeData,
                [field]: {
                    ...congeData[field],
                    touched: true,
                },
            };
            setCongeData(newState);
            validate(newState);
        }
    };

    const validate = (congeData: any) => {
        if (congeData) {
            new Validation()
                .add(congeData.typeConge, [
                    { REQUIRED: true, message: "Ce champ est obligatoire" },
                ])
                .validate((res: any) => {
                    setCongeData((prev: any) => ({
                        ...prev,
                        ...generateFields(res),
                    }));
                });
        }
    };

    const handleDelete = () => {
        setCongeData(null);

        if (onClose && typeof onClose === "function") {
            onClose();
        }
    };

    return (
        <div>
            {congeData && (
                <UpdateCongeCard
                    onOpen={display}
                    onChange={onChange}
                    onBlur={onBlur}
                    congeData={congeData}
                    congeTypes={congeTypes}
                    onSubmit={onSubmit}
                    onReset={handleDelete}
                    conge={conge}
                />
            )}
        </div>
    );
};

interface UpdateCongeInterface {
    display?: boolean;
    conge: any;
    onClose?: () => void;
    closeVacationRequest?: () => void;
    siteId: any;
    licenseId: string;
}
export default UpdateConge;
