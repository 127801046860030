import "./style.scss";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import WarningGIF from "./warning.gif";
import Picture from "../../atoms/Picture";

export default function HolidayDeletionAlert({
    display,
    headerTitle,
    content,
    textButton1,
    handleOnConfirm,
    onClose,
}: HolidayDeletionAlertInterface) {
    const HeaderTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "40px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    {headerTitle}
                </TextData>
            </div>
        );
    };
    return (
        <Modal modalEnabled={display} onClose={onClose}>
            <div className="attendance-container">
                <PopUpCard
                    display={display}
                    onClose={onClose}
                    containerWidth="450px"
                    header={<HeaderTitle />}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text={"Annuler"}
                    onClickButton1={onClose}
                    button2Text={textButton1}
                    onClickButton2={handleOnConfirm}
                >
                    <div className="planning-delete-container">
                        <Picture
                            imgPath={WarningGIF}
                            width="240px"
                            height="240px"
                        />
                        <TextData
                            variant="div"
                            width="auto"
                            color="#6F78AB"
                            size="20px"
                            fontWeight="400"
                        >
                            {content}
                        </TextData>
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}

interface HolidayDeletionAlertInterface {
    display?: boolean;
    onClose?: (event?: Event) => void;
    headerTitle?: string;
    content?: string;
    handleOnConfirm?: () => void;
    textButton1?: string;
}
