import React from "react";
import Datatable from "../../../components/organism/Datatable";
import { SortByProps, useEmployees } from "../../../hooks/useEmployees";
import * as columnFormatters from "./column-formatters";
import { getRangeData } from "../../../_helpers/TableHelpers";
import { useIntl } from "react-intl";
import EmployeesNoRecord from "../../../components/templates/EmployeesNoRecord";

export default function EmployeesArchiveTable({
    openRestoreEmployeeDialog,
    openEditEmployee,
    queryParams,
    onQueryParamsChange,
    archived,
    openCreateEmployeeDialog,
    onUpdateSortBy,
}: EmployeesArchiveTableInterface) {
    const intl = useIntl();

    const columns = [
        {
            dataField: "fname",
            text: intl.formatMessage({ id: "MENU.FIRSTNAME_AND_LASTNAME" }),
            formatter: columnFormatters.NameColumnFormatter,
            sort: true,
        },
        {
            dataField: "role",
            text: intl.formatMessage({ id: "MENU.ROLE" }),
            formatter: columnFormatters.RoleColumnFormatter,
            sort: true,
            getCellClassName: () => "!max-w-[140px]",
        },
        {
            dataField: "email",
            text: intl.formatMessage({ id: "MENU.EMAIL_ADDRESS" }),
            formatter: columnFormatters.EmailColumnFormatter,
            sort: true,
        },
        {
            dataField: "phone",
            text: intl.formatMessage({ id: "MENU.PHONE" }),
            formatter: columnFormatters.PhoneColumnFormatter,
            sort: true,
        },
        // {
        //     dataField: "hr",
        //     text: intl.formatMessage({ id: "MENU.HR_FOLDER" }),
        //     formatter: columnFormatters.ProfileStatsColumnFormatter,
        //     sort: true,
        // },
        {
            dataField: "userScore",
            text: intl.formatMessage({ id: "MENU.HR_FOLDER" }),
            formatter: columnFormatters.ProfileStatsColumnFormatter,
            sort: true,
        },
        {
            dataField: "updatedAt",
            text: intl.formatMessage({ id: "MENU.ARCHIVE_DATE" }),
            formatter: columnFormatters.ArchiveDateColumnFormatter,
            sort: true,
        },
        {
            dataField: "actions",
            text: " ",
            formatter: columnFormatters.ActionColumnFormatter,
            formatExtraData: {
                openRestoreEmployeeDialog,
                openEditEmployee,
            },
        },
    ];

    return (
        <div>
            {archived.length ? (
                <Datatable
                    data={getRangeData(
                        archived,
                        queryParams.page,
                        queryParams.sizePerPage,
                    )}
                    columns={columns}
                    rowClasses={(row, id) => {
                        return id % 2 == 0 ? "bg-white" : "bg-light-border";
                    }}
                    sortField={queryParams.sortField}
                    sortOrder={queryParams.sortOrder}
                    onTableSort={(field, order) => {
                        onUpdateSortBy({
                            field,
                            order,
                        });
                    }}
                />
            ) : (
                <EmployeesNoRecord
                    openCreateEmployesDialog={openCreateEmployeeDialog}
                    active={false}
                />
            )}
        </div>
    );
}

interface EmployeesArchiveTableInterface {
    archived: any[];
    openRestoreEmployeeDialog: (id: number) => void;
    openEditEmployee: (id: number) => void;
    queryParams: {
        page: number;
        sizePerPage: number;
        sortOrder: string;
        sortField: string;
    };
    onQueryParamsChange: (data: any) => void;
    openCreateEmployeeDialog: () => void;
    onUpdateSortBy: (value: SortByProps) => void;
}
