import React from "react";
import StepButton from "../../atoms/StepButton";
import "./index.scss";

export default function Stepper({
    steps,
    nextStep,
    prevStep,
    currentStep = 0,
    width = 32,
    height = 32,
}: StepperInterface) {
    return (
        <div className="flex py-5">
            {/* Steps */}
            <nav style={{ position: "absolute" }}>
                {steps.map((step, keyS) => (
                    <div key={keyS}>
                        {keyS > 0 && (
                            <span
                                className={`inline-block h-[120px] ml-[17px] w-[2px] ${
                                    currentStep >= keyS
                                        ? "bg-default-active"
                                        : "bg-g-100"
                                }`}
                            />
                        )}
                        <div
                            className={`flex items-center relative md:mr-[150px]`}
                        >
                            <StepButton
                                current={currentStep == keyS}
                                checked={currentStep > keyS}
                            />
                            <div className={`absolute left-[50px] w-[100px]`}>
                                <div
                                    className={`${
                                        currentStep == keyS
                                            ? "text-n-700"
                                            : "text-n-500"
                                    } relative  text-lg font-semibold max-w-[160px] hidden md:block`}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={
                                        keyS === 1 && currentStep === 0
                                            ? nextStep
                                            : keyS === 0 && currentStep === 1
                                              ? prevStep
                                              : undefined
                                    }
                                >
                                    {step.title}
                                    <div className="absolute top-[-22px]">
                                        <span className="text-n-500 font-medium text-sm">
                                            Étape {keyS + 1}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </nav>
            {steps[currentStep].component}
        </div>
    );
}

interface StepperInterface {
    steps: Array<StepInterface>;
    currentStep: number;
    width?: number;
    height?: number;
    nextStep?: any;
    prevStep?: any;
}

interface StepInterface {
    title: string;
    component: React.ReactNode;
}
