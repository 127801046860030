import React from "react";
import Primes from "../../../components/templates/EmployeesEdit/Primes";
import { useEmployees } from "../../../hooks/useEmployees";
import NoPrimesFound from "./NoPrimesFound";
import PrimesTable from "./PrimesTable";
import DeletePrimeDialog from "./DeletePrimeDialog";
import { CreateEmployeePrimes } from "../../../redux/features/employees/types";
import {
    generateFields,
    Validation,
    destructFields,
} from "../../../_helpers/FormHelpers";
import moment from "moment";
import EditPrimesDialog from "../../../components/templates/EmployeesEdit/EditPrimesDialog";
import EditPrimeForm from "../../../components/templates/EmployeesEdit/EditPrimeForm";
import { useNotificationContext } from "../../../Context/Notification";
import { useIntl } from "react-intl";
import { useBranch } from "../../../hooks/useBranch";

export default function PrimesPages() {
    const {
        primes,
        fetchPrimes,
        employeeForEdit,
        actionLoading,
        primeTypes,
        updatePrime,
        createPrime,
    } = useEmployees();

    const { addNotification } = useNotificationContext();
    const intl = useIntl();
    const [primeForDelete, setPrimeForDelete] = React.useState();
    const [primeForEdit, setPrimeForEdit] = React.useState<any>(null);

    const getPrimeTypesValue = (value: string) => {
        if (value === "") return "";
        let index = primeTypes?.findIndex((val) => val._id === value) || -1;
        if (index !== -1) {
            return { label: primeTypes[index]?.name ?? "", value };
        }
        return "";
    };
    const openEditPrimesDialog = (prime?: any) => {
        let newState;
        if (prime) {
            newState = generateFields([
                { name: "acompteId", value: prime._id },
                { name: "commentaire", value: prime.commentaire },
                { name: "date", value: moment(prime.date) },
                { name: "montant", value: prime.montant },
                {
                    name: "typeAcompte",
                    value: getPrimeTypesValue(prime.typeAcompte._id),
                },
                { name: "userId", value: prime.userId },
            ]);
        } else {
            newState = generateFields([
                { name: "acompteId", value: undefined },
                { name: "commentaire", value: "" },
                { name: "date", value: moment() },
                { name: "montant", value: undefined },
                { name: "typeAcompte", value: undefined },
                { name: "userId", value: employeeForEdit._id },
            ]);
        }
        setPrimeForEdit(newState);
        validate(newState);
    };

    React.useEffect(() => {
        if (employeeForEdit) {
            fetchPrimes({
                userId: employeeForEdit._id,
            });
        }
    }, [employeeForEdit]);

    const openDeletePrimesDialog = (data: any) => {
        setPrimeForDelete(data);
    };

    const onSubmit = () => {
        const values = destructFields(primeForEdit);

        if (values.acompteId) {
            updatePrime({
                acompteId: values.acompteId,
                commentaire: values.commentaire,
                montant: values.montant,
                date: moment(values.date).toISOString(),
                type: values.typeAcompte.value,
                userId: values.userId,
            }).then((res: any) => {
                const data: any = res.payload;
                if (data.status === "success") {
                    addNotification({
                        title: intl.formatMessage({
                            id: "PRIME.EDIT.NOTIFICATION.SUCCESS.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "PRIME.EDIT.NOTIFICATION.SUCCESS.MESSAGE",
                        }),
                        type: "success",
                    });
                    fetchPrimes({
                        userId: employeeForEdit._id,
                    });
                } else if (data.status === "fail") {
                    addNotification({
                        title: intl.formatMessage({
                            id: "PRIME.EDIT.NOTIFICATION.FAIL.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "PRIME.EDIT.NOTIFICATION.FAIL.MESSAGE",
                        }),
                        type: "fail",
                    });
                }
                setPrimeForEdit(undefined);
            });
        } else {
            createPrime({
                acompteId: values.acompteId,
                commentaire: values.commentaire,
                montant: values.montant,
                date: moment(values.date)
                    .set({ hour: 1, minute: 0, second: 0 })
                    .toISOString(),
                type: values?.typeAcompte?.value ?? "",
                userId: values.userId,
            }).then((res: any) => {
                const data: any = res.payload;
                if (data.status === "success") {
                    addNotification({
                        title: intl.formatMessage({
                            id: "PRIME.CREATE.NOTIFICATION.SUCCESS.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "PRIME.CREATE.NOTIFICATION.SUCCESS.MESSAGE",
                        }),
                        type: "success",
                    });
                    fetchPrimes({
                        userId: employeeForEdit._id,
                    });
                } else if (data.status === "fail") {
                    addNotification({
                        title: intl.formatMessage({
                            id: "PRIME.CREATE.NOTIFICATION.FAIL.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "PRIME.CREATE.NOTIFICATION.FAIL.MESSAGE",
                        }),
                        type: "fail",
                    });
                }
                setPrimeForEdit(undefined);
            });
        }
    };

    const onChange = (field: string, value: any) => {
        if (primeForEdit) {
            const newState = {
                ...primeForEdit,
                [field]: {
                    ...primeForEdit[field],
                    value,
                },
            };
            setPrimeForEdit(newState);
            validate(newState);
        }
    };

    const onBlur = (field: string) => {
        if (primeForEdit) {
            const newState = {
                ...primeForEdit,
                [field]: {
                    ...primeForEdit[field],
                    touched: true,
                },
            };
            setPrimeForEdit(newState);
            validate(newState);
        }
    };

    const validate = (prime: any) => {
        if (prime) {
            new Validation()
                .add(prime.montant, [
                    { REQUIRED: true, message: "Ce champ est obligatoire" },
                ])
                .add(prime.typeAcompte, [
                    { REQUIRED: true, message: "Ce champ est obligatoire" },
                ])
                .validate((res: any) => {
                    setPrimeForEdit((prev: any) => ({
                        ...prev,
                        ...generateFields(res),
                    }));
                });
        }
    };

    return (
        <div>
            {primeForEdit ? (
                <EditPrimesDialog
                    prime={primeForEdit}
                    show={primeForEdit ? true : false}
                    onHide={() => setPrimeForEdit(undefined)}
                    onSubmit={onSubmit}
                    actionLoading={actionLoading}
                    EditPrimeForm={
                        <EditPrimeForm
                            prime={primeForEdit}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    }
                />
            ) : (
                <></>
            )}
            {primeForDelete ? (
                <DeletePrimeDialog
                    prime={primeForDelete}
                    show={primeForDelete ? true : false}
                    onHide={() => {
                        setPrimeForDelete(undefined);
                    }}
                />
            ) : (
                <></>
            )}
            {primes?.length ? (
                <>
                    <Primes
                        openEditPrimesDialog={openEditPrimesDialog}
                        primesTable={
                            <PrimesTable
                                primes={primes}
                                openDeletePrimesDialog={openDeletePrimesDialog}
                                openEditPrimesDialog={openEditPrimesDialog}
                            />
                        }
                    />
                </>
            ) : (
                <>
                    <NoPrimesFound
                        openEditPrimesDialog={openEditPrimesDialog}
                    />
                </>
            )}
        </div>
    );
}
