import * as React from "react";
import TextData from "../../../atoms/TextData";
import ReportDisplayOption from "../../../molecules/ReportDisplayOption";
import SelectInput from "../../../atoms/SelectInput";
import Button from "../../../atoms/Button";
import { useReport } from "../../../../hooks/useReport";
import moment from "moment";
import { useUser } from "../../../../hooks/useUsers";
import {
    capitalize,
    extractNumber,
    formatDate,
} from "../../../../_helpers/Functions";
import DatepickerInput from "../../../atoms/DatepickerInput";
import { useBranch } from "../../../../hooks/useBranch";
import { useLogin } from "../../../../hooks/useLogin";

export function WorkedHoursTableRightMenuProps() {
    const { users, getUsersList } = useUser();
    const { selectedBranchSettings } = useBranch();
    const {
        setMonth,
        setYear,
        setSelectedEmployee,
        report,
        branchs,
        selectedBranchs,
        setHideRemainingContractHours,
        setHideExtraContractHours,
        setShowOnlyCurrentMonthHours,
        setShowWorkedHoursEverywhere,
        setShowWorkedHours,
        setShowBreaks,
        setShowMeals,
        setShowWorkedHoursInMinutes,
        updateRportOptionInSettings,
        setReportRange,
        getMonthlyWorkHours,
    } = useReport();
    const handleSetShowOnlyCurrentMonthHours = (
        showOnlyCurrentMonthHours: boolean,
    ) => {
        setShowOnlyCurrentMonthHours(
            showOnlyCurrentMonthHours,
            reportRangeType,
        );
    };
    const handleSetShowWorkedHoursEverywhere = (
        showWorkedHoursEverywhere: boolean,
    ) => {
        setShowWorkedHoursEverywhere(
            showWorkedHoursEverywhere,
            reportRangeType,
        );
    };
    const handleOptionActions = [
        setHideRemainingContractHours,
        setHideExtraContractHours,
        handleSetShowOnlyCurrentMonthHours,
        handleSetShowWorkedHoursEverywhere,
        setShowWorkedHours,
        setShowBreaks,
        setShowMeals,
        setShowWorkedHoursInMinutes,
    ];
    React.useEffect(() => {
        setOptions((prev) => {
            const updatedOptions = [...prev];

            updatedOptions[0].checked =
                report.hideRemainingContractHours || false;
            updatedOptions[1].checked = report.hideExtraContractHours || false;
            updatedOptions[2].checked =
                report.showOnlyCurrentMonthHours || false;
            updatedOptions[3].checked =
                report.showWorkedHoursEverywhere || false;
            updatedOptions[4].checked = report.showWorkedHours || false;
            updatedOptions[5].checked = report.showBreaks || false;
            updatedOptions[6].checked = report.showMeals || false;
            updatedOptions[7].checked =
                report.showWorkedHoursInMinutes || false;
            return updatedOptions;
        });
    }, [report]);
    const [options, setOptions] = React.useState([
        {
            text: "Cacher les heures restantes au contrat",
            checked: false,
        },
        {
            text: "Cacher les heures supplémentaires",
            checked: false,
        },
        {
            text: "Afficher uniquement les heures travaillées du mois en cours",
            checked: false,
        },
        {
            text: "Afficher les heures travaillées partout",
            checked: false,
        },

        {
            text: "Afficher les jours travaillés",
            checked: false,
        },
        {
            text: "Afficher les pauses",
            checked: false,
        },
        {
            text: "Afficher les repas",
            checked: false,
        },
        {
            text: "Afficher les heures travaillées (en min)",
            checked: false,
        },
    ]);
    const { branchSettings } = useBranch();
    const { auth } = useLogin();
    const hideOptions = React.useMemo(() => {
        let arr: number[] = [];
        if (
            report.branchs?.length <= 1 ||
            auth?.user?.role !== "Administrator"
        ) {
            arr.push(3);
        }
        if (!branchSettings.usePause) {
            arr.push(5);
        }
        if (!branchSettings.useRepas) {
            arr.push(6);
        }
        return arr;
    }, [branchSettings.usePause, branchSettings.useRepas, report.branchs]);

    const currentYear = moment().year();
    const currentBranchSettings = JSON.parse(
        sessionStorage.getItem("selectedBranch") || `{"created_at":"2018"}`,
    );
    const startYear = moment(currentBranchSettings.created_at).year();

    const years = [
        // { label: "Année", value: "Année" }
    ];
    for (let year = currentYear; year >= startYear; year--) {
        years.push({
            label: String(year),
            value: String(year),
        });
    }

    const months = [
        // { label: "Mois", value: "Mois" },
        ...moment.months().map((month) => ({
            label: capitalize(month),
            value: capitalize(month),
        })),
    ];

    const formatEmployeeDisplay = (user: any) =>
        `${user.fname} ${user.lname}`.trim();
    const listUsers = users.map((user: any) => {
        const emp = formatEmployeeDisplay(user);
        return { label: emp, value: emp };
    });
    const employees = [
        { label: "Selectionner un employé", value: "Selectionner un employé" },
        ...listUsers,
    ];

    const handleSelectYear = (selectedOption: any) => {
        // // const selectedOption = e.options[e.selectedIndex];
        const selectedYear = selectedOption.value;

        if (extractNumber(selectedYear) === 0) {
            setYear(null);
            return;
        }

        setYear(selectedYear);
    };

    const handleSelectMonth = (selectedOption: any) => {
        // const selectedOption = e.options[e.selectedIndex];
        const selectedMonth = capitalize(
            moment
                .months()
                .find((month) => capitalize(month) === selectedOption.value) ??
                "",
        );
        if (selectedMonth === undefined) {
            setMonth(null);
            return;
        }
        //TODO: fix this
        const monthIndex = months.findIndex(
            (month: any) => month.value === selectedMonth,
        );
        const formattedMonth = String(monthIndex + 1).padStart(2, "0");

        setMonth(formattedMonth);
    };

    const handleSelectEmployee = (selectedOption: any) => {
        const selectedUser = users.find((user: any) => {
            return formatEmployeeDisplay(user) === selectedOption.value;
        });
        if (selectedUser === undefined) {
            setSelectedEmployee(null);
            return;
        }
        setSelectedEmployee(selectedUser);
    };

    const handleOptionChange = (selectedOption: any) => {
        setOptions((prevOptions) => {
            const newOptions = prevOptions.map((option, index) => {
                if (option.text === selectedOption.text) {
                    handleOptionActions[index](selectedOption.checked);
                    return {
                        ...option,
                        checked: selectedOption.checked,
                    };
                }
                return option;
            });
            return newOptions;
        });
    };
    const { reportRangeType, handleSetReportRangeType } = useReport();
    // React.useState("month");
    const handleGetWorkedHoursDataInMonth = () => {
        let monthInNum = moment().month() - 1;
        let yearInNum = moment().year();
        if (report.month) {
            monthInNum = Number(report.month) - 1;
        }
        if (report.year) {
            yearInNum = Number(report.year);
        }
        let startDate, endDate;
        if (report.showOnlyCurrentMonthHours) {
            startDate = moment()
                .year(yearInNum)
                .month(monthInNum)
                .startOf("month")
                .format("YYYY-MM-DD");
            endDate = moment()
                .year(yearInNum)
                .month(monthInNum)
                .endOf("month")
                .format("YYYY-MM-DD");
        } else {
            startDate = moment()
                .year(yearInNum)
                .month(monthInNum)
                .startOf("month")
                .startOf("week")
                .format("YYYY-MM-DD");
            endDate = moment()
                .year(yearInNum)
                .month(monthInNum)
                .endOf("month")
                .endOf("week")
                .format("YYYY-MM-DD");
        }

        if (
            startDate !== report.workHoursDateRange.startDate ||
            endDate !== report.workHoursDateRange.endDate
        ) {
            setReportRange({
                startDate,
                endDate,
            });
        }
        getMonthlyWorkHours({
            startDate,
            endDate,
            showOnlyThisMonthRapport: report.showOnlyCurrentMonthHours ?? true,
        });
        //send req 7asb l option
    };
    const handleGetWorkedHoursDataInPeriod = ({
        start,
        end,
    }: {
        start?: any;
        end?: any;
    }) => {
        let monthInNum = moment().month() - 1;
        let yearInNum = moment().year();
        if (report.month) {
            monthInNum = Number(report.month) - 1;
        }
        if (report.year) {
            yearInNum = Number(report.year);
        }

        let startDate =
            start ??
            report.workHoursDateRange.startDate ??
            moment()
                .year(yearInNum)
                .month(monthInNum)
                .startOf("month")
                .format("YYYY-MM-DD");
        let endDate =
            end ??
            report.workHoursDateRange.endDate ??
            moment()
                .year(yearInNum)
                .month(monthInNum)
                .endOf("month")
                .format("YYYY-MM-DD");

        if (
            startDate !== report.workHoursDateRange.startDate ||
            endDate !== report.workHoursDateRange.endDate
        ) {
            setReportRange({
                startDate,
                endDate,
            });
        }
        getMonthlyWorkHours({
            startDate,
            endDate,
            showOnlyThisMonthRapport: true,
        });
    };

    React.useEffect(() => {
        if (
            (report.month &&
                report.year &&
                report.showOnlyCurrentMonthHours !== undefined &&
                report.showWorkedHoursEverywhere !== undefined &&
                selectedBranchs?.length > 0 &&
                selectedBranchSettings?._id &&
                selectedBranchs?.find(
                    (branch: any) =>
                        branch.value === selectedBranchSettings?._id,
                ) !== undefined) ||
            (report.month &&
                report.year &&
                selectedBranchs?.length > 0 &&
                !selectedBranchSettings?._id)
        ) {
            if (reportRangeType === "month") {
                handleGetWorkedHoursDataInMonth();
            } else {
                handleGetWorkedHoursDataInPeriod({});
            }
        }
    }, [
        report.month,
        report.year,
        report.showOnlyCurrentMonthHours,
        report.showWorkedHoursEverywhere,
        selectedBranchs,
        selectedBranchSettings?._id,
    ]);

    const startDateField = {
        name: "startDate",
        value: report.workHoursDateRange.startDate,
    };
    const endDateField = {
        name: "endDate",
        value: report.workHoursDateRange.endDate,
    };
    const handleStartDateChange = (value: any) => {
        const startDate = new Date(value);
        setReportRange({ startDate: formatDate(startDate) });
        handleGetWorkedHoursDataInPeriod({ start: formatDate(startDate) });
    };
    const handleEndDateChange = (value: any) => {
        const endDate = new Date(value);
        setReportRange({ endDate: formatDate(endDate) });
        handleGetWorkedHoursDataInPeriod({ end: formatDate(endDate) });
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "445px",
                justifyContent: "flex-end",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {reportRangeType === "month" ? (
                    <>
                        <SelectInput
                            title=""
                            options={years}
                            selectedValue={{
                                label: report.year,
                                value: report.year,
                            }}
                            isSearchable={false}
                            onSelectOption={handleSelectYear}
                            style={{
                                height: "40px",
                                width: "130px",
                                borderRadius: "9px",
                                fontSize: "12px",
                            }}
                        ></SelectInput>
                        <SelectInput
                            title=""
                            options={months}
                            selectedValue={
                                months[extractNumber(report.month) - 1]
                            }
                            isSearchable={false}
                            onSelectOption={handleSelectMonth}
                            style={{
                                height: "40px",
                                width: "130px",
                                borderRadius: "9px",
                                fontSize: "12px",
                            }}
                        ></SelectInput>
                    </>
                ) : (
                    <>
                        <DatepickerInput
                            required={false}
                            field={startDateField}
                            onUpdate={handleStartDateChange}
                            // side="left"
                            style={{
                                height: "40px",
                                width: "130px",
                                borderRadius: "9px",
                                fontSize: "12px",
                            }}
                        />
                        <DatepickerInput
                            required={false}
                            field={endDateField}
                            onUpdate={handleEndDateChange}
                            // side="left"
                            style={{
                                height: "40px",
                                width: "130px",
                                borderRadius: "9px",
                                fontSize: "12px",
                            }}
                        />
                    </>
                )}
            </div>
            <ReportDisplayOption
                options={options}
                handleOptionChange={handleOptionChange}
                isRapport={true}
                hideOptions={[
                    reportRangeType !== "month" ? 2 : -1,
                    ...hideOptions,
                ]}
            />
            <div
                className="view-buttons"
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Button
                    padding="8px 16px"
                    width="80px"
                    textColor={
                        reportRangeType === "period" ? "white" : "#6f78ab"
                    }
                    backgroundColor={
                        reportRangeType === "period" ? "#6f78ab" : "white"
                    }
                    height="32px"
                    style={{
                        border: "0.5px solid #6f78ab",
                        borderRight: "0px none",
                        borderRadius: " 4px 0px 0px 4px",
                    }}
                    onClick={() => handleSetReportRangeType("period")}
                >
                    <TextData
                        color={
                            reportRangeType === "period" ? "white" : "#6f78ab"
                        }
                        size="12px"
                        fontWeight="500"
                        width="auto"
                    >
                        Période
                    </TextData>
                </Button>
                <Button
                    padding="8px 16px"
                    width="80px"
                    textColor={
                        reportRangeType === "month" ? "white" : "#6f78ab"
                    }
                    backgroundColor={
                        reportRangeType === "month" ? "#6f78ab" : "white"
                    }
                    height="32px"
                    style={{
                        border: "0.5px solid #6f78ab",
                        borderRight: "1ps solid red",
                        borderRadius: "0px 4px 4px 0px",
                    }}
                    onClick={() => handleSetReportRangeType("month")}
                >
                    <TextData
                        color={
                            reportRangeType === "month" ? "white" : "#6f78ab"
                        }
                        size="12px"
                        fontWeight="500"
                        width="auto"
                    >
                        Mois
                    </TextData>
                </Button>
            </div>
            {/* <SelectInput
                title=''
                options={employees}
                selectedValue={employees[0]}
                onSelectOption={handleSelectEmployee}
                style={{
                    width: "auto",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput> */}
        </div>
    );
}
