import React from "react";
import ZToggleButton from "../../../../components/atoms/ZToggleButton";
import Button from "../../../../components/atoms/ZButton";
import { useIntl } from "react-intl";
import { EmployeesFilterProps } from "../../../../hooks/useEmployees";

export default function RegisterFilter({
    filterBy,
    onUpdateFilterBy,
    handleDownload,
}: RegisterFilterInterface) {
    const intl = useIntl();
    return (
        <div className="py-[30px]  flex items-center justify-between">
            <div className="border border-n-200 rounded-lg py-2 px-4">
                <div className="flex gap-8">
                    <ZToggleButton
                        isChecked={filterBy.actif}
                        onPress={() => {
                            onUpdateFilterBy({
                                ...filterBy,
                                actif: !filterBy.actif,
                                deleted:
                                    filterBy.deleted === null ? false : null,
                            });
                        }}
                        leftComponent={
                            <label className="text-lg font-bold text-n-400 mr-5">
                                {intl.formatMessage({
                                    id: "REGISTER.TOGGLE.ACTIF",
                                })}
                            </label>
                        }
                    />
                </div>
            </div>
            <Button
                leftComponent={
                    <img src="/assets/imgs/fi_download.png" alt="icon" />
                }
                title={intl.formatMessage({ id: "REGISTER.DOWNLOAD.TITLE" })}
                variant="primary"
                onClick={handleDownload}
            />
        </div>
    );
}

interface RegisterFilterInterface {
    filterBy: EmployeesFilterProps;
    onUpdateFilterBy: (props: EmployeesFilterProps) => void;
    handleDownload: () => void;
}
