import React from "react";
import Button from "../../../../components/atoms/ZButton";

export const ActionColumnFormatter = (row: any, extras: any) => {
    return (
        <div className="flex  items-center space-x-0 justify-between w-full">
            <div className="text-v-800 text-md font-light">
                <Button
                    onClick={() => {
                        extras.closeTraitement();
                        extras.openVacationRequestDialog();
                        extras.updateEmployeeValue(row);
                    }}
                    title="Traiter"
                    style={{
                        fontSize: "16px",
                        width: "auto",
                        height: "auto",
                    }}
                />
            </div>
        </div>
    );
};
