import Swal from "sweetalert2";
import { API } from "../constants/env";
import {
    createVacationService,
    deleteVacationService,
    getAllVacationService,
    updateSelectedVacationService,
} from "../redux/features/vacation/service";
import { OneVacationItem } from "../redux/features/vacation/type";
import {
    loadSelectedVacation,
    resetNewVacation,
    resetSeletedVacation,
    setCreateVacation,
    setSelectedVacation,
} from "../redux/features/vacation/vacationSlice";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import {
    getVacationService,
    updateVacationService,
} from "../redux/features/settings/service";

export const useVacation = () => {
    const dispatch = useAppDispatch();
    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;
    const vacations = useAppSelector((state: any) => state.vacation).list;
    const selectedVacation = useAppSelector(
        (state: any) => state.vacation,
    ).selected;
    const newVacation = useAppSelector((state: any) => state.vacation).new;
    const vacationSetting = useAppSelector(
        (state: any) => state.settings,
    ).vacation;

    const getVacationSettings = () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings && selectedBranchSettings._id) {
            dispatch(
                getVacationService({
                    branchID: selectedBranchSettings._id,
                    token: token,
                }),
            );
        }
    };
    const updateVacationSettings = ({
        entitlementCalculationDate,
        enableLeaveRuleEntitlement,
    }: any) => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings && selectedBranchSettings._id) {
            dispatch(
                updateVacationService({
                    branchID: selectedBranchSettings._id,
                    token: token,
                    entitlementCalculationDate: entitlementCalculationDate,
                    enableLeaveRuleEntitlement: enableLeaveRuleEntitlement,
                }),
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    Swal.fire({ icon: "success" });
                    getVacationSettings();
                } else {
                    Swal.fire({ icon: "error" });
                }
            });
        }
    };

    const getAllVacations = () => {
        dispatch(getAllVacationService({ siteID: selectedBranchSettings._id }));
    };

    const loadVacationToUpdate = (id: string) => {
        const toUpdate = vacations.find(
            (element: OneVacationItem) => element._id === id,
        );
        if (toUpdate) {
            dispatch(
                loadSelectedVacation({
                    _id: toUpdate._id,
                    designation: toUpdate.designation,
                    color: toUpdate.color,
                }),
            );
        }
    };
    const setDesignationToUpdate = (designation: string) => {
        dispatch(setSelectedVacation({ designation }));
    };
    const setColorToUpdate = (color: string) => {
        dispatch(setSelectedVacation({ color }));
    };

    const updateSelectedVacation = () => {
        dispatch(
            updateSelectedVacationService({
                color: selectedVacation.color,
                designation: selectedVacation.designation,
                siteID: selectedBranchSettings._id,
                idCongeType: selectedVacation._id,
            }),
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                Swal.fire({ icon: "success" });
                dispatch(resetSeletedVacation());
                getAllVacations();
            } else {
                Swal.fire({ icon: "error" });
            }
        });
    };

    const deleteSelectedVacation = () => {
        if (selectedVacation._id) {
            dispatch(
                deleteVacationService({
                    idCongeType: selectedVacation._id,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    dispatch(resetSeletedVacation());
                    getAllVacations();
                    Swal.fire({
                        title: "cette opération a été effectuée avec succès",
                        color: "#2A8BAB",
                        text: "",
                        icon: "success",
                        confirmButtonText: "Oui",
                        confirmButtonColor: "#2A8BAB",
                    });
                } else {
                    Swal.fire({ icon: "error" });
                }
            });
        } else {
            Swal.fire({ icon: "error" });
        }
    };

    const setDesignationToCreate = (designation: string) => {
        dispatch(setCreateVacation({ designation }));
    };
    const setColorToCreate = (color: string) => {
        dispatch(setCreateVacation({ color }));
    };

    const submitNewVacation = () => {
        dispatch(
            createVacationService({
                color: newVacation.color,
                designation: newVacation.designation,
                siteID: selectedBranchSettings._id,
            }),
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                Swal.fire({ icon: "success" });
                dispatch(resetNewVacation());
                getAllVacations();
            } else {
                Swal.fire({ icon: "error" });
            }
        });
    };

    return {
        vacations,
        selectedVacation,
        newVacation,
        vacationSetting,

        getAllVacations,
        loadVacationToUpdate,
        setDesignationToUpdate,
        setColorToUpdate,
        updateSelectedVacation,
        deleteSelectedVacation,
        getVacationSettings,
        updateVacationSettings,

        setDesignationToCreate,
        setColorToCreate,
        submitNewVacation,
    };
};
