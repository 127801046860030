
import { ReactComponent as EllipsisVertical } from '../../../../utils/img/lucideReact-icons/EllipsisVertical.svg';
import { ReactComponent as  X  } from '../../../../utils/img/lucideReact-icons/x.svg';
import { ReactComponent as CheckIcon } from "../../../../components/atoms/Checkbox/checkedIcon.svg";

export function ResultColumnFormatter( row: any,extras: any) {
  return (
    <div className="flex items-center space-x-0 justify-between w-full">
        <div
          className={`flex gap-2 items-center justify-center text-md rounded-md p-2 w-full ${
            row?.status ==1 
              ? "text-green-700 bg-approve-op-1"
              : "text-error bg-error-op-2"
          }`}
            >
              {row?.status == 1  ? <CheckIcon  height={20} width={20} /> : <X  height={20} width={20} />}
              {row?.status == 1 ? (
                <p className="font-bold">Approuvé</p>
              ) : (
                <p className="font-bold">Refusé</p>
              )}
        </div>
        <EllipsisVertical  
            color="#4c5690"   
            className="cursor-pointer"
            onClick={() => {  
                extras.openVacationRequestDialog(); 
                extras.updateEmployeeValue(row);  
                extras.openTraitement()
              }} 
        />
    </div>
   
  );
}
