import React from "react";
import Datatable from "../../../components/organism/Datatable";
import { getRangeData } from "../../../_helpers/TableHelpers";
import { SortByProps, useEmployees } from "../../../hooks/useEmployees";
import * as columnFormatters from "./column-formatters";
import { useIntl } from "react-intl";
import EmployeesNoRecord from "../../../components/templates/EmployeesNoRecord";
import { useBranch } from "../../../hooks/useBranch";

export default function EmployeesActifTable({
    openArchiveEmployeeDialog,
    openEditEmployee,
    queryParams,
    employees,
    onQueryParamsChange,
    openCreateEmployeeDialog,
    onUpdateSortBy,
}: EmployeesArchiveTableInterface) {
    const intl = useIntl();
    const columns = [
        {
            dataField: "fname",
            text: intl.formatMessage({
                id: "MENU.FIRSTNAME_AND_LASTNAME",
            }),
            formatter: columnFormatters.NameColumnFormatter,
            sort: true,
        },
        {
            dataField: "role",
            text: intl.formatMessage({ id: "MENU.ROLE" }),
            formatter: columnFormatters.RoleColumnFormatter,
            sort: true,
            //getCellClassName: () => "!max-w-[140px]",
        },
        {
            dataField: "pin",
            text: intl.formatMessage({ id: "MENU.PIN_CODE" }),
            formatter: columnFormatters.PinCodeColumnFormatter,
            sort: true,
        },
        {
            dataField: "email",
            text: intl.formatMessage({ id: "MENU.EMAIL_ADDRESS" }),
            formatter: columnFormatters.EmailColumnFormatter,
            sort: true,
        },
        {
            dataField: "phone",
            text: intl.formatMessage({ id: "MENU.PHONE" }),
            formatter: columnFormatters.PhoneColumnFormatter,
            sort: true,
        },
        {
            dataField: "userScore",
            text: intl.formatMessage({ id: "MENU.HR_FOLDER" }),
            formatter: columnFormatters.ProfileStatsColumnFormatter,
            sort: true,
        },

        {
            dataField: "actions",
            text: " ",
            formatter: columnFormatters.ActionColumnFormatter,
            formatExtraData: {
                openArchiveEmployeeDialog,
                openEditEmployee,
            },
        },
    ];

    const [getDataRange, setGetDataRange] = React.useState(() => {
        if (employees.length) {
            return getRangeData(
                employees,
                queryParams.page,
                queryParams.sizePerPage,
            );
        } else {
            return [];
        }
    });

    React.useEffect(() => {
        setGetDataRange(
            getRangeData(employees, queryParams.page, queryParams.sizePerPage),
        );
    }, [employees, queryParams.page, queryParams.sizePerPage]);

    return (
        <>
            {employees.length ? (
                <Datatable
                    data={getDataRange}
                    columns={columns}
                    rowClasses={(row, id) => {
                        return id % 2 == 0 ? "bg-white" : "bg-light-border";
                    }}
                    sortField={queryParams.sortField}
                    sortOrder={queryParams.sortOrder}
                    onTableSort={(field, order) => {
                        onUpdateSortBy({
                            field,
                            order,
                        });
                    }}
                />
            ) : (
                <EmployeesNoRecord
                    openCreateEmployesDialog={openCreateEmployeeDialog}
                    active={true}
                />
            )}
        </>
    );
}

interface EmployeesArchiveTableInterface {
    employees: any[];
    openArchiveEmployeeDialog: (id: number) => void;
    openEditEmployee: (id: number) => void;
    queryParams: {
        page: number;
        sizePerPage: number;
        sortField: string;
        sortOrder: string;
    };
    onQueryParamsChange: (data: any) => void;
    openCreateEmployeeDialog: () => void;
    onUpdateSortBy: (value: SortByProps) => void;
}
