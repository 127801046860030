import React from "react";
import * as actions from "../redux/features/pointeuse/service";
import { reducersActions } from "../redux/features/pointeuse/pointeuseSlice";
import { ROUTER } from "../constants/env";

import {
    AddEmployeeToPointeuseRequest,
    FetchShiftListRequest,
    FetchShiftWeekRequest,
    UpdatePointageRequest,
    UpdateShiftRequest,
    ValidateShiftRequest,
    ValidateJourneyRequest,
    FetchDeparementsRequest,
} from "../redux/features/pointeuse/types";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { shallowEqual } from "react-redux";
import moment from "moment";
import { fetchDepartementsUrl } from "../redux/features/api.routes";
import axios from "axios";
import { API } from "../constants/env";
import Swal from "sweetalert2";

export interface PointeuseFilterProps {
    departements: any[];
}

const filterByName = (target: any[], name = "") => {
    let newArray = [...target];
    if (name !== "") {
        newArray = newArray.filter(
            (value) =>
                value.employee.fname
                    .toLowerCase()
                    .indexOf(name.toLowerCase()) !== -1 ||
                value.employee.lname
                    .toLowerCase()
                    .indexOf(name.toLowerCase()) !== -1,
        );
    }
    return newArray;
};

export default function usePointeuse() {
    const onUpdateFilters = (props: PointeuseFilterProps) => {
        //setFilterBy(props);
        dispatch(
            reducersActions.filterUpdated({
                filters: props,
            }),
        );
    };

    const [filterBy, setFilterBy] = React.useState({
        from: moment(),
        to: moment(),
    });

    const [month, setMonth] = React.useState(() => {
        if (filterBy.from) {
            return {
                from: filterBy.from.clone().startOf("month"),
                to: filterBy.from.clone().endOf("month"),
            };
        } else {
            return {
                from: moment().startOf("month"),
                to: moment().endOf("month"),
            };
        }
    });

    const dispatch = useAppDispatch();
    const {
        listLoading,
        shiftsLoading,
        weekLoading,
        actionLoading,
        lastSync,
        shifts,
        employees,
        showedEmployees,
        toSelectEmployees,
        weekDetails,
        filters,
        departements,
        planning,
        shiftsMap,
        plans,
        data,
        options,
        filteredDataByDepartments,
        employeesById,
        uniqueEmployees,
    } = useAppSelector((state) => state.pointeuse, shallowEqual);
    const employeesState = useAppSelector((state) => state.employees);

    const [filtredShifts, setFiltredShifts] = React.useState<any>([]);

    React.useEffect(() => {
        let newShifts = filterByName(shifts, employeesState.filterBy.name).sort(
            (a, b) => {
                return a.duree <= 0 ? a.duree - b.duree : 1;
            },
        );
        // if (filters.departements.length) {
        //     newShifts = newShifts.filter((shift) => {
        //         let exist = false;
        //         filters.departements.forEach((dep) => {
        //             let departement = departements.find(
        //                 (temp) => temp._id === dep
        //             );
        //             if (departement.employees.includes(shift.employee._id)) {
        //                 exist = true;
        //             }
        //         });
        //         return exist;
        //     });
        // }
        setFiltredShifts(newShifts);
    }, [filters, shifts, employeesState.filterBy.name]);

    const [filtredDepartements, setFiltredDepartements] = React.useState<any>(
        [],
    );

    React.useEffect(() => {
        let newDeparements = departements.map((departement: any) => {
            let val = 0;
            shifts.forEach((shift: any) => {
                if (departement.employees.includes(shift.employee._id)) {
                    val++;
                }
            });
            return {
                ...departement,
                inShift: val,
            };
        });
        setFiltredDepartements(newDeparements);
    }, [shifts, departements]);

    const getShifList = (data: FetchShiftListRequest) => {
        console.log(" getShifList = ", data);
        return dispatch(actions.fetchShiftList(data));
    };

    const getShiftWeek = (data: FetchShiftWeekRequest) => {
        return dispatch(actions.fetchShiftWeek(data));
    };

    const validateShift = (id: ValidateShiftRequest) => {
        return dispatch(actions.validateShift(id));
    };

    const addEmployeeToPointeuse = (data: AddEmployeeToPointeuseRequest) => {
        return dispatch(actions.addEmployeeToPointeuse(data));
    };

    const updatePointage = (data: UpdatePointageRequest) => {
        return dispatch(actions.updatePointage(data));
    };

    const updateShift = (data: UpdateShiftRequest) => {
        return dispatch(actions.updateShift(data));
    };

    const validateJourney = (journey: ValidateJourneyRequest) => {
        return dispatch(actions.validateJourney(journey));
    };

    const getMonthDetails = (data: FetchShiftListRequest) => {};

    const fetchDepartements = (data: FetchDeparementsRequest) => {
        return dispatch(actions.fetchDepartements(data));
    };
    const getDepartmentsForNewEmp = async (sites: any[]) => {
        let response: any[] = [];
        try {
            // console.log("sites!!!", sites);
            for (const selectedSite of sites) {
                const res = await axios.get(
                    `${fetchDepartementsUrl}/${selectedSite}`,
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (res.status === 200) {
                    response.push({
                        site: selectedSite,
                        depts: res.data,
                    });
                }
            }
            // console.log("response:::", response);
            return response;
        } catch (error) {
            console.log("error", error);
            return response;
        }
    };
    const printPointage = async ({ from, to }: any) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;

            try {
                const response = await fetch(
                    API.client.pointeuse.printShiftList,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            siteId,
                            from,
                            to,
                        }),
                    },
                );
                const data = await response.json();
                if (response.ok) {
                    if (data.filePath) {
                        window.open(ROUTER.STATIC + data.filePath, "_blank");
                    }
                } else if (data.error) {
                    Swal.fire({
                        title: "Erreur!",
                        text: data.message,
                        icon: "error",
                        confirmButtonText: "ok",
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    const getPointeuseOptionsFromLocalStorage = () => {
        const pointeuseOptionsJson = localStorage.getItem("pointeuseOptions");
        if (pointeuseOptionsJson) {
            const pointeuseOptions = JSON.parse(pointeuseOptionsJson);
            dispatch(
                reducersActions.optionsUpdate({
                    hidePause: pointeuseOptions.hidePause,
                    hideDays: pointeuseOptions.hideDays,
                    hideMeals: pointeuseOptions.hideMeals,
                    showTimeInMinutes: pointeuseOptions.showTimeInMinutes,
                }),
            );
        }
    };
    const setHidePause = (hidePause: boolean) => {
        dispatch(reducersActions.optionsUpdate({ hidePause }));
    };
    const setHideDays = (hideDays: boolean) => {
        dispatch(reducersActions.optionsUpdate({ hideDays }));
    };
    const setHideMeals = (hideMeals: boolean) => {
        dispatch(reducersActions.optionsUpdate({ hideMeals }));
    };
    const setShowTimeInMinutes = (showTimeInMinutes: boolean) => {
        dispatch(reducersActions.optionsUpdate({ showTimeInMinutes }));
    };
    const setDepartments = (departments: any) => {
        dispatch(reducersActions.updatePointeuse({ departments }));
    };
    const setFilteredByDataDepartments = (filteredDataByDepartments: any) => {
        dispatch(
            reducersActions.updatePointeuse({ filteredDataByDepartments }),
        );
    };

    const restPointeuseState = () => {
        dispatch(reducersActions.updatePointeuse({ reset: true }));
    };
    const handleSetEmployeesPointeuse = (employees: any) => {
        dispatch(reducersActions.updatePointeuse({ employees }));
    };
    return {
        listLoading:
            actionLoading || listLoading || shiftsLoading || weekLoading,
        lastSync,
        actionLoading,
        getShifList,
        getShiftWeek,
        pointeuseFilterBy: filterBy,
        setFilterBy,
        month,
        setMonth,
        shifts: filtredShifts,
        validateShift,
        addEmployeeToPointeuse,
        pointeuseEmployees: employees,
        showedEmployees,
        toSelectEmployees,
        updatePointage,
        updateShift,
        validateJourney,
        weekDetails,
        getMonthDetails,
        onUpdateFilters,
        filters,
        fetchDepartements,
        getDepartmentsForNewEmp,
        departements: filtredDepartements,
        planningByUser: planning,
        shiftsMap,
        plans,
        data,
        setHidePause,
        setHideDays,
        setHideMeals,
        setShowTimeInMinutes,
        getPointeuseOptionsFromLocalStorage,
        pointeuseOptions: options,
        departments: departements,
        setDepartments,
        setFilteredByDataDepartments,
        filteredDataByDepartments,
        restPointeuseState,
        employeesById,
        uniqueEmployees,
        handleSetEmployeesPointeuse,
        printPointage,
    };
}
