import * as React from "react";

import "./style.scss";
import TextData from "../../atoms/TextData";
import { formatTime, convertMinutesToHours } from "../../../_helpers/Functions";
import moment from "moment";
function PlanningTableFooter(props: any /*PlanningTableFooterProps*/) {
    const {
        totalTime,
        week,
        period,
        selectedDay,
        isTotal,
        department,
        position = "static",
    } = props;
    const dayOrder = moment().isoWeekday();
    const weekNumber = moment().isoWeek();
    //     allDays: 0,
    //     days: Array(7).fill(0),
    // });

    // React.useEffect(() => {
    //     if (
    //         planning.employeesData !== null &&
    //         planning.employeesPlanningData !== null
    //     ) {
    //         changeTotalTime();
    //     } else {
    //         setTotalTime({
    //             allDays: 0,
    //             days: Array(7).fill(0),
    //         });
    //     }
    // }, [planning.employeesData, planning.employeesPlanningData]);
    return (
        // <tfoot
        //     style={{
        //         borderTopLeftRadius: "12px",
        //         borderBottomLeftRadius: "12px",
        //     }}
        // >
        <tr
            style={{
                position,
                bottom: "-1px",
                backgroundColor: "#FFF",
                height: "20px",
                boxShadow: "0px -10px 10px -12px rgba(0, 0, 0, 0.2)",
                zIndex: "9",
            }}
        >
            {period === "week" ? (
                <>
                    <td
                        id="col-1"
                        style={{
                            padding: "10px",
                            backgroundColor: isTotal ? "#2a8bab" : "white",
                            outline: isTotal
                                ? "1px solid white"
                                : "1px solid #edeff5",
                            // borderTopLeftRadius: isTotal ? "12px" : "0",
                            borderBottomLeftRadius: isTotal ? "12px" : "0",
                            overflow: "hidden",
                        }}
                    >
                        {totalTime && (
                            <TextData
                                width="auto"
                                size="14px"
                                fontWeight="600"
                                color={isTotal ? "white" : "#2A346D"}
                            >
                                Total des heures
                                {department && " (" + department + ")"}:{" "}
                                {totalTime?.allDays === 0
                                    ? "0h"
                                    : convertMinutesToHours(
                                          totalTime?.allDays || 0,
                                      )}
                            </TextData>
                        )}
                    </td>
                    {totalTime?.days?.map((dayTime: any, index: number) => {
                        return (
                            <td
                                id={`col-${index + 2}`}
                                style={{
                                    backgroundColor: `${
                                        isTotal
                                            ? "#2a8bab"
                                            : weekNumber === week &&
                                                dayOrder === index + 1
                                              ? "rgb(42 ,139, 171, 0.05)"
                                              : ""
                                    }`,
                                    textAlign: "center",
                                    padding: "10px",
                                    outline: isTotal
                                        ? "1px solid white"
                                        : "1px solid #edeff5",
                                }}
                            >
                                <TextData
                                    width="auto"
                                    size="14px"
                                    fontWeight="600"
                                    color={isTotal ? "white" : "#2A346D"}
                                >
                                    {dayTime === 0
                                        ? "0h"
                                        : convertMinutesToHours(dayTime)}
                                </TextData>
                            </td>
                        );
                    })}
                </>
            ) : period === "day" ? (
                <>
                    <td
                        colSpan={8}
                        style={{
                            padding: "10px",
                            width: "100%",
                            backgroundColor: isTotal ? "#2a8bab" : "white",
                            outline: isTotal
                                ? "1px solid white"
                                : "1px solid #edeff5",
                            // borderTopLeftRadius: isTotal ? "12px" : "0",
                            borderBottomLeftRadius: isTotal ? "12px" : "0",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                padding: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <TextData
                                width="auto"
                                size="14px"
                                fontWeight="600"
                                color={isTotal ? "white" : "#2A346D"}
                            >
                                Total des heures
                                {department && " (" + department + ")"}:{" "}
                                {totalTime?.days[
                                    moment(selectedDay).day() === 0
                                        ? 6
                                        : moment(selectedDay).day() - 1
                                ] === 0
                                    ? "0h"
                                    : convertMinutesToHours(
                                          totalTime?.days[
                                              moment(selectedDay).day() === 0
                                                  ? 6
                                                  : moment(selectedDay).day() -
                                                    1
                                          ],
                                      )}
                            </TextData>
                        </div>
                    </td>
                </>
            ) : (
                <></>
            )}
        </tr>
    );
}

export default React.memo(PlanningTableFooter);
interface PlanningTableFooterProps {
    totalTime: any;
    week: number;
}
PlanningTableFooter.defaultProps = {};

{
    /* <th id='col-2'>
                <TextData
                    width='auto'
                    color='#101844'
                    size='14px'
                    fontWeight='500'
                >
                    9h00
                </TextData>
            </th>
            <th id='col-3'>
                <TextData
                    width='auto'
                    color='#101844'
                    size='14px'
                    fontWeight='500'
                >
                    9h00
                </TextData>
            </th>
            <th id='col-4'>
                <TextData
                    width='auto'
                    color='#101844'
                    size='14px'
                    fontWeight='500'
                >
                    9h00
                </TextData>
            </th>
            <th id='col-5'>
                <TextData
                    width='auto'
                    color='#101844'
                    size='14px'
                    fontWeight='500'
                >
                    9h00
                </TextData>
            </th>
            <th id='col-6'>
                <TextData
                    width='auto'
                    color='#101844'
                    size='14px'
                    fontWeight='500'
                >
                    9h00
                </TextData>
            </th>
            <th id='col-7'>
                <TextData
                    width='auto'
                    color='#101844'
                    size='14px'
                    fontWeight='500'
                >
                    9h00
                </TextData>
            </th> */
}
