import * as React from "react";
import { useDrag } from "react-dnd";

import "./style.scss";
import TextData from "../../atoms/TextData";
import { ReactComponent as ArrowSVG } from "./arrow.svg";
import { ReactComponent as CloseSVG } from "./close.svg";
import { ReactComponent as CoffeeSVG } from "./Coffee.svg";
import { ReactComponent as ExpandSVG } from "./expand.svg";
import { ReactComponent as CollapseSVG } from "./collapse.svg";
import { ReactComponent as PlusSVG } from "./plus.svg";
import Button from "../../atoms/Button";
import NewTicketCard from "../../organism/NewTicketCard";
import Modal from "../../atoms/Modal";
import AttendanceCard from "../../organism/AttendanceCard";
import { fomatStartEndTime } from "../../../_helpers/Functions";
import { useBranch } from "../../../hooks/useBranch";

function PlanningShifts(props: any /*PlanningShiftsProps*/) {
    const {
        setOpenNewFixedPlan,
        setEditThisShift,
        setIsFixedEditing,
        onClick,
        fixedPlans,
        setDraggedShiftType,
    } = props;
    const { branchSettings } = useBranch();
    const [isCollapse, setIsCollapse] = React.useState(true);
    const handleCollapse = () => {
        setIsCollapse(!isCollapse);
    };
    const CardItem = (props: any) => {
        const { id, shift, onCardClick } = props;
        // Drag & Drop events
        // const [{ isDragging }, drag] = useDrag(() => ({
        //     type: "shift",
        //     item: { id: id },
        //     collect: (monitor) => ({
        //         isDragging: !!monitor.isDragging(),
        //     }),
        // }));
        // Drag & Drop events
        const handleDragStart = async (
            event: React.DragEvent<HTMLDivElement>,
        ) => {
            await setDraggedShiftType("predefined");
            event.dataTransfer.setData(
                "text/plain",
                JSON.stringify({ type: "predefined", shift }),
            );
            // Set the shift data to be transferred during drag
            // event.dataTransfer.setData("text/plain", JSON.stringify(shift));
        };
        if (isCollapse) {
            return (
                <div
                    className="card-item-container"
                    // ref={drag}
                    draggable
                    onDragStart={handleDragStart}
                    onClick={onCardClick}
                    style={{
                        justifyContent: "center",
                        cursor: "grab",
                        width: "65%",
                    }}
                >
                    <div
                        className="card-item-line"
                        style={{
                            backgroundColor: shift?.steaker?.color || "black",
                        }}
                    ></div>
                </div>
            );
        } else {
            return (
                <div
                    className="card-item-container"
                    onClick={onCardClick}
                    style={{
                        cursor: "grab",
                        // width: "220px",
                        width: "90%",
                    }}
                    // ref={drag}
                    draggable
                    onDragStart={handleDragStart}
                >
                    <div
                        className="card-item-line"
                        style={{
                            backgroundColor: shift?.steaker?.color || "black",
                        }}
                    ></div>
                    <div className="card-item-box">
                        {shift.type === 1 ? (
                            <TextData
                                width="auto"
                                variant="label"
                                color="#4D5E80"
                                size="14px"
                                fontWeight="700"
                                style={{
                                    cursor: "grab",
                                }}
                            >
                                {shift?.steaker?.name.slice(0, 14) || "test"}
                                {shift?.steaker?.name.length > 14 && "..."}
                            </TextData>
                        ) : (
                            <TextData
                                width="auto"
                                variant="label"
                                color="#4D5E80"
                                size="14px"
                                fontWeight="700"
                                style={{
                                    cursor: "grab",
                                }}
                            >
                                {shift?.typeAbsence || "test"}
                                {/* {shift?.typeAbsence.slice(0, 14) || "test"}
                                {shift?.typeAbsence.length > 14 && "..."} */}
                            </TextData>
                        )}

                        {shift?.from === 0 && shift?.to === 1439 ? (
                            <TextData
                                width="auto"
                                variant="label"
                                color="#4D5E80"
                                size="12px"
                                fontWeight="500"
                                style={{
                                    cursor: "grab",
                                }}
                            >
                                Toute la journée
                            </TextData>
                        ) : (
                            <TextData
                                width="auto"
                                variant="label"
                                color="#4D5E80"
                                size="12px"
                                fontWeight="500"
                                style={{
                                    cursor: "grab",
                                }}
                            >
                                {fomatStartEndTime(shift?.from)} -{" "}
                                {fomatStartEndTime(shift?.to)}{" "}
                                {shift?.pause > 0 &&
                                    branchSettings.usePause &&
                                    "(" + shift?.pause + " min)"}
                            </TextData>
                        )}
                    </div>
                </div>
            );
        }
    };
    const handleOpenModifyShift = (shift: any) => {
        setIsFixedEditing(true);
        setEditThisShift(shift);
        setOpenNewFixedPlan(true);
    };
    if (isCollapse) {
        return (
            <div
                className="planning-shift-container"
                style={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    boxShadow: "1px 1px 4px #11106e1a",
                    width: "5%",
                    position: "sticky",
                    top: "0",
                    right: "0",
                }}
            >
                <div
                    className="planning-shift-header"
                    onClick={handleCollapse}
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <CollapseSVG
                        width={25}
                        height={25}
                        color="#11106e"
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={handleCollapse}
                    />
                    {/* <ExpandSVG
                        width={25}
                        height={25}
                        color='#11106e'
                        style={{
                            transform: "rotate(180deg)",
                            cursor: "pointer",
                        }}
                    /> */}
                    {/* <ArrowSVG
                        style={{
                            transform: "rotate(180deg)",
                            minWidth: "32px",
                            minHeight: "32px",
                            cursor: "pointer",
                        }}
                    /> */}
                </div>
                <div
                    className="planning-shift-body"
                    style={{
                        width: "100%",
                        maxHeight: "100%",
                        // paddingLeft: "10px",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    {fixedPlans?.map((shift: any, key: any) => {
                        return (
                            <CardItem
                                id={key}
                                shift={shift}
                                onCardClick={() => handleOpenModifyShift(shift)}
                            />
                        );
                    })}
                    <div
                        style={{
                            position: "sticky",
                            bottom: "0",
                            backgroundColor: "#edeff5",
                            // padding: "5px 5px 0",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            className="add-card-item-container "
                            style={{
                                width: "65%",
                            }}
                        >
                            <Button
                                style={{
                                    margin: "2px 0",
                                }}
                                height="100%"
                                width="100%"
                                backgroundColor="#FFF"
                                onClick={() => {
                                    setOpenNewFixedPlan(true);
                                    setIsFixedEditing(false);
                                }}
                            >
                                <PlusSVG color="#3728ab" />
                            </Button>
                            {/* <AttendanceCard
                            display={openNewService}
                            onClose={() => setOpenNewService(false)}
                            withEmployees={false}
                        /> */}
                        </div>
                        {/* <div
                            className='auto-replace-card-container'
                            style={{
                                width: "65%",
                            }}
                        >
                            <CoffeeSVG
                                style={{
                                    minWidth: "24px",
                                    minHeight: "24px",
                                }}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="planning-shift-container"
                style={{
                    width: "15%",
                    position: "sticky",
                    top: "0",
                    right: "0",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    boxShadow: "1px 1px 4px #11106e1a",
                }}
            >
                <div
                    className="planning-shift-header"
                    style={{
                        width: "90%",
                    }}
                >
                    <CollapseSVG
                        width={30}
                        height={30}
                        color="#11106e"
                        style={{
                            transform: "rotate(180deg)",
                            cursor: "pointer",
                            marginLeft: "10px",
                        }}
                        onClick={handleCollapse}
                    />
                    <TextData
                        width="auto"
                        variant="regular"
                        fontWeight="700"
                        size="16"
                        color="#11106E"
                        style={{
                            overflowWrap: "break-word",
                            marginRight: "10px",
                            marginLeft: "5px",
                            textAlign: "center",
                        }}
                    >
                        Mes shifts prédéfinis
                    </TextData>
                    {/* <ArrowSVG
                        style={{
                            minWidth: "32px",
                            minHeight: "32px",
                            marginRight: "10px",
                            cursor: "pointer",
                        }}
                        onClick={handleCollapse}
                    /> */}
                </div>
                <div
                    className="planning-shift-body"
                    style={{
                        width: "100%",
                        maxHeight: "100%",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        // padding: "0 12px",
                    }}
                >
                    {fixedPlans?.map((shift: any, key: any) => {
                        return (
                            <CardItem
                                id={key}
                                shift={shift}
                                onCardClick={() => handleOpenModifyShift(shift)}
                            />
                        );
                    })}
                    <div
                        style={{
                            position: "sticky",
                            bottom: "0",
                            backgroundColor: "#edeff5",
                            // padding: "5px 5px 0",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            className="add-card-item-container"
                            style={{ width: "90%" }}
                        >
                            <Button
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "3px",
                                }}
                                height="100%"
                                width="100%"
                                backgroundColor="#fff"
                                onClick={() => setOpenNewFixedPlan(true)}
                            >
                                <PlusSVG
                                    height={22}
                                    width={22}
                                    color="#3728ab"
                                />
                                <div
                                    style={{
                                        textAlign: "left",
                                        padding: "0",
                                        width: "70%",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        color: "#3728ab",
                                    }}
                                >
                                    Ajouter un shift prédéfini
                                </div>
                            </Button>
                            {/* <AttendanceCard
                            display={openNewService}
                            onClose={() => setOpenNewService(false)}
                            withEmployees={false}
                        /> */}
                        </div>
                        {/* <div
                            className='auto-replace-card-container'
                            style={{ width: "90%" }}
                        >
                            <CoffeeSVG
                                // style={{
                                //     minWidth: "18px",
                                //     minHeight: "18px",
                                // }}
                                height={19}
                                width={19}
                            />
                            <TextData
                                width='auto'
                                size='11px'
                                fontWeight='500'
                                color='#2A8BAB'
                                style={{
                                    marginLeft: 5,
                                    overflowWrap: "break-word",
                                }}
                            >
                                Remplissage automatique
                            </TextData>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}
export default React.memo(PlanningShifts);
interface PlanningShiftsProps {
    shifts: any;
}
PlanningShifts.defaultProps = {
    shifts: [
        {
            name: "Bandol",
            description: "11:00 -18:00",
            color: "#CC7429",
        },
        {
            name: "Cuisine",
            description: "11:00 -18:00",
            color: "#29CC39",
        },
        // {
        //     name: "Serveur",
        //     description: "11:00 -18:00",
        //     color: "#8833FF",
        // },
        // {
        //     name: "Commis",
        //     description: "11:00 -18:00",
        //     color: "#33BFFF",
        // },
        // {
        //     name: "Légumes",
        //     description: "11:00 -18:00",
        //     color: "#2EE6CA",
        // },
    ],
};
