import React from "react";
import { FieldInterface } from "../../../_helpers/FormHelpers";
import InputInfos from "../InputInfos";

export default function ZInput({
    type = "text",
    field,
    required = false,
    onUpdate,
    onBlur,
    label,
    labelRightAction,
    placeholder,
    withFeedbackLabel = true,
    rightInputAction,
    leftInputAction,
    onInputClick,
    labelClasses = "",
    inputClasses = "",
    dpae = false,
    rup = false,
    desactivated = false,
    maxLength,
    isNumber = false,
    isPhoneNumber = false,
    style,
    regex,
}: ZInputInterface) {
    const { error, hasError, value, touched, name } = field;
    const getClassNameInput = () => {
        let baseClass: string =
            "h-full w-full border rounded-md focus:outline-none focus:border-t-500 px-2 py-1 text-md font-light text-n-600 peer";
        if (rightInputAction) {
            baseClass += " !pr-[50px]";
        }
        if (leftInputAction) {
            baseClass += " !pl-[30px]";
        }

        if (touched && hasError) {
            baseClass += " border-error";
        } else {
            baseClass += " border-n-100";
        }
        return `${baseClass} ${inputClasses}`;
    };
    const decimalPattern = /^[+-]?\d+(\.\d+)?$/;
    const phoneNumberPattern = /^\d+$/;
    return (
        <div className="w-full">
            {label && (
                <div className="flex justify-between text-n-400">
                    <div className={`flex items-center`}>
                        <label className="text-base font-medium mb-1 ${labelClasses}">
                            {label}
                        </label>
                        {required && (
                            <span className="text-error font-semibold">*</span>
                        )}
                        <InputInfos dpae={dpae} rup={rup} />
                    </div>
                    <div>
                        {maxLength && type === "text" && (
                            <span>
                                {String(value)?.length}/{maxLength}
                            </span>
                        )}

                        {labelRightAction && labelRightAction}
                    </div>
                </div>
            )}
            <div className="relative h-[48px] flex items-center">
                <input
                    autoComplete="new-password"
                    disabled={desactivated}
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onBlur={() => {
                        onBlur && onBlur(field.name);
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === "") {
                            onUpdate && onUpdate(e.target.value);
                        } else if (isNumber || type === "number") {
                            if (decimalPattern.test(e.target.value)) {
                                if (
                                    maxLength &&
                                    String(e.target.value).length <= maxLength
                                ) {
                                    onUpdate && onUpdate(e.target.value);
                                } else if (!maxLength) {
                                    onUpdate && onUpdate(e.target.value);
                                }
                            }
                        } else if (isPhoneNumber) {
                            if (phoneNumberPattern.test(e.target.value)) {
                                onUpdate && onUpdate(e.target.value);
                            }
                        } else {
                            onUpdate && onUpdate(e.target.value);
                        }
                    }}
                    onClick={() => onInputClick && onInputClick()}
                    className={getClassNameInput()}
                    maxLength={maxLength}
                    // pattern="^[0-9]+$"
                    style={{
                        ...style,
                    }}
                />
                {rightInputAction && (
                    <div
                        className={`absolute right-4 ${
                            touched && hasError ? "text-error" : "text-n-500"
                        } peer-focus:text-t-500`}
                        onClick={() => onInputClick && onInputClick()}
                    >
                        {rightInputAction}
                    </div>
                )}
                {leftInputAction && (
                    <div
                        className={`absolute left-0 ${
                            touched && hasError ? "text-error" : "text-n-500"
                        } peer-focus:text-t-500`}
                    >
                        {leftInputAction}
                    </div>
                )}
            </div>
            <div>
                {withFeedbackLabel && hasError && (
                    <span className="text-error text-sm font-light w-full max-w-[20px]">
                        {error}
                    </span>
                )}
            </div>
        </div>
    );
}

interface ZInputInterface {
    withFeedbackLabel?: boolean;
    placeholder?: string;
    type?: "text" | "number" | "password" | "time" | "email";
    required: boolean;
    onUpdate?: (data: string | number) => void;
    onBlur?: (field: string) => void;
    field: FieldInterface;
    label?: string | React.ReactNode;
    labelRightAction?: React.ReactNode;
    rightInputAction?: React.ReactNode;
    leftInputAction?: React.ReactNode;
    onInputClick?: () => void;
    labelClasses?: string;
    inputClasses?: string;
    dpae?: boolean;
    rup?: boolean;
    desactivated?: boolean;
    maxLength?: number;
    isNumber?: boolean;
    isPhoneNumber?: boolean;
    style?: any;
    regex?: RegExp;
}
