import * as React from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { ReactComponent as ArrowDown } from "./Arrow.svg";
import TextData from "../TextData";

import "./style.scss";
const CustomOption = (props: any) => {
    return (
        <components.Option {...props}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "8px" }}>{props.data.icon}</span>
                <span>{props.data.label}</span>
            </div>
        </components.Option>
    );
};
const SelectInput = (props: SelectInputProps) => {
    const {
        isCreatable,

        id,
        isDisabled,
        isSearchable,
        options,
        isMultiple,
        placeholder,
        optionColor,
        optionSize,

        //////////////
        title,
        selectedValue,
        captionColor,
        captionSize,
        onSelectOption,
        onBlur,
        style,
        styleOption,
        menuPlacement,
        isChanging,
        required = false,
    } = props;

    const [optionItems, setOptionItems] = React.useState<any>(options);
    const [selectedVal, setselectedVal] = React.useState<any>(() => {
        if (!selectedValue) return null;
        const res = options.filter(
            (item: any) => item.value === selectedValue.value,
        );
        return res;
    });
    const [isOpen, setIsOpen] = React.useState(false);
    React.useEffect(() => {
        setselectedVal(selectedValue);
    }, [selectedValue]);
    React.useEffect(() => {
        setOptionItems(options);
    }, [options]);

    const handleClick = (selectedDataOption: any) => {
        setselectedVal(selectedDataOption);
        onSelectOption && onSelectOption(selectedDataOption); // todo verify this merge
    };

    const customStyles: any = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderRadius: "4px",
            // padding: "4px 2px",
            borderColor: "#C7CBE2",
            color: "#4C5690",
            boxShadow: "none",
            "&:hover": {},
            ...style,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#EDEFF5" : "white",
            color: "#4C5690",
            fontWeight: "500",
            fontSize: "12px",
            cursor: "pointer",
            // fontWeight: "400",
            // fontSize: "14px",
            // cursor: "pointer",
            ...styleOption,
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: "red",
        }),
        placeholder: (provided: any, state: any) => ({
            ...provided,
        }),
        multiValue: (provided: any, state: any) => ({
            ...provided,
        }),
        multiValueLabel: (provided: any, state: any) => ({
            ...provided,
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            marginTop: "0px",
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    };
    return (
        <div
            className="select-input-container"
            style={{
                opacity: isDisabled ? "0.4" : "1",
            }}
        >
            {/* <span
                style={{
                    color: captionColor,
                    fontSize: captionSize,
                    textAlign: "left",
                }}
            >
                {title}{" "}
            </span> */}
            {title && (
                <div
                    className={`flex items-center`}
                    style={{
                        color: "#6f78ab",
                    }}
                >
                    <label className="text-base font-medium mb-1 ${labelClasses}">
                        {title}
                    </label>
                    {required && (
                        <span
                            className="text-error font-semibold"
                            style={{
                                color: "red",
                            }}
                        >
                            *
                        </span>
                    )}
                </div>
            )}

            {isCreatable ? (
                <CreatableSelect
                    formatCreateLabel={(inputValue) => `➕ ${inputValue}`}
                    id={id}
                    className="_select-input"
                    isDisabled={isDisabled}
                    isSearchable={isSearchable}
                    isMulti={isMultiple}
                    options={optionItems}
                    value={isChanging ? selectedVal : null}
                    placeholder={placeholder}
                    styles={customStyles}
                    menuPlacement={menuPlacement}
                    onMenuOpen={() => {
                        setIsOpen(true);
                    }}
                    required={required}
                    onMenuClose={() => {
                        setIsOpen(false);
                    }}
                    onChange={(val) => handleClick && handleClick(val)}
                    onBlur={() => {
                        onBlur && onBlur();
                    }}
                    formatOptionLabel={(option: any) => {
                        const { label, value, icon } = option;
                        if (icon) {
                            return (
                                <div
                                    id={value}
                                    className="custom-option-container"
                                >
                                    {icon}
                                    <TextData
                                        width="auto"
                                        variant="label"
                                        color={optionColor}
                                        size={optionSize}
                                        fontWeight="400"
                                    >
                                        {label}
                                    </TextData>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    id={value}
                                    className="custom-option-container"
                                >
                                    <TextData
                                        width="auto"
                                        variant="label"
                                        color={optionColor}
                                        size={optionSize}
                                        fontWeight="400"
                                    >
                                        {label}
                                    </TextData>
                                </div>
                            );
                        }
                    }}
                    components={{
                        DropdownIndicator: () => {
                            return (
                                <div
                                    className="arrow-container"
                                    style={{
                                        transform: isOpen
                                            ? "rotate(180deg)"
                                            : "",
                                    }}
                                >
                                    <ArrowDown />
                                </div>
                            );
                        },
                        IndicatorSeparator: null,
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                />
            ) : (
                <Select
                    id={id}
                    className="_select-input"
                    isDisabled={isDisabled}
                    isSearchable={isSearchable}
                    isMulti={isMultiple}
                    options={optionItems}
                    value={isChanging ? selectedVal : null}
                    placeholder={placeholder}
                    styles={customStyles}
                    menuPlacement={menuPlacement}
                    required={required}
                    onMenuOpen={() => {
                        setIsOpen(true);
                    }}
                    onMenuClose={() => {
                        setIsOpen(false);
                    }}
                    onChange={(val) => handleClick && handleClick(val)}
                    onBlur={() => {
                        onBlur && onBlur();
                    }}
                    noOptionsMessage={() => "Aucune option disponible"}
                    formatOptionLabel={(option: any) => {
                        const { label, value, icon } = option;
                        if (icon) {
                            return (
                                <div
                                    id={value}
                                    className="custom-option-container"
                                >
                                    {icon}
                                    <TextData
                                        width="auto"
                                        variant="label"
                                        color={optionColor}
                                        size={optionSize}
                                        fontWeight="400"
                                    >
                                        {label}
                                    </TextData>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    id={value}
                                    className="custom-option-container"
                                >
                                    <TextData
                                        width="auto"
                                        variant="label"
                                        color={optionColor}
                                        size={optionSize}
                                        fontWeight="400"
                                    >
                                        {label}
                                    </TextData>
                                </div>
                            );
                        }
                    }}
                    components={{
                        DropdownIndicator: () => {
                            return (
                                <div
                                    className="arrow-container"
                                    style={{
                                        transform: isOpen
                                            ? "rotate(180deg)"
                                            : "",
                                    }}
                                >
                                    <ArrowDown />
                                </div>
                            );
                        },
                        IndicatorSeparator: null,
                        Option: CustomOption,
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                />
            )}
        </div>
    );
};

interface OptionType {
    value: any;
    label: string | React.ReactNode;
    icon?: React.ReactNode;
}
interface SelectInputProps {
    id?: string;
    isDisabled?: boolean;
    isSearchable?: boolean;
    isCreatable?: boolean;
    options: OptionType[];
    isMultiple?: boolean;
    title: string;
    selectedValue?: OptionType | null;
    optionColor?: string;
    optionSize?: string;
    captionColor?: string;
    captionSize?: string;
    style?: React.CSSProperties;
    styleOption?: React.CSSProperties;
    onSelectOption?: (option: any) => void;
    onBlur?: () => void;
    placeholder?: string | React.ReactNode;
    menuPlacement?: any;
    isChanging?: boolean;
    required?: boolean;
}

SelectInput.defaultProps = {
    isCreatable: false,
    id: "mySelect",
    isDisabled: false,
    isSearchable: true,
    selectedValue: null,
    options: [
        { label: "Marseille", value: "something", icon: null },
        { label: "Paris", value: "Paris", icon: null },
        { label: "Ile de france", value: "something", icon: null },
    ],
    title: "Etablissement",
    captionColor: "",
    captionSize: "12px",
    optionColor: "#4C5690",
    optionSize: "14px",
    onSelectOption: (option: any) => {},
    placeholder: "Select an option",
    menuPlacement: "auto",
    isChanging: true,
    required: false,
};
export default SelectInput;
