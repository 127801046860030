import { useAppDispatch, useAppSelector } from "./useReduxHooks";

import {
    updateBranch,
    getBranch,
    getMultiplePlanning,
    getTicket,
    getService,
    getBonusType,
    getAbsence,
    saveMultiplePlanning,
    deleteMultiplePlanning,
    saveTicket,
    removeTicket,
    saveService,
    removeService,
    generateBonusType,
    modifyBonusType,
    removeBonusType,
    modifyAbsence,
} from "../redux/features/settings/service";
import {
    updateSelectedPlan,
    updateSelectedTicket,
    updateSelectedService,
    updateSelectedBonusType,
    updateSelectedAbsence,
    updateScheduleAuthorization,
    updateScheduleMultiplePlanningStatus,
} from "../redux/features/settings/settingsSlice";
import {
    multiplePlanningPayload,
    deleteMultiplePlanningRequest,
    ticketPayload,
    servicePayload,
    bonusTypePayload,
    absencePayload,
    deleteTicketsRequest,
    deleteServicesRequest,
    deleteBonusTypesRequest,
} from "../redux/features/settings/types";
import { useBranch } from "./useBranch";

export const useSchedule = () => {
    const { getBranchSettings } = useBranch();
    const dispatch = useAppDispatch();
    const scheduleSettings = useAppSelector(
        (state: any) => state.settings,
    ).schedule;
    const multiplePlannings = useAppSelector((state: any) => state.settings)
        .schedule.multiplePlanning;
    const tickets = useAppSelector((state: any) => state.settings).schedule
        .tickets;
    const services = useAppSelector((state: any) => state.settings).schedule
        .services;
    const bonusTypes = useAppSelector((state: any) => state.settings).schedule
        .bonusTypes;
    const absences = useAppSelector((state: any) => state.settings).schedule
        .absences;

    const selectedPlan = useAppSelector((state: any) => state.settings).schedule
        .selectedPlan;
    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;
    const selectedTicket = useAppSelector((state: any) => state.settings)
        .schedule.selectedTicket;
    const selectedService = useAppSelector((state: any) => state.settings)
        .schedule.selectedService;
    const selectedBonusType = useAppSelector((state: any) => state.settings)
        .schedule.selectedBonusType;
    const selectedAbsence = useAppSelector((state: any) => state.settings)
        .schedule.selectedAbsence;

    const getMultiplePlannings = () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings._id) {
            dispatch(
                getMultiplePlanning({
                    token,
                    branchID: selectedBranchSettings._id,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const getTickets = () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings._id) {
            dispatch(
                getTicket({
                    token,
                    branchID: selectedBranchSettings._id,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const getServices = () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings._id) {
            dispatch(
                getService({
                    token,
                    branchID: selectedBranchSettings._id,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const getBonusTypes = () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings._id) {
            dispatch(
                getBonusType({
                    token,
                    branchID: selectedBranchSettings._id,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const getAbsences = () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings._id) {
            dispatch(
                getAbsence({
                    token,
                    branchID: selectedBranchSettings._id,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const getScheduleSettings = () => {
        getBranchSettings();
        getMultiplePlannings();
        getTickets();
        getServices();
        // getBonusTypes();
        getAbsences();
    };
    const saveSelectedPlan = (payload: multiplePlanningPayload) => {
        dispatch(
            updateSelectedPlan({
                _id: payload._id,
                employees: payload.employees,
                name: payload.name,
                site: payload.site,
            }),
        );
    };
    const saveSelectedTicket = (payload: ticketPayload) => {
        dispatch(
            updateSelectedTicket({
                _id: payload._id,
                name: payload.name,
                color: payload.color,
                site: payload.site,
                fromHour: payload.fromHour,
                toHour: payload.toHour,
            }),
        );
    };
    const saveSelectedService = (payload: servicePayload) => {
        dispatch(
            updateSelectedService({
                id: payload.id,
                from: payload.from,
                to: payload.to,
                name: payload.name,
                siteId: payload.siteId,
            }),
        );
    };
    const saveSelectedBonusType = (payload: bonusTypePayload) => {
        dispatch(
            updateSelectedBonusType({
                acompteTypeId: payload.acompteTypeId,
                name: payload.name,
                affectSalary: payload.affectSalary,
                affectType: payload.affectType,
                siteId: payload.siteId,
            }),
        );
    };
    const saveSelectedAbsence = (payload: absencePayload) => {
        dispatch(
            updateSelectedAbsence({
                _id: payload._id,
                absence: payload.absence,
                color: payload.color,
                site: payload.site,
            }),
        );
    };
    const saveScheduleSettings = (payload: any) => {
        if (payload.authorization !== undefined) {
            dispatch(
                updateScheduleAuthorization({
                    authorization: payload.authorization,
                }),
            );
        }
        if (payload.multiplePlanningStatus !== undefined) {
            dispatch(
                updateScheduleMultiplePlanningStatus({
                    multiplePlanningStatus: payload.multiplePlanningStatus,
                }),
            );
        }
    };
    //update/create actions
    const updateMultiPlanning = (payload: any) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            dispatch(
                saveMultiplePlanning({
                    token,
                    multiplePlanning: payload,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getMultiplePlannings();
                }
            });
        }
    };
    const updateTicket = (payload: any) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            dispatch(
                saveTicket({
                    token,
                    ticket: payload,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getTickets();
                }
            });
        }
    };
    const updateService = (payload: any) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            dispatch(
                saveService({
                    token,
                    service: payload,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getServices();
                }
            });
        }
    };
    const createBonusType = (payload: any) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            dispatch(
                generateBonusType({
                    token,
                    bonusType: payload,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getBonusTypes();
                }
            });
        }
    };
    const updateBonusType = (payload: any) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            dispatch(
                modifyBonusType({
                    token,
                    bonusType: payload,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getBonusTypes();
                }
            });
        }
    };
    const updateAbsence = (payload: any) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            dispatch(
                modifyAbsence({
                    token,
                    absence: payload,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getAbsences();
                }
            });
        }
    };
    const updateScheduleSettings = () => {
        const token = localStorage.getItem("token");
        if (selectedAbsence.color) {
            const absencePayload = {
                siteId: selectedBranchSettings._id,
                absence: selectedAbsence.absence,
                color: selectedAbsence.color,
                absenceId: selectedAbsence._id,
            };
            updateAbsence(absencePayload);
        }
        if (token && selectedBranchSettings._id) {
            dispatch(
                updateBranch({
                    token,
                    branchID: selectedBranchSettings._id,
                    body: {
                        changementShift: scheduleSettings.authorization,
                        multiplePlanning:
                            scheduleSettings.multiplePlanningStatus,
                    },
                }),
            );
        }
        //TODO: update other settings
    };

    //delete actions
    const deleteMultiPlanning = (payload: string) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            const requestPayload: deleteMultiplePlanningRequest = {
                token,
                planningID: payload,
            };
            dispatch(deleteMultiplePlanning(requestPayload)).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getMultiplePlannings();
                    // return true;
                }
            });
        }
        // return false;
    };
    const deleteTicket = (payload: string) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            const requestPayload: deleteTicketsRequest = {
                token,
                ticketID: payload,
            };
            dispatch(removeTicket(requestPayload)).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getTickets();
                }
            });
        }
    };
    const deleteService = (payload: string) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            const requestPayload: deleteServicesRequest = {
                token,
                serviceID: payload,
            };
            dispatch(removeService(requestPayload)).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getServices();
                }
            });
        }
    };
    const deleteBonusType = (payload: string) => {
        const token = localStorage.getItem("token");
        if (token && payload) {
            const requestPayload: deleteBonusTypesRequest = {
                token,
                bonusTypeID: payload,
            };
            dispatch(removeBonusType(requestPayload)).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    getBonusTypes();
                }
            });
        }
    };
    return {
        scheduleSettings,
        updateScheduleSettings,
        saveScheduleSettings,
        // getted data stored in
        multiplePlannings,
        tickets,
        services,
        bonusTypes,
        absences,
        // selected items
        selectedPlan,
        selectedTicket,
        selectedService,
        selectedBonusType,
        selectedAbsence,
        //get data from server
        getMultiplePlannings,
        getTickets,
        getServices,
        getBonusTypes,
        getAbsences,
        getScheduleSettings,
        //save to slice settings state
        saveSelectedPlan,
        saveSelectedTicket,
        saveSelectedService,
        saveSelectedBonusType,
        saveSelectedAbsence,
        //updates to server
        updateMultiPlanning,
        updateTicket,
        updateService,
        createBonusType,
        updateBonusType,
        updateAbsence,
        //delete from server
        deleteMultiPlanning,
        deleteTicket,
        deleteService,
        deleteBonusType,
    };
};
