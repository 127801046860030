import React from "react";
import EmployesCreate from "../../../components/templates/EmployesCreate";
import { useEmployees } from "../../../hooks/useEmployees";
import { useSites } from "../../../hooks/useSites";
import {
    generateFields,
    Validation,
    FieldInterface,
    destructFields,
} from "../../../_helpers/FormHelpers";
import { CreateEployeeRequest } from "../../../redux/features/employees/types";
import { useIntl } from "react-intl";
import { useNotificationContext } from "../../../Context/Notification";
import usePointeuse from "../../../hooks/usePointeuse";
import { useBranch } from "../../../hooks/useBranch";
import PreviousMap from "postcss/lib/previous-map";

export default function EmployeeCreate({
    show,
    onHide,
}: EmployesCreateInterface) {
    const employeesActions = useEmployees();
    const sitesActions = useSites();
    const intl = useIntl();
    const { addNotification } = useNotificationContext();
    React.useEffect(() => {
        sitesActions.getSitesManagement();
    }, []);

    const fields = [
        { name: "fname", value: "" },
        { name: "lname", value: "" },
        { name: "email", value: "" },
        { name: "phone", value: "" },
        { name: "tempsDeTravailHebdomadaire", value: "" },
        { name: "tempsDeTravailMensuel", value: "" },
        { name: "file", value: null },
        { name: "pin", value: "" },
        { name: "role", value: "Employee" },
        { name: "manage", value: [] },
        { name: "belongsTo", value: [] },
        {
            name: "planning",
            value: [],
            // value: sitesActions.sitesManagement.map((site) => site._id),
        },
        { name: "departements", value: [] },
    ];

    React.useEffect(() => {
        let newEmployee = generateFields(fields);
        setEmployee(newEmployee);
        // validate(newEmployee);
    }, [sitesActions.sitesManagement]);
    const [employee, setEmployee] = React.useState<Employee>(() => {
        return generateFields(fields);
    });

    React.useEffect(() => {
        validate(employee);
    }, []);
    const existPinMessage = "Ce code pin est déjà existant !";
    const existEmailMessage = "Cette adresse email est déjà utilisée !";
    const existPhoneMessage = "Ce numéro de téléphone est déjà existant !";
    const atLeastOneDepartmentMessage =
        "Veuillez sélectionner au moins un département !";
    const onChange = async (field: string, value: any) => {
        // console.log(" onChange = / = ", field);
        // let newState = {
        //     ...employee,
        //     [field]: {
        //         ...employee[field],
        //         value,
        //         touched: true,
        //     },
        // };
        await setEmployee((prevEmployee: any) => {
            return {
                ...prevEmployee,
                [field]: {
                    ...prevEmployee[field],
                    value,
                    touched: true,
                },
            };
        });
        // console.log("new emp: ", employee);
        await validate({
            [field]: {
                ...employee[field],
                value,
                touched: true,
            },
        });
        let exist = false;
        if (field === "pin") {
            allEmpsInApp?.map((emp: any) => {
                if (emp.pin === value) {
                    exist = true;
                    setEmployee((prevEmployee: any) => {
                        return {
                            ...prevEmployee,
                            [field]: {
                                ...prevEmployee[field],
                                hasError: true,
                                touched: true,
                                error: existPinMessage,
                            },
                        };
                    });
                }
            });
            if (!exist) {
                setEmployee((prevEmployee: any) => {
                    return {
                        ...prevEmployee,
                        [field]: {
                            ...prevEmployee[field],
                            hasError: false,
                            touched: true,
                            error: "",
                        },
                    };
                });
            }
        }
        exist = false;
        if (field === "email") {
            allEmpsInApp?.map((emp: any) => {
                if (emp.email === value && value !== "") {
                    exist = true;
                    setEmployee((prevEmployee: any) => {
                        return {
                            ...prevEmployee,
                            [field]: {
                                ...prevEmployee[field],
                                hasError: true,
                                touched: true,
                                error: existEmailMessage,
                            },
                        };
                    });
                }
            });
            if (!exist) {
                setEmployee((prevEmployee: any) => {
                    return {
                        ...prevEmployee,
                        [field]: {
                            ...prevEmployee[field],
                            hasError: false,
                            touched: true,
                            error: "",
                        },
                    };
                });
            }
        }
        exist = false;
        if (field === "phone") {
            allEmpsInApp?.map((emp: any) => {
                if (emp.phone === value && value !== "") {
                    exist = true;
                    setEmployee((prevEmployee: any) => {
                        return {
                            ...prevEmployee,
                            [field]: {
                                ...prevEmployee[field],
                                hasError: true,
                                touched: true,
                                error: existPhoneMessage,
                            },
                        };
                    });
                }
            });
            if (!exist) {
                setEmployee((prevEmployee: any) => {
                    return {
                        ...prevEmployee,
                        [field]: {
                            ...prevEmployee[field],
                            hasError: false,
                            touched: true,
                            error: "",
                        },
                    };
                });
            }
        }
    };

    const onBlur = (field: string) => {
        const newState = {
            ...employee,
            [field]: {
                ...employee[field],
                touched: true,
            },
        };
        setEmployee(newState);
        validate({
            [field]: {
                ...employee[field],
                touched: true,
            },
        });
    };

    const validate = async (state: any /*Employee*/) => {
        // setEmployee((prev) => ({ ...prev, ...state }));

        let toValidate = new Validation();
        if (state.fname) {
            toValidate.add(state.fname, [
                { REQUIRED: true, message: "Ce champ est obligatoire !" },
            ]);
        }
        if (state.lname) {
            toValidate.add(state.lname, [
                { REQUIRED: true, message: "Ce champ est obligatoire !" },
            ]);
        }
        if (state.pin) {
            toValidate.add(state.pin, [
                { REQUIRED: true, message: "Ce champ est obligatoire !" },
                { MIN_LENGTH: 4, message: "Il faut renseigner 4 chiffres" },
                { MAX_LENGTH: 4, message: "Il faut renseigner 4 chiffres" },
                {
                    EXIST: state.pin.error === existPinMessage,
                    message: existPinMessage,
                },
            ]);
        }
        if (state.email) {
            toValidate
                .add(state.email, [
                    {
                        REGEX: "^$|^\\S+@\\S+\\.\\S+$",
                        message:
                            "Le format doit être comme ceci : contact@esperoo.fr",
                    },
                ])
                .add(state.email, [
                    {
                        EXIST: state.email.error === existEmailMessage,
                        message: existEmailMessage,
                    },
                ]);
        }
        if (state.phone) {
            toValidate.add(state.phone, [
                {
                    EXIST: state.phone.error === existPhoneMessage,
                    message: existPhoneMessage,
                },
            ]);
        }
        if (state.belongsTo) {
            toValidate.add(state.belongsTo, [
                {
                    AT_LEAST_ONE: !state.belongsTo.value?.length,
                    message:
                        "Veuillez choisir un établissement d'affiliation !",
                },
            ]);
        }
        if (state.departements) {
            let isErrorWithDepatment = false;
            if (employee.belongsTo?.value?.length) {
                siteDepartements.map((siteDep: any) => {
                    const { site, depts } = siteDep;
                    if (
                        employee.belongsTo?.value.includes(site) &&
                        depts.length > 0
                    ) {
                        let got = false;
                        state.departements.value?.map((item: any) => {
                            if (item.site === site) {
                                got = true;
                            }
                        });
                        isErrorWithDepatment = !got;
                    }
                });
            }
            toValidate.add(state.departements, [
                {
                    AT_LEAST_ONE: isErrorWithDepatment,
                    message: atLeastOneDepartmentMessage,
                },
            ]);
        }
        toValidate.validate(async (res) => {
            await setEmployee((prev) => {
                // console.log("before update  == ", {
                //     ...prev,
                //     ...generateFields(res),
                // });
                return {
                    ...prev,
                    ...generateFields(res),
                };
            });
        });
        // new Validation();
        // .add(state.fname, [
        //     { REQUIRED: true, message: "Ce champ est obligatoire !" },
        // ])
        // .add(state.lname, [
        //     { REQUIRED: true, message: "Ce champ est obligatoire !" },
        // ])
        // .add(state.pin, [
        //     { REQUIRED: true, message: "Ce champ est obligatoire !" },
        //     { MIN_LENGTH: 4, message: "Il faut renseigner 4 chiffres" },
        //     { MAX_LENGTH: 4, message: "Il faut renseigner 4 chiffres" },
        //     {
        //         EXIST: state.pin.error === existPinMessage,
        //         message: existPinMessage,
        //     },
        // ])
        // .add(state.email, [
        //     {
        //         REGEX: "^$|^\\S+@\\S+\\.\\S+$",
        //         message:
        //             "Le format doit être comme ceci : contact@esperoo.fr",
        //     },
        // ])
        // .add(state.email, [
        //     {
        //         EXIST: state.email.error === existEmailMessage,
        //         message: existEmailMessage,
        //     },
        // ])
        // .add(state.phone, [
        //     {
        //         EXIST: state.phone.error === existPhoneMessage,
        //         message: existPhoneMessage,
        //     },
        // ])
        // .add(state.belongsTo, [
        //     {
        //         AT_LEAST_ONE: !state.belongsTo.value?.length,
        //         message:
        //             "Veuillez choisir un établissement d'affiliation !",
        //     },
        // ]);
    };
    const { allActiveEmployees, allEmpsInApp } = useEmployees();
    const checkError = async (fields: string[]) => {
        let error = false;
        if (fields.length === 0) {
            if (
                employee.role.value === "Manager" &&
                (!employee.belongsTo.value.length ||
                    (employee.belongsTo.value.length > 0 &&
                        employee.manage.value.length === 0))
            ) {
                error = true;
            }
        } else {
            let stateToValidate: any = {};
            fields.map((field: any) => {
                stateToValidate[field] = { ...employee[field], touched: true };
            });
            await validate(stateToValidate);
            let newState = { ...employee };

            fields.map((field) => {
                if (newState[field].hasError) {
                    console.log(
                        " the causeeee  = , newState[field] ",
                        newState[field],
                    );
                    error = true;
                    newState[field].touched = true;
                }
            });
            // setEmployee(newState);
        }
        return error;
    };

    const onSubmit = async () => {
        // onBlur("belongsTo");
        const values = destructFields(employee);
        await employeesActions
            .addEmployee(values as CreateEployeeRequest)
            .then((res) => {
                const data: any = res.payload;
                if (data.status === "success") {
                    if (data.data?.isPackageError) {
                        addNotification({
                            title: intl.formatMessage({
                                id: "EMPLOYEE.CREATE.NOTIFICATION.SUCCESS.TITLE",
                            }),
                            message:
                                data.data?.errorMessage ??
                                intl.formatMessage({
                                    id: "EMPLOYEE.CREATE.NOTIFICATION.SUCCESS.MESSAGE",
                                }),
                            type: "success",
                        });
                    } else {
                        addNotification({
                            title: intl.formatMessage({
                                id: "EMPLOYEE.CREATE.NOTIFICATION.SUCCESS.TITLE",
                            }),
                            message: intl.formatMessage({
                                id: "EMPLOYEE.CREATE.NOTIFICATION.SUCCESS.MESSAGE",
                            }),
                            type: "success",
                        });
                    }
                } else if (data.status === "fail") {
                    if (data?.data?.isPackageLimited) {
                        addNotification({
                            title: intl.formatMessage({
                                id: "EMPLOYEE.CREATE.NOTIFICATION.FAIL.TITLE",
                            }),
                            message: intl.formatMessage({
                                id: "EMPLOYEE.CREATE.NOTIFICATION.FAIL.MESSAGE_UPGRADE",
                            }),
                            type: "fail",
                        });
                    } else {
                        addNotification({
                            title: intl.formatMessage({
                                id: "EMPLOYEE.CREATE.NOTIFICATION.FAIL.TITLE",
                            }),
                            message: intl.formatMessage({
                                id: "EMPLOYEE.CREATE.NOTIFICATION.FAIL.MESSAGE",
                            }),
                            type: "fail",
                        });
                    }
                }
            });
        let newEmployee = generateFields(fields);
        setEmployee(newEmployee);
    };

    const { selectedBranchSettings, branchSettings, getBranchSettings } =
        useBranch();
    const { fetchDepartements, departements, getDepartmentsForNewEmp } =
        usePointeuse();
    const [siteDepartements, setSiteDepartements] = React.useState([]);
    React.useEffect(() => {
        // console.log("should run from EmployeesCreate");
        const fetchData = async () => {
            if (selectedBranchSettings && selectedBranchSettings._id) {
                await getBranchSettings();
                await fetchDepartements({ siteId: selectedBranchSettings._id });
                let res: any = await getDepartmentsForNewEmp(
                    sitesActions.sitesManagement.map((site: any) => site._id),
                );
                setSiteDepartements((prev: any) => res);
            }
        };
        fetchData();
    }, [selectedBranchSettings._id, sitesActions.sitesManagement]);

    return (
        <div>
            <EmployesCreate
                sites={sitesActions.sitesManagement}
                show={show}
                onHide={onHide}
                employee={employee}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                validate={validate}
                checkError={checkError}
                confirmLoading={employeesActions.actionLoading}
                departements={siteDepartements}
                multiplePlanning={branchSettings.multiplePlanning}
            />
        </div>
    );
}

interface EmployesCreateInterface {
    show: boolean;
    onHide: () => void;
}

interface Employee {
    [key: string]: FieldInterface;
}
