import * as React from "react";

import "./style.scss";
import SearchSVG from "./search.svg";
import PopUpCard from "../../molecules/PopUpCard";
import Input from "../../atoms/Input";
import TextData from "../../atoms/TextData";
import Checkbox from "../../atoms/Checkbox";

import img1 from "./pictures/1.png";
import img2 from "./pictures/2.png";
import img3 from "./pictures/3.png";
import img4 from "./pictures/4.png";
import img6 from "./pictures/6.png";
import Picture from "../../atoms/Picture";
import defaultIMG from "./default.png";
import { ROUTER } from "../../../constants/env";

export default function NewDepartmentCard(props: NewDepartmentCardProps) {
    const {
        headerCardTitle,
        display,
        onClose,
        employeeList,
        isAllEmployeesSelected,
        data,
        isEdit,
        onDeleteDepartment,
    } = props;
    //input field
    const {
        inputValue,
        inputLabel,
        inputLabelColor,
        inputLabelSize,
        inputBorderColor,
        inputTextSize,
        inputTextColor,
        inputRadius,
        inputPlaceholder,
        inputWidth,
        inputOnChange,
    } = props;
    const {
        employeeSectionHeader,
        employeeSearchIcon,
        employeeSearchPlaceholder,
        employeeSearchInputWidth,
        employeeSearchInputBackground,
        employeeSearchInputTextColor,
        onEmployeeSearchInputChange,
        employeeSearchSelectAllText,
        employeeSearchSelectAllTextSize,
        employeeSearchSelectAllTextWeight,
        onSelectAllEmployees,
    } = props;
    const {
        employeeItemImageWidth,
        employeeItemImageHeight,
        employeeTextSize,
        employeeTextWeight,
        employeeTextColor,
        employeeTickIsRounded,
        onEmployeeItemClick,
    } = props;
    const { onCancelButton, onSubmitButton } = props;
    const [searchTerm, setSearchTerm] = React.useState("");
    const [filteredEmployees, setFilteredEmployees] =
        React.useState(employeeList);

    React.useEffect(() => {
        const filtered = employeeList?.filter((employee) =>
            employee?.name
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase() ?? ""),
        );
        filtered?.sort((a: any, b: any) => {
            // First, sort by isSelected
            if (b.isSelected - a.isSelected !== 0) {
                return b.isSelected - a.isSelected;
            }
            // Then, sort by name
            return a.name.localeCompare(b.name);
        });
        setFilteredEmployees(filtered);
    }, [employeeList, searchTerm]);

    const handleSearch = (data: any) => {
        setSearchTerm(data);
        onEmployeeSearchInputChange?.(data);
    };
    const [selectAll, setselectAll] = React.useState(isAllEmployeesSelected);
    const handleCheckAll = (e: any) => {
        setselectAll(!selectAll);
        onSelectAllEmployees?.(e.target);
    };
    const handleSelectEmployee = (e: any) => {
        onEmployeeItemClick?.(e.target);
    };
    const handleEmployeeCheckedStatus = (
        actualEmpSelection: boolean | undefined,
    ) => {
        if (selectAll == undefined) {
            return actualEmpSelection;
        } else {
            return selectAll;
        }
    };

    const handleClose = () => {
        onClose && onClose();
    };
    const [editedData, setData] = React.useState(
        data ?? {
            _id: "",
            employees: [],
            name: "",
            site: "",
        },
    );
    React.useEffect(() => {
        if (display) {
            setData(data);
        }
    }, [data, display]);
    const onSubmit = () => {};
    const onDelete = async () => {
        let ok = await onDeleteDepartment?.(editedData?._id || "");
        if (ok) handleClose();
    };
    return (
        <PopUpCard
            display={display}
            onClose={handleClose}
            header={headerCardTitle}
            // onClickButton1={onCancelButton}
            // onClickButton2={onSubmitButton}
            // button1Text="Annuler"
            // button2Text="Ajouter"
            containerWidth="600px"
            isCloseIcon={true}
            onClickButton1={isEdit ? onDelete : undefined}
            button1Text={isEdit ? "Supprimer" : ""}
            button1Style={{
                backgroundColor: isEdit ? "red" : "",
                color: "white",
            }}
            onClickButton2={onSubmitButton}
            button2Text={isEdit ? "Modifier" : "Ajouter"}
        >
            <div className="new-dept-card-container">
                <Input
                    captionText={inputLabel}
                    captionColor={inputLabelColor}
                    captionSize={inputLabelSize}
                    borderColor={inputBorderColor}
                    borderWidth={1}
                    style={{
                        inputStyle: {
                            padding: "7px 15px 7px 15px",
                            outline: "none",
                            marginBottom: 15,
                        },
                    }}
                    textSize={inputTextSize}
                    textColor={inputTextColor}
                    inputRadius={inputRadius}
                    placeholder={inputPlaceholder}
                    inputWidth={inputWidth}
                    value={inputValue}
                    onChange={inputOnChange}
                    maxTextLength={30}
                />
                <div
                    className="employees-section"
                    style={{
                        maxHeight: "400px",
                    }}
                >
                    <TextData
                        color="#6F78AB"
                        size="16px"
                        fontWeight="bolder"
                        style={{
                            marginLeft: "0",
                        }}
                    >
                        {employeeSectionHeader}
                    </TextData>
                    <div className="new-dept-search-bar">
                        <Input
                            captionText={null}
                            borderWidth={0}
                            iconImg={employeeSearchIcon}
                            iconLocation="left"
                            placeholder={employeeSearchPlaceholder}
                            inputWidth={employeeSearchInputWidth}
                            style={{
                                inputStyle: {
                                    backgroundColor:
                                        employeeSearchInputBackground,
                                    outline: "none",
                                    width: "100%",
                                    // padding: "7px 3px 7px 25px",
                                    color: employeeSearchInputTextColor,
                                },
                            }}
                            onChange={handleSearch}
                        />
                        <div className="flex items-center gap-1">
                            <TextData
                                width="auto"
                                size={employeeSearchSelectAllTextSize}
                                variant="regular"
                                fontWeight={employeeSearchSelectAllTextWeight}
                            >
                                {employeeSearchSelectAllText}
                            </TextData>
                            <Checkbox
                                onClick={handleCheckAll}
                                id="all"
                                text={null}
                                rounded={true}
                                checked={selectAll}
                            />
                        </div>
                    </div>
                    <div
                        className="new-dept-list-of-employees-dapartments"
                        style={{
                            height: "100%",
                            overflow: "auto",
                        }}
                    >
                        {filteredEmployees &&
                            filteredEmployees.map((elem) => {
                                return (
                                    <div key={elem.id} className="employee">
                                        <div className="information">
                                            {elem.picture ===
                                            ROUTER.STATIC_IMG("") ? (
                                                <Picture
                                                    imgPath={defaultIMG}
                                                    className={["rounded-full"]}
                                                    width={
                                                        employeeItemImageWidth
                                                    }
                                                    height={
                                                        employeeItemImageHeight
                                                    }
                                                />
                                            ) : (
                                                <Picture
                                                    imgPath={elem.picture}
                                                    className={["rounded-full"]}
                                                    width={
                                                        employeeItemImageWidth
                                                    }
                                                    height={
                                                        employeeItemImageHeight
                                                    }
                                                />
                                            )}
                                            <TextData
                                                variant="label"
                                                width="auto"
                                                size={employeeTextSize}
                                                fontWeight={employeeTextWeight}
                                                color={employeeTextColor}
                                            >
                                                {elem.name}
                                            </TextData>
                                        </div>
                                        <div className="employee-selection">
                                            <Checkbox
                                                id={elem.id}
                                                text={null}
                                                rounded={employeeTickIsRounded}
                                                checked={handleEmployeeCheckedStatus(
                                                    elem.isSelected,
                                                )}
                                                onClick={handleSelectEmployee}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </PopUpCard>
    );
}
interface Employee {
    id: string;
    name: string;
    picture: string;
    isSelected?: boolean;
}
interface NewDepartmentCardProps {
    headerCardTitle?: string;
    display?: boolean;
    onClose?: () => void;
    header?: string;
    headerStyle?: React.CSSProperties;
    activateFooter?: boolean;
    button1Text?: string;
    button2Text?: string;
    button1Style?: React.CSSProperties;
    button2Style?: React.CSSProperties;
    onClickButton1?: () => void;
    onClickButton2?: () => void;
    employeeList?: Employee[];
    isAllEmployeesSelected?: boolean;
    onEmployeeItemClick?: (target: any) => void;
    //input field
    data?: any;
    isEdit?: boolean;
    inputValue?: string;
    inputLabel?: string;
    inputLabelColor?: string;
    inputLabelSize?: string;
    inputBorderColor?: string;
    inputTextSize?: number;
    inputTextColor?: string;
    inputRadius?: string;
    inputPlaceholder?: string;
    inputWidth?: string;
    inputOnChange?: (data: any) => void;
    //employees section
    employeeSectionHeader?: string;
    employeeSearchIcon?: string;
    employeeSearchPlaceholder?: string;
    employeeSearchInputWidth?: string;
    employeeSearchInputBackground?: string;
    employeeSearchInputTextColor?: string;
    onEmployeeSearchInputChange?: (data: any) => void;
    employeeSearchSelectAllText?: string;
    employeeSearchSelectAllTextSize?: string;
    employeeSearchSelectAllTextWeight?: string;
    onSelectAllEmployees?: (e: any) => void;
    //employee item props
    employeeItemImageWidth?: string;
    employeeItemImageHeight?: string;
    employeeTextSize?: string;
    employeeTextWeight?: string;
    employeeTextColor?: string;
    employeeTickIsRounded?: boolean;
    // submit
    onCancelButton?: () => void;
    onSubmitButton?: () => void;
    onDeleteDepartment?: (id: string) => void;
}
NewDepartmentCard.defaultProps = {
    headerCardTitle: "Ajouter un département",
    display: true,
    // isAllEmployeesSelected: false,
    employeeList: [
        {
            name: "Kristin Watson",
            id: "1",
            picture: img1,
            isSelected: false,
        },
        {
            name: "Theresa Webb",
            id: "2",
            picture: img2,
            isSelected: false,
        },
        {
            name: "Cameron Williamson",
            id: "3",
            picture: img3,
            isSelected: false,
        },
        {
            name: "Jerome Bell",
            id: "4",
            picture: img4,
            isSelected: false,
        },
        {
            name: "Annette Black",
            id: "5",
            picture: img6,
            isSelected: false,
        },
    ],

    //input field
    inputValue: "",
    inputLabel: "Nom du département",
    inputLabelColor: "#8E96C1",
    inputLabelSize: "12px",
    inputBorderColor: "#C7CBE2",
    inputTextSize: 14,
    inputTextColor: "#2A346D",
    inputRadius: "4px",
    inputPlaceholder: "",
    inputWidth: "100%",
    inputOnChange: (data: any) => {},
    //employees section
    employeeSectionHeader: "Sélectionner un collaborateur",
    employeeSearchIcon: SearchSVG,
    employeeSearchPlaceholder: "Rechercher",
    employeeSearchInputWidth: "70%",
    employeeSearchInputBackground: "transparent",
    employeeSearchInputTextColor: "#8E96C1",
    onEmployeeSearchInputChange: () => {},
    employeeSearchSelectAllText: "Tous",
    employeeSearchSelectAllTextSize: "12",
    employeeSearchSelectAllTextWeight: "600",
    onSelectAllEmployees: (e: any) => {},
    //employee item props
    employeeItemImageWidth: "40px",
    employeeItemImageHeight: "40px",
    employeeTextSize: "14px",
    employeeTextWeight: "bolder",
    employeeTextColor: "#101844",
    employeeTickIsRounded: true,
    onEmployeeItemClick: (data: any) => {},
    // submit
    onCancelButton: () => {},
    onSubmitButton: () => {},
};
