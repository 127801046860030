import * as React from "react";

import "./style.scss";
import TextData from "../../../atoms/TextData";
import Button from "../../../atoms/Button";
import Table from "../../Table";
import { useLeaveBalance } from "../../../../hooks/useLeaveBalance";
import { useEmployees } from "../../../../hooks/useEmployees";
import EmployeeInfoHeaderRow from "../../../molecules/EmployeeInfoHeaderRow";
import { employeeRoleColor } from "../../../../_helpers/Functions";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import { ReactComponent as Warn } from "./warn.svg";
import Input from "../../../atoms/Input";
import PopUpCard from "../../../molecules/PopUpCard";
import Modal from "../../../atoms/Modal";
import Loading from "../../../atoms/Loading";
import { EmployeeInfoHeader } from "../../WorkHoursOverviewCard";
import moment from "moment";
import { useBranch } from "../../../../hooks/useBranch";
import NoAccess from "../../../../pages/Settings/Elements/NoAccess";
import CommingSoon from "../../../../pages/Settings/Elements/CommingSoon";
import { useVacation } from "../../../../hooks/useVacation";
import SelectInput from "../../../atoms/SelectInput";
import Swal from "sweetalert2";
import { API, ROUTER } from "../../../../constants/env";
import { useLogin } from "../../../../hooks/useLogin";

function VacationBarioloTableRightMenu() {
    return <div></div>;
}
function VacationBarioloTableHeader() {
    const { computeAutoVacationBalanceScore } = useLeaveBalance();
    const handleMonthlyBalanceCalculation = () => {
        computeAutoVacationBalanceScore();
    };
    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                justifyContent: "space-between",
                height: "80px",
                margin: "0 1.25rem",
            }}
        >
            <TextData color="#11106E" size="34" fontWeight="bold">
                Rapports
            </TextData>
            {/* <Button
                textColor='white'
                backgroundColor='#2A8BAB'
                style={{
                    fontSize: "16px",
                    gap: "0.25rem",
                    marginTop: "1rem",
                    alignSelf: "flex-end",
                    marginBottom: "0.5rem",
                }}
                width='auto'
                padding='0.75rem 20px'
                height='auto'
                onClick={handleMonthlyBalanceCalculation}
                disabled={true}
            >
                Mise à Jour des Soldes Mensuels
            </Button> */}
        </div>
    );
}
function VacationBarioloTable() {
    const { allHistoryBalances, getAllLeaveBalance, forceManualLeaveBalance } =
        useLeaveBalance();
    const { employees, getEmployees } = useEmployees();
    const { selectedBranchSettings } = useBranch();
    const { auth } = useLogin();
    const { vacationSetting, getVacationSettings } = useVacation();
    const [customBariolo, setCustomBariolo] = React.useState<any>({});
    const fetchCustomBarioloConge = async () => {
        const response = await fetch(API.client.barioloVacation.setting, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        if (response.ok) {
            let data = await response.json();
            setCustomBariolo(data);
            console.log("setCustomBariolo : ", data);
        }
    };

    const [emp, setEmp] = React.useState(employees);
    const fetchEmployeesScores = async () => {
        const response = await fetch(
            API.client.barioloVacation.empScore(selectedBranchSettings?._id),
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            },
        );
        if (response.ok) {
            let data = await response.json();
            setEmp(data);
            console.log("output : ", data);
        }
    };
    React.useEffect(() => {
        fetchCustomBarioloConge();
        getEmployees({ archived: false });
        getAllLeaveBalance();
        getVacationSettings();
        fetchEmployeesScores();
    }, [selectedBranchSettings]);

    React.useEffect(() => {
        // setEmp(
        //     employees.map((employee) => {
        //         return {
        //             ...employee,
        //             leaveBalances: allHistoryBalances.find(
        //                 (lb: any) => lb._id === employee._id,
        //             ),
        //         };
        //     }),
        // );
    }, [employees, allHistoryBalances]);
    const [showHistoryPopup, setShowHistoryPopup] = React.useState(false);
    const [selectedHistory, setSelectedHistory] = React.useState<any>(null);
    // React.useEffect(() => {
    //     if (selectedHistory?._id) {
    //         let existedSelectedHistory = employees.find(
    //             (employee) => employee?._id === selectedHistory?._id,
    //         );
    //         if (existedSelectedHistory) {
    //             existedSelectedHistory = {
    //                 ...existedSelectedHistory,
    //                 leaveBalances: allHistoryBalances.find(
    //                     (lb: any) => lb._id === existedSelectedHistory._id,
    //                 ),
    //             };
    //             console.log("existedSelectedHistory", existedSelectedHistory);
    //             setSelectedHistory(existedSelectedHistory);
    //         }
    //     }
    // }, [employees, allHistoryBalances]);

    const loadVacationData = () => {
        let res: any = [
            {
                dataRow: [
                    {
                        name: "date",
                        value: "employeeX",
                        style: {
                            padding: "10px 0",
                            paddingLeft: "15px",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: `${
                                1 % 2 === 0 ? "#f5f6f9" : "white"
                            }`,
                            color: "#101844",
                        },
                    },
                    {
                        name: "date",
                        value: "18.3 jours",
                        style: {
                            padding: "10px 0",
                            paddingLeft: "15px",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: `${
                                1 % 2 === 0 ? "#f5f6f9" : "white"
                            }`,
                            color: "#101844",
                        },
                    },
                    {
                        name: "date",
                        value: "14 Dec 2023 par foulenben felten",
                        style: {
                            padding: "10px 0",
                            paddingLeft: "15px",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: `${
                                1 % 2 === 0 ? "#f5f6f9" : "white"
                            }`,
                            color: "#101844",
                        },
                    },
                    {
                        name: "date",
                        value: <input type="text" value="18.3" />,
                        style: {
                            padding: "10px 0",
                            paddingLeft: "15px",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: `${
                                1 % 2 === 0 ? "#f5f6f9" : "white"
                            }`,
                            color: "#101844",
                        },
                    },
                    {
                        name: "date",
                        value: <Button />,
                        style: {
                            padding: "10px 0",
                            paddingLeft: "15px",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: `${
                                1 % 2 === 0 ? "#f5f6f9" : "white"
                            }`,
                            color: "#101844",
                        },
                    },
                ],
            },
        ];
        res = emp.map((user) => {
            const employeeCell = {
                value: (
                    <EmployeeInfoHeaderRow
                        firstName={user?.fname}
                        lastName={user?.lname}
                        position={user?.role}
                        dotColor={employeeRoleColor(user?.role)}
                        weekHours={
                            (user?.contract?.tempsDeTravailHebdomadaire
                                ? user?.contract?.tempsDeTravailHebdomadaire
                                : "0") + " h"
                        }
                        profileImage={user?.picture}
                    />
                ),
                name: `${user?.fname} ${user?.lname}`,
                style: {
                    borderRight: "1px solid #EDEFF5",
                },
                onClickCell: () => {},
            };
            let totalAccruedLeave = 0;
            let totalLeaveTaken = 0;
            let totalRemainingLeave = 0;
            // let history: any = null;
            // if (user.leaveBalances?.leaveBalances.length > 0) {
            //     history = user.leaveBalances.leaveBalances;
            //     if (history && history.length > 0) {
            //         for (const value of history) {
            //             if (value.leaveCount > 0) {
            //                 totalAccruedLeave += value.leaveCount;
            //             } else {
            //                 totalLeaveTaken += value.leaveCount;
            //             }
            //         }
            //         totalRemainingLeave = totalAccruedLeave + totalLeaveTaken;
            //     }
            // }
            const leaveBalanceCalculation = () => {
                if (user.ruleID !== null) {
                    return [
                        {
                            value: (
                                <div>
                                    <TextData
                                        variant="div"
                                        color="#101844"
                                        size="14px"
                                        fontWeight="bold"
                                        width="auto"
                                        style={{ textAlign: "center" }}
                                    >
                                        {user?.soldeAcquis > 0
                                            ? user?.soldeAcquis
                                            : 0}
                                    </TextData>
                                </div>
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        },
                        {
                            value: (
                                <TextData
                                    variant="div"
                                    color="#101844"
                                    size="14px"
                                    fontWeight="bold"
                                    width="auto"
                                    style={{ textAlign: "center" }}
                                >
                                    {user?.soldePris > 0 ? user?.soldePris : 0}
                                </TextData>
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        },
                        {
                            value: (
                                <TextData
                                    variant="div"
                                    color="#101844"
                                    size="14px"
                                    fontWeight="bold"
                                    width="auto"
                                    style={{ textAlign: "center" }}
                                >
                                    {user?.soldeRestant > 0
                                        ? user?.soldeRestant
                                        : 0}
                                </TextData>
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        },
                    ];
                } else {
                    return [
                        {
                            value: (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "5px",
                                    }}
                                >
                                    <Warn
                                        color="#e4514d"
                                        width="30px"
                                        height="auto"
                                    />
                                    <TextData
                                        variant="label"
                                        color="#101844"
                                        size="12px"
                                        fontWeight="bold"
                                        width="auto"
                                    >
                                        Assignez une politique de congés à ce
                                        salarié.
                                    </TextData>
                                </div>
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        },
                        {
                            value: (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "5px",
                                    }}
                                >
                                    <Warn
                                        color="#e4514d"
                                        width="30px"
                                        height="auto"
                                    />
                                    <TextData
                                        variant="label"
                                        color="#101844"
                                        size="12px"
                                        fontWeight="bold"
                                        width="auto"
                                    >
                                        Assignez une politique de congés à ce
                                        salarié.
                                    </TextData>
                                </div>
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        },
                        {
                            value: (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "5px",
                                    }}
                                >
                                    <Warn
                                        color="#e4514d"
                                        width="30px"
                                        height="auto"
                                    />
                                    <TextData
                                        variant="label"
                                        color="#101844"
                                        size="12px"
                                        fontWeight="bold"
                                        width="auto"
                                    >
                                        Assignez une politique de congés à ce
                                        salarié.
                                    </TextData>
                                </div>
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        },
                    ];
                }
            };
            const manualUpdate = () => {
                const forceUpdate = async (data: any) => {
                    console.log("Data:: ", data);
                    // const diff = (data - totalAccruedLeave).toFixed(2);
                    // await forceManualLeaveBalance(user._id, diff);
                };
                if (user.ruleID !== null) {
                    return {
                        value: (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Input
                                    type="number"
                                    value={totalAccruedLeave}
                                    captionText={null}
                                    inputWidth="100px"
                                    borderColor=""
                                    borderWidth={1}
                                    style={{
                                        inputCoreStyle: {
                                            textAlign: "center" as "center",
                                        },
                                    }}
                                    onBlur={forceUpdate}
                                />
                            </div>
                        ),
                        name: "phone",
                        style: {
                            borderRight: "1px solid #EDEFF5",
                        },
                    };
                } else {
                    return {
                        value: (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "5px",
                                }}
                            >
                                <Warn
                                    color="#e4514d"
                                    width="30px"
                                    height="auto"
                                />
                                <TextData
                                    variant="label"
                                    color="#101844"
                                    size="12px"
                                    fontWeight="bold"
                                    width="auto"
                                >
                                    Assignez une politique de congés à ce
                                    salarié.
                                </TextData>
                            </div>
                        ),
                        name: "phone",
                        style: {
                            borderRight: "1px solid #EDEFF5",
                        },
                    };
                }
            };

            const historyButton = () => {
                const disabled = user.ruleID === null;
                const handleHistory = (user: any) => {
                    // console.log("user", user);
                    setSelectedHistory(user);
                    setShowHistoryPopup(true);
                };
                return {
                    value: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                disabled={disabled}
                                textColor="white"
                                backgroundColor="#2A8BAB"
                                style={{
                                    fontSize: "16px",
                                    gap: "0.25rem",
                                    alignSelf: "flex-end",
                                }}
                                width="auto"
                                padding="0.35rem 20px"
                                height="auto"
                                onClick={() => handleHistory(user)}
                            >
                                Modifier
                            </Button>
                        </div>
                    ),
                    name: "phone",
                    style: {
                        borderRight: "1px solid #EDEFF5",
                    },
                };
            };
            const dataRows = [
                employeeCell,
                ...leaveBalanceCalculation(),
                // manualUpdate(),
                historyButton(),
            ];
            return {
                dataRow: dataRows,
                onClickRow: () => {},
                style: {
                    cursor: "pointer",
                    border: "1px solid #EDEFF5",
                },
                className: ["hover:bg-slate-100"],
            };
        });
        return res;
    };
    const positionColor = (role: any) => {
        switch (role) {
            case "Administrator":
                return "#62A62E";
            case "Manager":
                return "#7061F1";
            case "Employee":
                return "#62A62E";
            default:
                return "#62A62E";
        }
    };
    const NUMBER_OF_COLUMNS = 5;
    const VacationTableProps = () => {
        return {
            fields: {
                fieldRow: [
                    {
                        title: (
                            <TextData
                                variant="label"
                                color="#2A346D"
                                size="16px"
                                fontWeight="bold"
                                width="auto"
                            >
                                Employés
                            </TextData>
                        ),
                        name: "name",
                        style: {
                            paddingLeft: "15px",
                            borderTop: "none",
                            width: `${100 / NUMBER_OF_COLUMNS}%`,
                            // backgroundColor: "#CFE0E6",
                            backgroundColor: "#A6D3E2",
                        },
                        titleContainerStyle: {
                            // height: "70px",
                            display: "flex",
                            alignItems: "center",
                        },
                    },
                    {
                        title: (
                            <TextData
                                variant="label"
                                color="#2A346D"
                                size="16px"
                                fontWeight="bold"
                                width="auto"
                            >
                                Solde de congés acquis
                            </TextData>
                        ),
                        name: "name",
                        style: {
                            padding: "10px 0",
                            width: `${100 / NUMBER_OF_COLUMNS}%`,
                            textAlign: "center" as "center",
                            borderRight: "1px solid #edeff5",
                            borderLeft: "1px solid #edeff5",
                            borderBottom: "1px solid #edeff5",
                            backgroundColor: "#A6D3E2",
                        },
                    },
                    {
                        title: (
                            <TextData
                                variant="label"
                                color="#2A346D"
                                size="16px"
                                fontWeight="bold"
                                width="auto"
                            >
                                Solde de congés pris
                            </TextData>
                        ),
                        name: "name",
                        style: {
                            padding: "10px 0",
                            width: `${100 / NUMBER_OF_COLUMNS}%`,
                            textAlign: "center" as "center",
                            borderRight: "1px solid #edeff5",
                            borderLeft: "1px solid #edeff5",
                            borderBottom: "1px solid #edeff5",
                            backgroundColor: "#A6D3E2",
                        },
                    },
                    {
                        title: (
                            <TextData
                                variant="label"
                                color="#2A346D"
                                size="16px"
                                fontWeight="bold"
                                width="auto"
                            >
                                Solde de congés restants
                            </TextData>
                        ),
                        name: "name",
                        style: {
                            padding: "10px 0",
                            width: `${100 / NUMBER_OF_COLUMNS}%`,
                            textAlign: "center" as "center",
                            borderRight: "1px solid #edeff5",
                            borderLeft: "1px solid #edeff5",
                            borderBottom: "1px solid #edeff5",
                            backgroundColor: "#A6D3E2",
                        },
                    },
                    // {
                    //     title: (
                    //         <TextData
                    //             variant="label"
                    //             color="#2A346D"
                    //             size="16px"
                    //             fontWeight="bold"
                    //             width="auto"
                    //         >
                    //             Mise à jour manuelle de solde de congés
                    //         </TextData>
                    //     ),
                    //     name: "name",
                    //     style: {
                    //         padding: "10px 0",
                    //         width: `${100 / NUMBER_OF_COLUMNS}%`,
                    //         textAlign: "center" as "center",
                    //         backgroundColor: "#A6D3E2",
                    //         borderRight: "1px solid #edeff5",
                    //         borderLeft: "1px solid #edeff5",
                    //     },
                    // },
                    {
                        title: (
                            <TextData
                                variant="label"
                                color="#2A346D"
                                size="16px"
                                fontWeight="bold"
                                width="auto"
                            >
                                Mettre à jour
                            </TextData>
                        ),
                        name: "name",
                        style: {
                            padding: "10px 0",
                            width: `${100 / NUMBER_OF_COLUMNS}%`,
                            textAlign: "center" as "center",
                            backgroundColor: "#A6D3E2",
                            borderRight: "1px solid #edeff5",
                            borderLeft: "1px solid #edeff5",
                        },
                    },
                ],
                onClickRow: () => {},
                style: {
                    border: "1px solid #EDEFF5",
                    borderTop: "none",
                    position: "sticky" as "sticky",
                    top: 0,
                    backgroundColor: "white",
                },
                className: [],
            },

            data: loadVacationData(),
        };
    };

    const handlePopupHistoryClose = () => {
        setShowHistoryPopup(false);
        setSelectedHistory(null);
    };
    const handleSubmitPopupHistory = () => {
        setShowHistoryPopup(false);
        setSelectedHistory(null);
    };
    const PopupHistoryModal = () => {
        let userInfo: any;
        let displayData = [];
        displayData.push(
            <tr className="historyHeader">
                <td>
                    <TextData>Date d'émission</TextData>
                </td>
                <td>
                    <TextData>Nombre de congés</TextData>
                </td>
                <td>
                    <TextData>Type</TextData>
                </td>
                <td>
                    <TextData>Approuvé par</TextData>
                </td>
            </tr>,
        );
        if (selectedHistory !== null) {
            console.log("selectedHistory ::", selectedHistory);
        }
        const [activateAdjustPopup, setActivateAdjustPopup] =
            React.useState(true);
        const handleCloseAdjust = () => {
            setActivateAdjustPopup(false);
        };
        const handleOpenAdjust = () => {
            setActivateAdjustPopup(true);
        };
        const AdjustComponent = () => {
            const [operationType, setoperationType] = React.useState(1);
            const [operationValue, setoperationValue] = React.useState(0);
            const handleOperationType = (data: any) => {
                setoperationType(data?.value);
            };
            const handleOperationValue = (data: any) => {
                setoperationValue(data);
            };
            const handleSubmitAdjust = async () => {
                const manualAmount = operationType * operationValue;
                if (manualAmount !== 0) {
                    await forceManualLeaveBalance(userInfo._id, manualAmount);
                    Swal.fire({
                        timer: 1000,
                        icon: "success",
                    });
                    await getAllLeaveBalance();
                    await handleCloseAdjust();
                }
            };
            return (
                <Modal
                    modalEnabled={activateAdjustPopup}
                    onClose={handleCloseAdjust}
                >
                    <PopUpCard
                        display={activateAdjustPopup}
                        onClose={handleCloseAdjust}
                        header="Mise à jour manuelle"
                        widthCloseIcon="24px"
                        containerWidth="35vw"
                        styleCloseIcon={{
                            height: "auto",
                            marginRight: "37px",
                            width: "24px",
                            border: "1px solid #edeff533",
                            borderTopRightRadius: "20px",
                        }}
                        headerStyle={{
                            fontSize: "22px",
                            fontWeight: "700",
                            color: "#2A346D",
                        }}
                        popupHeaderStyle={{
                            height: "80px",
                            backgroundColor: "#edeff54c",
                            borderBottom: "1px solid #C7CBE2",
                        }}
                        activateFooter={false}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "7px 7px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "7px",
                                }}
                            >
                                <TextData>Appliquer</TextData>
                                <SelectInput
                                    title=""
                                    style={{ height: "30px" }}
                                    selectedValue={{
                                        label: "Addition",
                                        value: 1,
                                    }}
                                    options={[
                                        { label: "Addition", value: 1 },
                                        { label: "Soustraire", value: -1 },
                                    ]}
                                    styleOption={{
                                        height: 30,
                                    }}
                                    onSelectOption={handleOperationType}
                                />
                                <TextData>de </TextData>
                                <Input
                                    type="number"
                                    inputWidth="70px"
                                    placeholder=""
                                    borderColor="grey"
                                    borderWidth={1}
                                    defaultValue={operationValue}
                                    onChange={handleOperationValue}
                                />
                            </div>
                            <Button
                                backgroundColor="#2a8bab"
                                onClick={handleSubmitAdjust}
                            >
                                Enregistrer
                            </Button>
                        </div>
                    </PopUpCard>
                </Modal>
            );
        };
        const [updatedSolder, setupdatedSolder] = React.useState({
            soldeAcquis: selectedHistory?.soldeAcquis || 0,
            soldePris: selectedHistory?.soldePris || 0,
        });

        const setChangeSolde = (data: any) => {
            const { soldeAcquis, soldePris } = data;
            if (soldeAcquis !== undefined) {
                setupdatedSolder({
                    ...updatedSolder,
                    soldeAcquis,
                });
            }
            if (soldePris !== undefined) {
                setupdatedSolder({
                    ...updatedSolder,
                    soldePris,
                });
            }
        };
        const updateSoldeUser = async () => {
            if (selectedHistory && selectedHistory._id) {
                const response = await fetch(
                    API.client.barioloVacation.updateSolde(selectedHistory._id),
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            soldeAcquis: parseFloat(updatedSolder?.soldeAcquis),
                            soldePris: parseFloat(updatedSolder?.soldePris),
                        }),
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    fetchEmployeesScores();
                    handlePopupHistoryClose();
                    Swal.fire({
                        icon: "success",
                        title: "Succès !",
                        text: "Votre opération a été réalisée avec succès.",
                        timer: 1000,
                    });
                }
            } else {
                //error swall
                Swal.fire({
                    icon: "error",
                    title: "Erreur !",
                    text: "Une erreur est survenue. Veuillez réessayer.",
                });
            }
        };

        return (
            <Modal
                modalEnabled={showHistoryPopup}
                onClose={handlePopupHistoryClose}
            >
                <PopUpCard
                    display={showHistoryPopup}
                    onClose={handlePopupHistoryClose}
                    header={`Modifier le solde ${selectedHistory ? ` de ${selectedHistory?.fname} ${selectedHistory?.lname}` : " des congés"}`}
                    widthCloseIcon="24px"
                    containerWidth="50vw"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    activateFooter={false}
                >
                    {selectedHistory ? (
                        <div style={{ alignItems: "right" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "17px",
                                    margin: " 10px 0",
                                }}
                            >
                                <Input
                                    captionText={"Solde de congés acquis"}
                                    placeholder={updatedSolder?.soldeAcquis}
                                    value={updatedSolder?.soldeAcquis}
                                    onChange={(data: any) => {
                                        setChangeSolde({
                                            soldeAcquis: data,
                                        });
                                    }}
                                />
                                <Input
                                    captionText={"Solde de congés pris"}
                                    placeholder={updatedSolder?.soldePris}
                                    value={updatedSolder?.soldePris}
                                    onChange={(data: any) => {
                                        setChangeSolde({
                                            soldePris: data,
                                        });
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    margin: "0 5%",
                                    marginTop: "20px",
                                    marginBottom: "7px",
                                }}
                            >
                                <Button
                                    backgroundColor="#2a8bab"
                                    textColor="white"
                                    width="auto"
                                    padding="7px 9px"
                                    onClick={updateSoldeUser}
                                >
                                    Mise à jour
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                height: "40vh",
                            }}
                        >
                            <Loading width="400px" height="200px" />
                        </div>
                    )}
                </PopUpCard>
            </Modal>
        );
    };
    return (
        <div
            style={{
                position: "relative",
            }}
        >
            {!(
                customBariolo &&
                customBariolo?.status &&
                selectedBranchSettings?._id !== null &&
                (auth?.user?.email == "sbelhadj@bariolojuices.com" ||
                    auth?.user?.email == "sbenkhaled@bariolojuices.com" ||
                    auth?.user?.email == "contact.esperoo@gmail.com")
            ) && <CommingSoon />}
            {!selectedBranchSettings._id && (
                <NoAccess
                    title="Congés"
                    description="Veuillez sélectionner un établissement pour accéder aux congés !"
                />
            )}
            {showHistoryPopup && <PopupHistoryModal />}
            <div
                style={{
                    position: "relative",
                    maxHeight: "66vh",
                    overflowX: "auto",
                }}
            >
                <Table {...VacationTableProps()} />
            </div>
        </div>
    );
}

export {
    VacationBarioloTableRightMenu,
    VacationBarioloTable,
    VacationBarioloTableHeader,
};
