import * as React from "react";
import classnames from "classnames";

import "./style.scss";
import Picture from "../../atoms/Picture";
import TextData from "../../atoms/TextData";

import whiteLogo from "../../../utils/img/whiteLogo.svg";
import { VERSION } from "../../../constants/env";
export default function AuthFooter(props: AuthFooterProps) {
    const { logo, height, backgroundColor, textColor, padding } = props;
    return (
        <div
            className="auth-footer"
            style={{
                height,
                backgroundColor,
                padding,
            }}
        >
            <Picture imgPath={logo} />
            <TextData color={textColor} width="auto">
                © Esperoo V{VERSION} Tous les droits sont réservés
            </TextData>
        </div>
    );
}

interface AuthFooterProps {
    logo?: string;
    textColor?: string;
    height?: string;
    backgroundColor?: string;
    version?: string;
    padding?: string;
}
AuthFooter.defaultProps = {
    logo: whiteLogo,
    textColor: "white",
    height: "5%",
    backgroundColor: "rgba(24, 29, 39, 0.3)",
};
