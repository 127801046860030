import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmployesState } from "./types";
import {
    fetchEmployees,
    createEmployee,
    fetchEmployee,
    fetchEmployeeInfos,
    fetchEmployeeDisponibilities,
    createUserUnavaibility,
    updateUserUnavaibility,
    fetchRegister,
    restoreEmp,
    archiveEmp,
    updateEmployee,
    updateEmployeeInfos,
    updateContract,
    removeUnavaibility,
    deleteEmployeeDocument,
    addEmployeeDocument,
    fetchEmployeeDocuments,
    fetchEmployeePrimes,
    createEmployeePrimes,
    updateEmployeePrimes,
    deleteEmployeePrimes,
    fetchPrimesTypes,
    fetchContracts,
    createContract,
    fetchEmployeeWorkHours,
    fetchEmployeeHolidays,
    updateEmployeePassword,
    deleteFolder,
    createEmployeeFolder,
    editEmployeeFolder,
    updateExistingDocument,
    downloadRegistre,
    uploadNewDocument,
} from "./service";
import moment from "moment";

const filter = (
    target: any[],
    filterBy: {
        role: any[];
        name: string;
        site?: string;
        niveau?: any[];
    },
) => {
    let newArray = [...target];
    if (filterBy.site) {
        newArray = newArray.filter((emp) => {
            if (!emp.belongsTo) {
                return false;
            }
            return emp.belongsTo.find(
                (temp: any) => temp._id === filterBy.site,
            );
        });
    }
    if (filterBy.niveau !== undefined && filterBy.niveau?.length > 0) {
        newArray = newArray.filter((emp) => {
            if (!emp.niveau) return false;
            return filterBy.niveau?.includes(emp.niveau);
        });
    }
    if (filterBy.name !== "") {
        // newArray = newArray.filter(
        //     (value) =>
        //         value.fname
        //             .toLowerCase()
        //             .indexOf(filterBy.name.toLowerCase()) !== -1 ||
        //         value.lname
        //             .toLowerCase()
        //             .indexOf(filterBy.name.toLowerCase()) !== -1
        // );
        const filterTerm = filterBy.name.trim().toLowerCase();
        newArray = newArray.filter((value) => {
            const trimmedFname = value.fname
                ? value.fname.trim().toLowerCase()
                : "";
            const trimmedLname = value.lname
                ? value.lname.trim().toLowerCase()
                : "";
            const fullName = `${trimmedFname} ${trimmedLname}`;

            return fullName.indexOf(filterTerm) !== -1;
        });
    }
    let roles = filterBy.role.map((option) => option.value);
    if (roles.length === 0) {
        return newArray;
    }
    return newArray.filter((value) => {
        return roles.includes(value.role);
    });
};

const filterRegister = (
    target: any[],
    filterBy: { actif: boolean; name: string; deleted: null | false },
) => {
    let newArray = [...target];
    if (filterBy.name !== "") {
        newArray = newArray.filter(
            (value) =>
                value.fname
                    .toLowerCase()
                    .indexOf(filterBy.name.toLowerCase()) !== -1 ||
                value.lname
                    .toLowerCase()
                    .indexOf(filterBy.name.toLowerCase()) !== -1,
        );
    }
    if (filterBy.actif) {
        newArray = newArray.filter((value) =>
            (value.contract?.dateFinContract !== "" &&
                moment(value.contract?.dateFinContract).isBefore(
                    moment(),
                    "day",
                )) ||
            moment(value.contract?.dateFinContract).isSame(moment(), "day")
                ? false
                : true,
        );
    }
    if (filterBy.deleted !== null) {
        newArray = newArray.filter(
            (value) => value.deleted === filterBy.deleted,
        );
    }
    return newArray;
};

const onSort = (data: any[], sortField: string, sortOrder: string) => {
    let copy = [...data];
    return copy.sort((a, b) => {
        let element1 = a[sortField] || "";
        let element2 = b[sortField] || "";
        switch (sortField) {
            case "fname": {
                let compare = 1;
                if (a["fname"].localeCompare([b["fname"]]) === 0) {
                    compare = a["lname"].localeCompare([b["lname"]]);
                } else {
                    compare = a["fname"].localeCompare([b["fname"]]);
                }
                return sortOrder === "asc" ? compare : -compare;
            }
            default: {
                let compare = element1
                    ? element1?.toString()?.localeCompare(element2?.toString())
                    : -1;
                return sortOrder === "asc" ? compare : -compare;
            }
        }
    });
};

const initialState: EmployesState = {
    actionLoading: false,
    listLoading: false,
    error: null,
    employees: [],
    archived: [],
    activeEmps: [],
    filtredEmployees: {
        employees: [],
        totalEmployees: 0,
        totalEmployeesPages: 0,
    },
    filtredArchived: {
        archived: [],
        totalArchived: 0,
        totalArchivedPages: 0,
    },
    disponibilities: [],
    register: [],
    filtredRegister: [],
    employeeForEdit: null,
    employeeInfos: null,
    filterBy: {
        role: [],
        name: "",
        actif: false,
        deleted: null,
        site: undefined,
        niveau: [],
    },
    sortBy: {
        field: "created_at",
        order: "desc",
    },
    documents: [],
    selectedDocument: null,
    primes: [],
    primeTypes: [],
    contracts: [],
    shifts: [],
    holidays: [],
    allActiveEmployees: [],
    filteredFromAllSites: [],
};

const employeeSlice = createSlice({
    name: "employees",
    initialState,
    reducers: {
        resetAllEmp: (state) => {
            state = initialState;
        },
        filterUpdated: (state: EmployesState, action: PayloadAction<any>) => {
            const filterBy = action.payload.filterBy;
            state.filterBy = filterBy;
            const employees = filter(state.employees, filterBy);
            const filteredFromAllSites = filter(state.allActiveEmployees, {
                ...filterBy,
                site: undefined,
            });

            const archived = filter(state.archived, filterBy);
            const register = filterRegister(state.register, filterBy);
            state.filtredEmployees = {
                employees: onSort(
                    employees,
                    state.sortBy.field,
                    state.sortBy.order,
                ),
                totalEmployees: employees.length,
                totalEmployeesPages: Math.ceil(employees.length / 10),
            };
            state.filtredArchived = {
                archived,
                totalArchived: archived.length,
                totalArchivedPages: Math.ceil(archived.length / 10),
            };
            state.filtredRegister = register;
            state.filteredFromAllSites = filteredFromAllSites;
        },
        sortUpdated: (state: EmployesState, action: PayloadAction<any>) => {
            const sortBy = action.payload;
            state.sortBy = sortBy;
            let empsToUpdate: any = {
                ...state.filtredEmployees,
                employees: onSort(
                    JSON.parse(
                        JSON.stringify(state.filtredEmployees?.employees),
                    ),
                    sortBy.field,
                    sortBy.order,
                ),
            };
            state.filtredEmployees = empsToUpdate;
            state.filtredArchived = {
                ...state.filtredArchived,
                archived: onSort(
                    JSON.parse(JSON.stringify(state.filtredArchived.archived)),
                    sortBy.field,
                    sortBy.order,
                ),
            };
        },
        sortPrimesByFieldName: (
            state: EmployesState,
            action: PayloadAction<any>,
        ) => {
            const { field, sort } = action.payload;
            if (field === "typeAcompte") {
                state.primes = state.primes.sort((a, b) => {
                    if (sort === "asc") {
                        return a.typeAcompte.name.localeCompare(
                            b.typeAcompte.name,
                        );
                    } else {
                        return b.typeAcompte.name.localeCompare(
                            a.typeAcompte.name,
                        );
                    }
                });
            } else {
                state.primes = state.primes.sort((a, b) => {
                    if (sort === "asc") {
                        if (a[field] < b[field]) {
                            return -1;
                        } else if (a[field] > b[field]) {
                            return 1;
                        } else {
                            return 0;
                        }
                    } else {
                        if (a[field] < b[field]) {
                            return 1;
                        } else if (a[field] > b[field]) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                });
            }
        },
        updateSelectedDocument: (
            state: EmployesState,
            action: PayloadAction<any>,
        ) => {
            const { fileName, categories, visibility, _id, reset } =
                action.payload;

            if (state.selectedDocument === null) {
                state.selectedDocument = {};
            }
            if (fileName !== undefined) {
                state.selectedDocument.fileName = fileName;
            }
            if (categories !== undefined) {
                state.selectedDocument.categories = categories;
            }
            if (visibility !== undefined) {
                state.selectedDocument.visibility = visibility;
            }
            if (_id !== undefined) {
                state.selectedDocument._id = _id;
            }
            if (reset === true) {
                state.selectedDocument = null;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEmployees.pending, (state: EmployesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            fetchEmployees.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                if (action.payload) {
                    const { data, archived } = action.payload;

                    state.listLoading = false;
                    let user = JSON.parse(sessionStorage.getItem("user") || "");

                    if (archived && data) {
                        state.archived = data;
                        const archived = filter(data, state.filterBy);
                        state.filtredArchived = {
                            archived,
                            totalArchived: archived.length,
                            totalArchivedPages: Math.ceil(archived.length / 10),
                        };
                    } else {
                        state.activeEmps = data;
                        state.allActiveEmployees = [
                            ...(user?.role === "Manager"
                                ? data?.filter(
                                      (emp: any) =>
                                          emp.role !== "Administrator",
                                  )
                                : data),
                        ];
                        state.filteredFromAllSites = state.allActiveEmployees;
                        const employees = filter(
                            user?.role === "Manager"
                                ? data?.filter(
                                      (emp: any) =>
                                          emp.role !== "Administrator",
                                  )
                                : data,
                            state.filterBy,
                        );
                        state.filtredEmployees = {
                            employees: onSort(
                                employees,
                                state.sortBy.field,
                                state.sortBy.order,
                            ),
                            totalEmployees: employees.length,
                            totalEmployeesPages: Math.ceil(
                                employees.length / 10,
                            ),
                        };
                        state.employees =
                            user?.role === "Manager"
                                ? data?.filter(
                                      (emp: any) =>
                                          emp.role !== "Administrator",
                                  )
                                : data;
                    }
                }
            },
        );
        builder.addCase(fetchEmployees.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(createEmployee.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(createEmployee.fulfilled, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = null;
        });
        builder.addCase(createEmployee.rejected, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(updateEmployee.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(
            updateEmployee.fulfilled,
            (state: EmployesState, action: any) => {
                state.employeeForEdit = action.payload.data;
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(updateEmployee.rejected, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(fetchEmployee.pending, (state: EmployesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            fetchEmployee.fulfilled,
            (state: EmployesState, action: PayloadAction) => {
                state.employeeForEdit = action.payload;
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(fetchEmployee.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(fetchEmployeeInfos.pending, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(
            fetchEmployeeInfos.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { infos } = action.payload;
                state.employeeInfos = infos;
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(fetchEmployeeInfos.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(
            fetchEmployeeDisponibilities.pending,
            (state: EmployesState) => {
                state.listLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeeDisponibilities.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { disponibilities } = action.payload;
                state.disponibilities = disponibilities;
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeeDisponibilities.rejected,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(
            createUserUnavaibility.pending,
            (state: EmployesState) => {
                state.actionLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            createUserUnavaibility.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            createUserUnavaibility.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(
            updateUserUnavaibility.pending,
            (state: EmployesState) => {
                state.actionLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            updateUserUnavaibility.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            updateUserUnavaibility.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(fetchRegister.pending, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(
            fetchRegister.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { register } = action.payload;
                state.register = register;
                state.filtredRegister = filterRegister(
                    register,
                    state.filterBy,
                );
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(fetchRegister.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(restoreEmp.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(restoreEmp.fulfilled, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = null;
        });
        builder.addCase(restoreEmp.rejected, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(archiveEmp.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(archiveEmp.fulfilled, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = null;
        });
        builder.addCase(archiveEmp.rejected, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(updateEmployeeInfos.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(
            updateEmployeeInfos.fulfilled,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            updateEmployeeInfos.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(fetchContracts.pending, (state: EmployesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(fetchContracts.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(
            fetchContracts.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                state.contracts = data;
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(updateContract.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(updateContract.fulfilled, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = null;
        });
        builder.addCase(updateContract.rejected, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(createContract.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(createContract.fulfilled, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = null;
        });
        builder.addCase(createContract.rejected, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(removeUnavaibility.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(
            removeUnavaibility.fulfilled,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(removeUnavaibility.rejected, (state: EmployesState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(
            fetchEmployeeDocuments.pending,
            (state: EmployesState) => {
                state.listLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeeDocuments.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                state.documents = data;
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeeDocuments.rejected,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(addEmployeeDocument.pending, (state: EmployesState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(
            addEmployeeDocument.fulfilled,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            addEmployeeDocument.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(
            deleteEmployeeDocument.pending,
            (state: EmployesState) => {
                state.actionLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            deleteEmployeeDocument.fulfilled,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            deleteEmployeeDocument.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(fetchEmployeePrimes.pending, (state: EmployesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            fetchEmployeePrimes.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                state.primes = data;
                state.listLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeePrimes.rejected,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(
            createEmployeePrimes.pending,
            (state: EmployesState) => {
                state.actionLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            createEmployeePrimes.fulfilled,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            createEmployeePrimes.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(
            updateEmployeePrimes.pending,
            (state: EmployesState) => {
                state.actionLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            updateEmployeePrimes.fulfilled,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            updateEmployeePrimes.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(
            deleteEmployeePrimes.pending,
            (state: EmployesState) => {
                state.actionLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            deleteEmployeePrimes.fulfilled,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            deleteEmployeePrimes.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(fetchPrimesTypes.pending, (state: EmployesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            fetchPrimesTypes.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                state.primeTypes = data;
                state.listLoading = true;
                state.error = null;
            },
        );
        builder.addCase(fetchPrimesTypes.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(
            fetchEmployeeWorkHours.pending,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeeWorkHours.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { shifts } = action.payload.data;
                state.shifts = shifts;
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeeWorkHours.rejected,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(
            fetchEmployeeHolidays.pending,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeeHolidays.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const holidays = action.payload.data;

                state.holidays = holidays;
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            fetchEmployeeHolidays.rejected,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(
            updateEmployeePassword.pending,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            updateEmployeePassword.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            updateEmployeePassword.rejected,
            (state: EmployesState) => {
                state.actionLoading = false;
                state.error = "error";
            },
        );

        builder.addCase(
            createEmployeeFolder.pending,
            (state: EmployesState) => {
                state.listLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            createEmployeeFolder.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                console.log(data, action);
                state.documents = {
                    ...state.documents,
                    folders: [...state.documents.folders, data],
                };
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            createEmployeeFolder.rejected,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = "error";
            },
        );

        builder.addCase(editEmployeeFolder.pending, (state: EmployesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            editEmployeeFolder.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                const folderIndex = state.documents.folders.findIndex(
                    (item: any) => item._id === data._id,
                );
                const newFoldersState = [...state.documents.folders];
                if (folderIndex > -1) {
                    newFoldersState[folderIndex] = data;
                    state.documents = {
                        ...state.documents,
                        folders: newFoldersState,
                    };
                    state.listLoading = false;
                    state.error = null;
                }
            },
        );
        builder.addCase(editEmployeeFolder.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });

        builder.addCase(deleteFolder.pending, (state: EmployesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            deleteFolder.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                console.log(data, action);
                state.documents = {
                    ...state.documents,
                    folders: state.documents.folders?.filter(
                        (folder: any) => folder._id !== data._id,
                    ),
                    files: state.documents.files.map((file: any) => ({
                        ...file,
                        folder:
                            file.folder?._id === data._id ? null : file.folder,
                    })),
                };
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(deleteFolder.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });

        builder.addCase(
            updateExistingDocument.pending,
            (state: EmployesState) => {
                state.listLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            updateExistingDocument.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                state.documents = {
                    ...state.documents,
                    files: state.documents.files.map((file: any) =>
                        file._id === data._id ? data : file,
                    ),
                };
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            updateExistingDocument.rejected,
            (state: EmployesState) => {
                state.listLoading = false;
                state.error = "error";
            },
        );
        ////register
        builder.addCase(
            downloadRegistre.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                // const { data } = action.payload;
                console.log("download register == ", action.payload);
            },
        );

        builder.addCase(uploadNewDocument.pending, (state: EmployesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            uploadNewDocument.fulfilled,
            (state: EmployesState, action: PayloadAction<any>) => {
                const { data } = action.payload;
                state.documents = {
                    ...state.documents,
                    files: [...state.documents.files, data],
                };
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(uploadNewDocument.rejected, (state: EmployesState) => {
            state.listLoading = false;
            state.error = "error";
        });
    },
});
export const { resetAllEmp } = employeeSlice.actions;
export const { reducer, actions } = employeeSlice;
export default reducer;
//fetchEmployeeHolidays updateEmployeePassword
