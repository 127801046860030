import React from "react";
import classNames from "classnames";
import Button from "../../atoms/Button";
import { NavLink, Link, Route, useLocation } from "react-router-dom";

import "./style.scss";

export default function TabNavigator(props: TabnavigatorInterface) {
    const {
        tabs,
        inactiveColor,
        activeColor,
        inactiveBorderBottom,
        activeBorderBottom,
    } = props;
    const [workedTabs, setWorkedTabs] = React.useState(
        tabs.filter((tab) => tab.disable !== true),
    );
    React.useEffect(() => {
        setWorkedTabs(tabs.filter((tab) => tab.disable !== false));
    }, [tabs]);

    const location = useLocation();
    const [tabState, settabState] = React.useState(
        workedTabs?.map((tab) => location.pathname === tab?.link),
    );

    const handleClick = (key: number) => {
        const updatedArr = Array(workedTabs?.length).fill(false);
        updatedArr[key] = true;
        settabState(updatedArr);
        workedTabs?.[key]?.onClick?.(key);
    };

    React.useEffect(() => {
        const currentTab = workedTabs?.findIndex(
            (tab) => tab?.link === location.pathname,
        );
        if (currentTab !== -1) {
            const updatedTabState = Array(workedTabs?.length).fill(false);
            updatedTabState[currentTab] = true;
            settabState(updatedTabState);
        }
    }, [workedTabs, location]);

    return (
        <div
            className="tab-navigation-container"
            style={{
                height: "calc(100vh - 165px)",
                overflow: "auto",
            }}
        >
            <div
                className="header-banner"
                style={{
                    position: "sticky",
                    top: "0",
                    zIndex: "99",
                    backgroundColor: "white",
                }}
            >
                <ul>
                    {workedTabs?.map((tab, key) => (
                        <li
                            onClick={() => handleClick(key)}
                            style={{
                                color: tabState[key]
                                    ? activeColor
                                    : inactiveColor,
                                borderBottom: tabState[key]
                                    ? activeBorderBottom
                                    : inactiveBorderBottom,
                            }}
                            key={key}
                        >
                            <NavLink to={tab?.link}>{tab?.title}</NavLink>
                        </li>
                    ))}
                </ul>
            </div>
            {workedTabs?.map((tab, key) => (
                <div key={key}>{tabState[key] && tab?.component}</div>
            ))}
        </div>
    );
}
interface TabnavigatorInterface {
    tabs: Array<TabsInterface>;
    onClick?: (data: any) => void;
    inactiveColor?: string;
    activeColor?: string;
    inactiveBorderBottom?: string;
    activeBorderBottom?: string;
}
interface TabsInterface {
    title: string | React.ReactNode;
    component?: React.ReactNode;
    link: string;
    active?: boolean;
    onClick?: (data: any) => void;
    disable?: boolean;
}
TabNavigator.defaultProps = {
    inactiveColor: "#5BB3D0",
    activeColor: "#1A7290",
    inactiveBorderBottom: "",
    activeBorderBottom: "4px solid #2A8BAB",
};
