import React from "react";
import { FilledTriangle, Inform } from "../../../Icons";
import tailwindTheme from "../../../tailwind-theme";

export default function Popover({ text, width, left, right }: PopoverProps) {
    const [top, setTop] = React.useState(0);

    const divRef = React.useRef<HTMLDivElement>(null);

    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    React.useEffect(() => {
        if (divRef.current) {
            const height = divRef.current.getBoundingClientRect().height;
            setTop(-height);
        }
    }, [divRef.current, isHovered]);

    return (
        <div
            className="relative flex justify-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <a className="cursor-pointer">
                <Inform />
            </a>
            {isHovered && (
                <>
                    <div
                        ref={divRef}
                        style={{
                            top: `${top - 14}px`,
                            width: width ?? "200px",
                            left: left === undefined ? "-150px" : left,
                            right: right === undefined ? "" : right,
                        }}
                        className={`absolute bg-n-800 z-50 rounded-lg px-3 py-2 text-white w-[200px] text-base`}
                    >
                        {text}
                    </div>
                    <div className="absolute top-[-16px]">
                        <FilledTriangle fill={tailwindTheme.colors["n-800"]} />
                    </div>
                </>
            )}
        </div>
    );
}

interface PopoverProps {
    text?: string | React.ReactNode;
    width?: string;
    left?: string;
    right?: string;
}
