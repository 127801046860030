import React, { useState } from "react";
// import Modal from "../Modal";
import Button from "../../atoms/ZButton";
import ZTextarea from "../../atoms/ZTextarea";
import DatepickerInput from "../../atoms/DatepickerInput";
import TabTrigger from "../../molecules/TabTrigger";
import SelectInput from "../../atoms/SelectInput";
// import SelectInput from "../../molecules/SelectInput";
import ZToggleButton from "../../atoms/ZToggleButton";
import Input from "../../atoms/Input";
import ZInput from "../../atoms/ZInput";
import { useHoliday } from "../../../hooks/useHolidays";
import PopUpCard from "../../molecules/PopUpCard";
import Modal from "../../atoms/Modal";
import Loading from "../../atoms/Loading";

const CreateCongeCard = ({
    onOpen,
    employeesOptions,
    onChange,
    onBlur,
    congeData,
    congeTypes,
    onSubmit,
    onReset,
}: CreateCongeCardProps) => {
    const { loading } = useHoliday();

    // *** PS: bech tsiir l integration mtaa code hedha baad maysir modification l api ****
    // const [activeTab, setActiveTab] = React.useState(0);
    //     if (index !== activeTab) {
    //         setActiveTab(index);
    //         const typedayValue = index === 0 ? false : true;

    //         onChange("typeday", typedayValue);
    //     }
    // const [activeTab, setActiveTab] = React.useState<number | null>(null);
    // const [traitement, setTraitement] = React.useState(false);

    // const changeTab = (index: number) => {
    //     if (index !== activeTab) {
    //         setActiveTab(index);
    //     }
    // };

    // const handleToggle = () => {
    //     setTraitement((prev) => {
    //         // If traitement is being turned off, reset activeTab to null
    //         if (prev) {
    //             setActiveTab(null);
    //         }
    //         return !prev;
    //     });
    // };

    // const tabs = [
    //     {
    //         title: "Approuvé",
    //         value: 1,
    //     },
    //     {
    //         title: "Refusé",
    //         value: 2,
    //     },
    // ];

    const checkError = () => {
        let error = false;
        Object?.keys(congeData)?.forEach((key) => {
            if (congeData[key]?.hasError) {
                error = true;
            }
        });
        return error;
    };

    const handleSubmit = () => {
        if (!checkError()) {
            onSubmit();
        }
    };
    if (!onOpen) {
        return null;
    }
    return (
        <div>
            <Modal modalEnabled={onOpen} onClose={onReset}>
                <div className="px-12">
                    <PopUpCard
                        containerWidth={"560"}
                        display={onOpen}
                        header={"Ajouter une demande de congé"}
                        onClose={onReset}
                        isCloseIcon={true}
                        activateFooter={false}
                    >
                        {loading ? (
                            <div style={{ height: 400 }}>
                                <Loading width="400px" height="200px" />
                            </div>
                        ) : (
                            <div className="w-full  min-h-[470px]">
                                <div className="emp-selection-container px-6 flex flex-col gap-4 mb-5 w-full">
                                    <div className="mt-3">
                                        <SelectInput
                                            title="Employé"
                                            required
                                            placeholder="Sélectionnner un employé"
                                            style={{
                                                // padding: "0 10px",
                                                height: "48px",
                                                width: "100%",
                                                borderRadius: "8px",
                                                fontSize: "12px",
                                            }}
                                            options={employeesOptions}
                                            onSelectOption={(option) => {
                                                onChange(
                                                    "employeeId",
                                                    option?.value,
                                                );
                                            }}
                                            selectedValue={employeesOptions.find(
                                                (option: any) =>
                                                    option.value ===
                                                    congeData?.employeeId
                                                        ?.value,
                                            )}
                                            onBlur={() => {
                                                onBlur("employeeId");
                                            }}
                                            isSearchable={false}
                                            // touched={
                                            //     congeData?.employeeId?.touched
                                            // }
                                            // error={congeData?.employeeId?.error}
                                        />
                                    </div>
                                    <div className="">
                                        <SelectInput
                                            title="Type"
                                            required
                                            placeholder="Sélectionnner le type du congé"
                                            style={{
                                                // padding: "0 10px",
                                                height: "48px",
                                                width: "100%",
                                                borderRadius: "8px",
                                                fontSize: "12px",
                                            }}
                                            options={congeTypes}
                                            onSelectOption={(option) => {
                                                onChange(
                                                    "typeConge",
                                                    option.value,
                                                );
                                            }}
                                            isSearchable={false}
                                            selectedValue={congeTypes.find(
                                                (option: any) =>
                                                    option.value ===
                                                    congeData?.typeConge?.value,
                                            )}
                                            onBlur={() => {
                                                onBlur("typeConge");
                                            }}
                                            // touched={
                                            //     congeData?.typeConge?.touched
                                            // }
                                            // error={congeData?.typeConge?.error}
                                        />
                                    </div>

                                    <DatepickerInput
                                        required={true}
                                        label="À partir de"
                                        field={congeData?.startDate}
                                        onUpdate={(value) => {
                                            onChange("startDate", value);
                                        }}
                                    />
                                    <DatepickerInput
                                        required={true}
                                        label="Jusqu’au"
                                        field={congeData?.endDate}
                                        onUpdate={(value) => {
                                            onChange("endDate", value);
                                        }}
                                    />
                                    {/* **  PS:this for the new version   *** */}
                                    {/* <div className="rounded-md border border-n-100 p-2 flex flex-col space-y-2">
                                        <div>
                                            <ZToggleButton
                                                isChecked={traitement}
                                                onPress={handleToggle}
                                                leftComponent={
                                                    <div>
                                                        <p className="text-n-700 text-light">
                                                            Approuvé ou refusé
                                                            conge direcement.
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </div>

                                        {traitement && (
                                            <div>
                                                <TabTrigger
                                                    containerClasses="!bg-n-0-op-2"
                                                    headerClasses="w-full text-center"
                                                    tabs={tabs.map(
                                                        (tab, index) => ({
                                                            ...tab,
                                                            active:
                                                                index ===
                                                                activeTab,
                                                            onClick: (idx) =>
                                                                changeTab(idx),
                                                        }),
                                                    )}
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                    tabsStyle={{
                                                        position: "sticky",
                                                        top: "0",
                                                        backgroundColor:
                                                            "#fbfcfd",
                                                        zIndex: "0",
                                                        boxShadow:
                                                            "0px 3px 5px rgba(0, 0, 0, 0.1)",
                                                        padding: "4px",
                                                        borderRadius: "12px",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div> */}

                                    {/* <div>
                                                        <ZToggleButton
                                                            isChecked={congeData?.typeday?.value}
                                                            onPress={async()=>{
                                                                onChange("typeday", !congeData?.typeday?.value);
                                                            }}
                                                            leftComponent={
                                                                <div>
                                                                    <p className="text-n-700 text-md">Toute la journée</p>
                                                                </div>
                                                            }
                                                        />
                                                        
                                    </div> */}
                                    {/* <div className="flex space-x-1">
                                                    {congeData?.typeday?.value == false && (
                                                        <>
                                                        <ZInput
                                                            
                                                                type="time"
                                                                required={false} 
                                                                label={"Début"}
                                                                field={congeData?.fromHour  }
                                                                onUpdate={() => {
                                                                }}
                                                                onBlur={() => {
                                                                    // onBlur("signatureRequestName")
                                                                }}
                                                                style={{
                                                                    paddingLeft: "6px",
                                                                    paddingRight: "6px"
                                                                }}
                                                            />
                                                            <ZInput
                                                                type="time"
                                                                required={false} 
                                                                label={"Début"}
                                                                field={congeData?.fromHour  }
                                                                onUpdate={() => {
                                                                }}
                                                                onBlur={() => {
                                                                    // onBlur("signatureRequestName")
                                                                }}
                                                                style={{
                                                                    paddingLeft: "6px",
                                                                    paddingRight: "6px"
                                                                }}
                                                            />
                                                        </>
                                                    )}  
                                                        </div> */}
                                    {/* </div> */}
                                    <ZTextarea
                                        rows={1}
                                        field={congeData?.note}
                                        maxlength={100}
                                        required={false}
                                        label={"Notes (100 caractères)"}
                                        onUpdate={(option) =>
                                            onChange("note", option?.toString())
                                        }
                                    />
                                </div>
                                <div className="flex justify-end space-x-2 border border-t-[1px] rounded-b-[20px] p-4">
                                    <Button
                                        title={"Annuler"}
                                        onClick={onReset}
                                    />
                                    <Button
                                        loading={loading}
                                        variant="primary"
                                        title={"Ajouter"}
                                        disabled={checkError()}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        )}
                    </PopUpCard>
                </div>
            </Modal>
        </div>
    );
};

interface CreateCongeCardProps {
    congeData: any;
    onOpen: boolean;
    congeTypes: any;
    onClose?: () => void;
    onSubmit: () => void;
    onReset: () => void;
    onChange: (field: string, value: any) => void;
    onBlur: (field: string) => void;
    employeesOptions: any;
}

export default CreateCongeCard;
