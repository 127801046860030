import React, { useEffect, useRef } from "react";
import moment, { Moment } from "moment";
import CalendarHeader from "../../molecules/CalendarHeader";
import { buildCalendar } from "../../../_helpers/CalendarHelpers";
import CalendarBody from "../../molecules/CalendarBody";
import CalendarExtras from "../../molecules/CalendarExtras";

type CalendarType = Array<Array<Moment | null>>;
type RangeType = { from: Moment; to: Moment };

export default function Calendar({
    value,
    close,
    onChange,
    open = true,
    showWeekCount = true,
    from,
    to,
    ranged,
    extras,
    styles,
    calenderContainerClasses,
    monthEffect,
    showMonthArrows = true,
    disabledFrom,
    disabledTo,
    markedDays,
    yearsType = "",
}: CalendarInterface) {
    const [calendar, setCalendar] = React.useState<CalendarType>([]);
    const [month, setMonth] = React.useState<any>();

    React.useEffect(() => {
        if (!month || !moment(from ? from : value).month() === month.month()) {
            setMonth(moment(from ? from : value));
        }
    }, [value, from, to]);

    const nextMonth = () => {
        setMonth(month.clone().add(1, "month"));
    };

    const prevMonth = () => {
        setMonth(month.clone().subtract(1, "month"));
    };

    React.useEffect(() => {
        setCalendar(buildCalendar(month));
    }, [month]);
    React.useEffect(() => {
        if (monthEffect && open && month) {
            monthEffect(month);
        }
    }, [month, open]);

    const selectMonth = (m: number) => {
        let newMonth = month.clone().set("month", m);
        setMonth(newMonth);
    };

    const selectYear = (year: number) => {
        let newMonth = month.clone().set("year", year);
        setMonth(newMonth);
    };
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const target = event.target as HTMLElement;
            const isOutsideCalendar =
                ref.current && !ref.current.contains(target);
            const isInsideReactSelect =
                target.closest(".custom-option-container") ||
                target.closest(".arrow-container");

            if (isOutsideCalendar && !isInsideReactSelect) {
                setTimeout(() => close?.(), 100);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
    return (
        <>
            {open ? (
                <div
                    ref={ref}
                    style={styles}
                    className={`max-w-[360px] bg-white p-3 rounded ${calenderContainerClasses}`}
                >
                    <CalendarHeader
                        value={month}
                        nextMonth={nextMonth}
                        prevMonth={prevMonth}
                        selectMonth={selectMonth}
                        selectYear={selectYear}
                        showMonthArrows={showMonthArrows}
                        yearsType={yearsType}
                    />

                    <CalendarBody
                        calendar={calendar}
                        value={value}
                        showWeekCount={showWeekCount}
                        ranged={ranged}
                        from={from}
                        to={to}
                        disabledFrom={disabledFrom}
                        disabledTo={disabledTo}
                        onChange={onChange}
                        markedDays={markedDays}
                    />
                    {extras && <CalendarExtras extras={extras} />}
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

interface CalendarInterface {
    value?: Moment;
    showWeekCount?: boolean;
    open?: boolean;
    close?: () => void;
    onChange?(value: Moment | RangeType): void;
    from?: Moment | null;
    to?: Moment | null;
    disabledFrom?: Moment | null;
    disabledTo?: Moment | null;
    ranged?: boolean;
    extras?: Array<{ title: string; node: React.ReactNode }>;
    styles?: React.CSSProperties;
    calenderContainerClasses?: string;
    monthEffect?: (month: Moment) => void;
    showMonthArrows?: boolean;
    selectedDates?: string[];
    markedDays?: { [key: string]: string };
    yearsType?: string;
}
