import React from "react";
import ZInput from "../../atoms/ZInput";
import DropzoneInput from "../../molecules/DropzoneInput";
import { useIntl } from "react-intl";
import Popover from "../../molecules/Popover";

export default function CreateEmployeeInfoForm({
    employee,
    onChange,
    onBlur,
}: CreateEmployeeInfoFormInterface) {
    const intl = useIntl();
    return (
        <div
            className="flex justify-between w-full px-5 gap-10"
            style={{ marginLeft: "200px" }}
        >
            <div className="flex flex-grow flex-col gap-5">
                <ZInput
                    label={intl.formatMessage({
                        id: "EMPLOYEE.ADD.LABEL.FIRST_NAME",
                    })}
                    field={employee.fname}
                    required={true}
                    placeholder={intl.formatMessage({
                        id: "EMPLOYEE.ADD.PLACEHOLDER.FIRST_NAME",
                    })}
                    onUpdate={(value) => {
                        let capitalValue: string = String(value);
                        const namePattern = /^[A-Za-z]+([- ][A-Za-z]*)*[- ]?$/;
                        if (
                            namePattern.test(capitalValue) ||
                            capitalValue === ""
                        ) {
                            capitalValue = capitalValue
                                .toLowerCase()
                                .split(" ")
                                .map(
                                    (word: string) =>
                                        word?.charAt(0)?.toUpperCase() +
                                        word?.slice(1),
                                )
                                .join(" ");
                            onChange("fname", capitalValue);
                        }
                    }}
                    maxLength={30}
                    onBlur={onBlur}
                />
                <ZInput
                    label={intl.formatMessage({
                        id: "EMPLOYEE.ADD.LABEL.LAST_NAME",
                    })}
                    field={employee.lname}
                    required={true}
                    placeholder={intl.formatMessage({
                        id: "EMPLOYEE.ADD.PLACEHOLDER.LAST_NAME",
                    })}
                    onUpdate={(value) => {
                        let capitalValue: string = String(value);
                        const namePattern = /^[A-Za-z]+([- ][A-Za-z]*)*[- ]?$/;
                        if (
                            namePattern.test(capitalValue) ||
                            capitalValue === ""
                        ) {
                            capitalValue = capitalValue
                                .toLowerCase()
                                .split(" ")
                                .map(
                                    (word: string) =>
                                        word?.charAt(0)?.toUpperCase() +
                                        word?.slice(1),
                                )
                                .join(" ");
                            onChange("lname", capitalValue);
                        }
                    }}
                    maxLength={30}
                    onBlur={onBlur}
                />
                <ZInput
                    label={intl.formatMessage({
                        id: "EMPLOYEE.ADD.LABEL.EMAIL",
                    })}
                    placeholder={intl.formatMessage({
                        id: "EMPLOYEE.ADD.PLACEHOLDER.EMAIL",
                    })}
                    field={employee.email}
                    required={false}
                    type="email"
                    onUpdate={(value) => {
                        const emailPattern = /^[a-zA-Z0-9@._-]*$/;
                        if (
                            emailPattern.test(String(value)) ||
                            String(value) === ""
                        ) {
                            onChange("email", value);
                        }
                    }}
                    onBlur={onBlur}
                    labelRightAction={
                        <Popover
                            text={`Pour permettre à votre collaborateur de se connecter via l'application mobile, veuillez remplir au moins l'un des champs suivants : téléphone ou email.`}
                        />
                    }
                />
                <ZInput
                    type="text"
                    label={intl.formatMessage({
                        id: "EMPLOYEE.ADD.LABEL.PHONE",
                    })}
                    placeholder={intl.formatMessage({
                        id: "EMPLOYEE.ADD.PLACEHOLDER.PHONE",
                    })}
                    field={employee.phone}
                    required={false}
                    isPhoneNumber={true}
                    onUpdate={(value) => {
                        if (String(value).length <= 15) {
                            onChange("phone", value);
                        }
                    }}
                    onBlur={onBlur}
                    labelRightAction={
                        <Popover
                            text={`Pour permettre à votre collaborateur de se connecter via l'application mobile, veuillez remplir au moins l'un des champs suivants : téléphone ou email.`}
                        />
                    }
                />
            </div>
            <div className="mx-auto">
                <DropzoneInput
                    file={employee.file.value}
                    onChange={(file) => {
                        onChange("file", file);
                    }}
                />
            </div>
        </div>
    );
}

interface CreateEmployeeInfoFormInterface {
    employee: any;
    onChange: (field: string, value: any) => void;
    onBlur: (field: string) => void;
}
