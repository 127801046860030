import React from "react";
import Modal from "../../../components/organism/Modal";
import { useIntl } from "react-intl";
import { useEmployees } from "../../../hooks/useEmployees";
import { Helmet } from "react-helmet";
import Button from "../../../components/atoms/ZButton";
import { useNotificationContext } from "../../../Context/Notification";

export default function RestoreEmployeeDialog({
    employee,
    show,
    onHide,
}: RestoreEmployeeDialogInterface) {
    const { addNotification } = useNotificationContext();
    const {
        actionLoading,
        restoreEmployee,
        getEmployees,
        employeeForEdit,
        getEmployee,
    } = useEmployees();

    const intl = useIntl();

    const Body = (
        <div className="h-full flex justify-center items-center flex-wrap">
            <div>
                {actionLoading === true
                    ? intl.formatMessage({
                          id: "EMPLOYEE.DIALOG.RESTORE.RESTORING",
                      })
                    : intl.formatMessage(
                          { id: "EMPLOYEE.DIALOG.RESTORE.CONFIRMATION" },
                          {
                              name: (
                                  <b>
                                      {employee.fname} {employee.lname}
                                  </b>
                              ),
                          },
                      )}
            </div>
        </div>
    );

    const resEmployee = (id: string) => {
        restoreEmployee({ id }).then((res) => {
            const data: any = res.payload;
            if (data.status === "success") {
                if (data.data?.isPackageError) {
                    addNotification({
                        title: intl.formatMessage({
                            id: "EMPLOYEE.RESTORE.NOTIFICATION.FAIL.TITLE",
                        }),
                        message:
                            data.data?.errorMessage ??
                            intl.formatMessage({
                                id: "EMPLOYEE.RESTORE.NOTIFICATION.FAIL.MESSAGE",
                            }),
                        type: "success",
                    });
                } else {
                    addNotification({
                        title: intl.formatMessage({
                            id: "EMPLOYEE.RESTORE.NOTIFICATION.SUCCESS.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "EMPLOYEE.RESTORE.NOTIFICATION.SUCCESS.MESSAGE",
                        }),
                        type: "success",
                    });
                    if (employeeForEdit) {
                        getEmployee({ id: employeeForEdit._id });
                    } else {
                        getEmployees({
                            archived: true,
                        });
                    }
                }
            } else if (data.status === "fail") {
                if (data?.data?.isPackageLimited) {
                    addNotification({
                        title: intl.formatMessage({
                            id: "EMPLOYEE.CREATE.NOTIFICATION.FAIL.TITLE",
                        }),
                        message:
                            data?.data?.errorMessage ??
                            intl.formatMessage({
                                id: "EMPLOYEE.CREATE.NOTIFICATION.FAIL.MESSAGE_UPGRADE",
                            }),
                        type: "fail",
                    });
                } else {
                    addNotification({
                        title: intl.formatMessage({
                            id: "EMPLOYEE.RESTORE.NOTIFICATION.FAIL.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "EMPLOYEE.RESTORE.NOTIFICATION.FAIL.MESSAGE",
                        }),
                        type: "fail",
                    });
                }
            }
            onHide();
        });
    };

    return (
        <>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "APP.TITLE" })} |{" "}
                    {intl.formatMessage({
                        id: "EMPLOYEE.DIALOG.RESTORE.TITLE",
                    })}
                </title>
            </Helmet>
            <Modal
                width={400}
                height={260}
                show={show}
                onHide={onHide}
                title={`${intl.formatMessage({ id: "EMPLOYEE.DIALOG.RESTORE.TITLE" })}`}
                body={Body}
                ConfirmComponent={
                    <Button
                        title={intl.formatMessage({ id: "MENU.RESTORE" })}
                        variant="primary"
                        loading={actionLoading}
                        onClick={() => resEmployee(employee._id)}
                        size="block"
                    />
                }
            />
        </>
    );
}

interface RestoreEmployeeDialogInterface {
    employee: any;
    show: boolean;
    onHide(): void;
}
