import React from "react";
import ContractEdit from "../../../components/templates/EmployeesEdit/ContractEdit";
import { useEmployees } from "../../../hooks/useEmployees";
import {
    generateFields,
    destructFields,
    Validation,
} from "../../../_helpers/FormHelpers";
import NoContractsFound from "./NoContractsFound";
import Contract from "../../../components/templates/EmployeesEdit/Contract";
import EditAmendmentDialogPage from "./AddAmendmentDialogPage";
import DeleteContractDialog from "./DeleteContractPage";
import {
    ContractCreateRequest,
    ContractUpdateRequest,
} from "../../../redux/features/employees/types";
import moment from "moment";
import { useNotificationContext } from "../../../Context/Notification";
import { useIntl } from "react-intl";
import { qualifications } from "../../../Options";

export default function ContractsPage() {
    const {
        employeeForEdit,
        actionLoading,
        editContract,
        getEmployee,
        getContracts,
        contracts,
        addContract,
    } = useEmployees();

    React.useEffect(() => {
        getContracts({ userId: employeeForEdit._id });
    }, []);

    const [contract, setContract] = React.useState<Contract>();
    const [amendment, setAmendment] = React.useState(null);
    const [contractDelete, setContractDelete] = React.useState(null);
    React.useEffect(() => {
        console.log(" contract ", contract);
    }, [contract]);

    const { addNotification } = useNotificationContext();
    const intl = useIntl();

    const getQualificationValue = (value: string) => {
        if (value === "") return "";
        let index = qualifications.findIndex((val) => val.value === value);
        if (index !== -1) {
            return qualifications[index];
        }
        return "";
    };

    const onChange = (field: string, value: any) => {
        if (contract) {
            const newState = {
                ...contract,
                [field]: {
                    ...contract[field],
                    value,
                },
            };
            setContract(newState);
            validate(newState);
        }
    };

    const onBlur = (field: string) => {
        if (contract) {
            const newState = {
                ...contract,
                [field]: {
                    ...contract[field],
                    touched: true,
                },
            };
            setContract(newState);
            validate(newState);
        }
    };

    const validate = (contract: Contract) => {
        if (contract) {
            new Validation()
                .add(contract.dateDebutContract, [
                    {
                        TEST: () => {
                            let error = false;
                            if (
                                contract.dateDebutContract.value &&
                                contract.dateDebutContract.value !== "" &&
                                contract.dateFinContract.value &&
                                contract.dateFinContract.value !== ""
                            ) {
                                if (
                                    moment(
                                        contract.dateDebutContract.value,
                                    ).isAfter(
                                        contract.dateFinContract.value,
                                        "D",
                                    )
                                )
                                    return true;
                            }
                            if (error) return error;
                            if (
                                (contract.type.value === "CDD" ||
                                    contract.type.value === "CDI") &&
                                contract.dateDebutContract.value &&
                                contract.dateDebutContract.value !== "" &&
                                contract.dateFinPerideEssai.value &&
                                contract.dateFinPerideEssai.value !== ""
                            ) {
                                if (
                                    moment(
                                        contract.dateDebutContract.value,
                                    ).isAfter(
                                        contract.dateFinPerideEssai.value,
                                        "D",
                                    )
                                )
                                    return true;
                            }
                            return false;
                        },
                        message: "Dates incohérentes",
                    },
                ])
                .add(contract.dateFinContract, [
                    {
                        TEST: () => {
                            if (
                                contract.dateDebutContract.value &&
                                contract.dateDebutContract.value !== "" &&
                                contract.dateFinContract.value &&
                                contract.dateFinContract.value !== ""
                            ) {
                                if (
                                    moment(
                                        contract.dateFinContract.value,
                                    ).isBefore(
                                        contract.dateDebutContract.value,
                                        "D",
                                    )
                                )
                                    return true;
                            }
                            if (
                                (contract.type.value === "CDD" ||
                                    contract.type.value === "CDI") &&
                                contract.dateFinContract.value &&
                                contract.dateFinContract.value !== "" &&
                                contract.dateFinPerideEssai.value &&
                                contract.dateFinPerideEssai.value !== ""
                            ) {
                                if (
                                    moment(
                                        contract.dateFinContract.value,
                                    ).isBefore(
                                        contract.dateFinPerideEssai.value,
                                        "D",
                                    )
                                )
                                    return true;
                            }
                            return false;
                        },
                        message: "Dates incohérentes",
                    },
                ])
                .add(contract.dateFinPerideEssai, [
                    {
                        TEST: () => {
                            if (
                                contract.type.value !== "CDD" &&
                                contract.type.value !== "CDI"
                            )
                                return false;
                            if (
                                contract.dateDebutContract.value &&
                                contract.dateDebutContract.value !== "" &&
                                contract.dateFinPerideEssai.value &&
                                contract.dateFinPerideEssai.value !== ""
                            ) {
                                if (
                                    moment(
                                        contract.dateFinPerideEssai.value,
                                    ).isBefore(
                                        contract.dateDebutContract.value,
                                        "D",
                                    )
                                )
                                    return true;
                            }
                            if (
                                contract.dateFinContract.value &&
                                contract.dateFinContract.value !== "" &&
                                contract.dateFinPerideEssai.value &&
                                contract.dateFinPerideEssai.value !== ""
                            ) {
                                if (
                                    moment(
                                        contract.dateFinPerideEssai.value,
                                    ).isAfter(
                                        contract.dateFinContract.value,
                                        "D",
                                    )
                                )
                                    return true;
                            }
                            return false;
                        },
                        message: "Dates incohérentes",
                    },
                ])
                .validate((res) => {
                    setContract((prev) => ({
                        ...prev,
                        ...generateFields(res),
                    }));
                });
        }
    };
    const initContractForEdit = (contract: any) => {
        if (contract) {
            let newState = generateFields([
                { name: "closed", value: contract.closed },
                { name: "_id", value: contract._id },
                {
                    name: "dateDebutContract",
                    value: contract.dateDebutContract,
                    touched: true,
                },
                {
                    name: "dateExpireTravailleurEtranger",
                    value: contract.dateExpireTravailleurEtranger,
                },
                {
                    name: "dateFinContract",
                    value: contract.dateFinContract,
                    touched: true,
                },
                {
                    name: "dateFinPerideEssai",
                    value: contract.dateFinPerideEssai,
                    touched: true,
                },
                {
                    name: "derniereVisiteMedicale",
                    value: contract.derniereVisiteMedicale,
                },
                { name: "echelon", value: contract.echelon },
                { name: "emploi", value: contract.emploi },
                {
                    name: "heureSupplementaire",
                    value: contract.heureSupplementaire,
                },
                {
                    name: "indemniteDeTransport",
                    value: contract.indemniteDeTransport,
                },
                {
                    name: "joursParSemaine",
                    value: contract.joursParSemaine,
                },
                { name: "mutuelle", value: contract.mutuelle },
                { name: "niveau", value: contract.niveau },
                {
                    name: "numDocumentTravailleurEtranger",
                    value: contract.numDocumentTravailleurEtranger,
                },
                { name: "plusInterim", value: contract.plusInterim },
                {
                    name: "plusStagiaire",
                    value: contract.plusStagiaire,
                },
                {
                    name: "qulification",
                    value: getQualificationValue(contract.qulification),
                },
                {
                    name: "salaireBrutMensuel",
                    value: contract.salaireBrutMensuel,
                },
                {
                    name: "statusTravailleurEtranger",
                    value: contract.statusTravailleurEtranger,
                },
                {
                    name: "tauxHoraireBrut",
                    value: contract.tauxHoraireBrut,
                },
                {
                    name: "tempsDeTravailHebdomadaire",
                    value: contract.tempsDeTravailHebdomadaire,
                },
                { name: "type", value: contract.type },
                {
                    name: "typeDocumentTravailleurEtranger",
                    value: contract.typeDocumentTravailleurEtranger,
                },
                {
                    name: "heureDebutContract",
                    value: contract.heureDebutContract,
                },
            ]);
            setContract(newState);
            validate(newState);
        }
    };

    const initContract = () => {
        let newState = generateFields([
            { name: "closed", value: false },
            { name: "userId", value: employeeForEdit._id },
            {
                name: "dateDebutContract",
                value: null,
                touched: true,
            },
            {
                name: "dateExpireTravailleurEtranger",
                value: null,
            },
            {
                name: "dateFinContract",
                value: null,
                touched: true,
            },
            {
                name: "dateFinPerideEssai",
                value: null,
                touched: true,
            },
            {
                name: "derniereVisiteMedicale",
                value: null,
            },
            { name: "echelon", value: null },
            { name: "emploi", value: null },
            {
                name: "heureSupplementaire",
                value: null,
            },
            {
                name: "indemniteDeTransport",
                value: null,
            },
            {
                name: "joursParSemaine",
                value: null,
            },
            { name: "mutuelle", value: null },
            { name: "niveau", value: null },
            {
                name: "numDocumentTravailleurEtranger",
                value: null,
            },
            { name: "plusInterim", value: null },
            {
                name: "plusStagiaire",
                value: null,
            },
            {
                name: "qulification",
                value: null,
            },
            {
                name: "salaireBrutMensuel",
                value: null,
            },
            {
                name: "statusTravailleurEtranger",
                value: null,
            },
            {
                name: "tauxHoraireBrut",
                value: null,
            },
            {
                name: "tempsDeTravailHebdomadaire",
                value: null,
            },
            { name: "type", value: null },
            {
                name: "typeDocumentTravailleurEtranger",
                value: null,
            },
            {
                name: "heureDebutContract",
                value: "16:00",
            },
        ]);
        setContract(newState);
        validate(newState);
    };

    const openDeleteContractDialog = (contract: any) => {
        setContractDelete(contract);
    };

    const onHideAmendment = () => {
        setAmendment(null);
    };
    const openEditAmendmentDialog = (amendment: any) => {
        setAmendment(amendment || {});
    };

    const onSubmit = () => {
        if (contract) {
            const values = destructFields(contract);
            if (values._id) {
                console.log(" values = ", values);
                editContract({
                    ...values,
                    dateDebutContract: values.dateDebutContract
                        ? moment(values.dateDebutContract).toLocaleString()
                        : "",
                    dateFinPerideEssai:
                        values.dateFinPerideEssai &&
                        (values.type === "CDI" || values.type === "CDD")
                            ? moment(values.dateFinPerideEssai).toLocaleString()
                            : "",
                    dateFinContract: values.dateFinContract
                        ? moment(values.dateFinContract).toLocaleString()
                        : "",
                    derniereVisiteMedicale: values.derniereVisiteMedicale
                        ? moment(values.derniereVisiteMedicale).toLocaleString()
                        : "",
                    dateExpireTravailleurEtranger:
                        values.dateExpireTravailleurEtranger
                            ? moment(
                                  values.dateExpireTravailleurEtranger,
                              ).toLocaleString()
                            : "",
                    qulification:
                        values.qulification && values.qulification !== ""
                            ? values.qulification.value
                            : "",
                    plusInterim:
                        contract.type === "Intérim" ? values.plusInterim : "",
                    plusStagiaire:
                        values.type === "Stagiaire" ? values.plusStagiaire : "",
                    heureDebutContract: values.heureDebutContract,
                    salaireBrutMensuel: values.salaireBrutMensuel,
                } as ContractUpdateRequest).then((res) => {
                    const data: any = res.payload;
                    if (data.status === "success") {
                        addNotification({
                            title: intl.formatMessage({
                                id: "CONTRACT.EDIT.NOTIFICATION.SUCCESS.TITLE",
                            }),
                            message: intl.formatMessage({
                                id: "CONTRACT.EDIT.NOTIFICATION.SUCCESS.MESSAGE",
                            }),
                            type: "success",
                        });
                        getEmployee({ id: employeeForEdit._id });
                    } else if (data.status === "fail") {
                        addNotification({
                            title: intl.formatMessage({
                                id: "CONTRACT.EDIT.NOTIFICATION.FAIL.TITLE",
                            }),
                            message: intl.formatMessage({
                                id: "CONTRACT.EDIT.NOTIFICATION.FAIL.MESSAGE",
                            }),
                            type: "fail",
                        });
                    }
                });
            } else {
                addContract({
                    ...values,
                    dateDebutContract: values.dateDebutContract
                        ? moment(values.dateDebutContract).toLocaleString()
                        : "",
                    dateFinPerideEssai:
                        values.dateFinPerideEssai &&
                        (values.type === "CDI" || values.type === "CDD")
                            ? moment(values.dateFinPerideEssai).toLocaleString()
                            : "",
                    dateFinContract: values.dateFinContract
                        ? moment(values.dateFinContract).toLocaleString()
                        : "",
                    derniereVisiteMedicale: values.derniereVisiteMedicale
                        ? moment(values.derniereVisiteMedicale).toLocaleString()
                        : "",
                    dateExpireTravailleurEtranger:
                        values.dateExpireTravailleurEtranger
                            ? moment(
                                  values.dateExpireTravailleurEtranger,
                              ).toLocaleString()
                            : "",
                    qulification:
                        values.qulification && values.qulification !== ""
                            ? values.qulification.value
                            : "",
                    plusInterim:
                        contract.type === "Intérim" ? values.plusInterim : "",
                    plusStagiaire:
                        values.type === "Stagiaire" ? values.plusStagiaire : "",
                    heureDebutContract: values.heureDebutContract,
                    salaireBrutMensuel: values.salaireBrutMensuel,
                } as ContractCreateRequest).then((res) => {
                    const data: any = res.payload;
                    if (data.status === "success") {
                        addNotification({
                            title: intl.formatMessage({
                                id: "CONTRACT.CREATE.NOTIFICATION.SUCCESS.TITLE",
                            }),
                            message: intl.formatMessage({
                                id: "CONTRACT.CREATE.NOTIFICATION.SUCCESS.MESSAGE",
                            }),
                            type: "success",
                        });
                        getEmployee({ id: employeeForEdit._id });
                    } else if (data.status === "fail") {
                        addNotification({
                            title: intl.formatMessage({
                                id: "CONTRACT.CREATE.NOTIFICATION.FAIL.TITLE",
                            }),
                            message: intl.formatMessage({
                                id: "CONTRACT.CREATE.NOTIFICATION.FAIL.MESSAGE",
                            }),
                            type: "fail",
                        });
                    }
                });
            }
        }
    };

    React.useEffect(() => {
        if (employeeForEdit.contract) {
            initContractForEdit(employeeForEdit.contract);
        }
    }, []);
    const handleEditContract = () => {
        if (contract && employeeForEdit.contract) {
            return (
                <ContractEdit
                    contract={contract}
                    onChange={onChange}
                    onBlur={onBlur}
                    openDeleteContractDialog={openDeleteContractDialog}
                    onSubmit={onSubmit}
                    actionLoading={actionLoading}
                />
            );
        } else {
            return <NoContractsFound createNewContract={initContract} />;
        }
    };

    return (
        <>
            <EditAmendmentDialogPage
                show={amendment ? true : false}
                amendment={amendment}
                onHide={onHideAmendment}
            />
            {employeeForEdit && (
                <>
                    <DeleteContractDialog
                        show={contractDelete ? true : false}
                        contract={contractDelete}
                        onHide={() => {
                            setContractDelete(null);
                        }}
                    />
                    {handleEditContract()}
                    {/* {!employeeForEdit.contract._id && contract === undefined ? (
                        <NoContractsFound createNewContract={initContract} />
                    ) : (
                        <h1>Test</h1>
                        // <ContractEdit
                        //     contract={employeeForEdit.contract}
                        //     onChange={onChange}
                        //     onBlur={onBlur}
                        //     openDeleteContractDialog={openDeleteContractDialog}
                        //     onSubmit={onSubmit}
                        //     actionLoading={actionLoading}
                        // />
                    )} */}
                    {/* {!employeeForEdit.contract && !contract ? (
                        <NoContractsFound createNewContract={initContract} />
                    ) : employeeForEdit.contract && !contract ? (
                        <Contract
                            contracts={contracts}
                            contract={employeeForEdit.contract}
                            openEditContract={initContractForEdit}
                            openAddContract={initContract}
                            openDeleteContractDialog={openDeleteContractDialog}
                            openEditAmendmentDialog={openEditAmendmentDialog}
                        />
                    ) : (
                        <ContractEdit
                            contract={contract}
                            onChange={onChange}
                            onBlur={onBlur}
                            openDeleteContractDialog={openDeleteContractDialog}
                            onSubmit={onSubmit}
                            actionLoading={actionLoading}
                        />
                    )} */}
                </>
            )}
        </>
    );
}

interface Contract {
    [key: string]: any;
}
