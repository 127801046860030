/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { extractNumber } from "../../../_helpers/Functions";
import Input from "../../../components/atoms/Input";
import TextData from "../../../components/atoms/TextData";
import ToggleButton from "../../../components/atoms/Toggle";
import Ticket from "../../../components/atoms/Ticket";
import Swal from "sweetalert2";
import InputFile from "../../../components/atoms/InputFile";
import ContractTemplateWidget from "../../../components/atoms/ContractTemplateWidget";
import { ROUTER } from "../../../constants/env";
import { useDPAE } from "../../../hooks/useDPAE";
import { useContractTemplate } from "../../../hooks/useContractTemplate";
import Button from "../../../components/atoms/Button";
import Modal from "../../../components/atoms/Modal";
import PopUpCard from "../../../components/molecules/PopUpCard";
import NewContractTemplateCard from "../../../components/organism/NewContractTemplateCard";
import UploadContractTemplate from "../Elements/AdministrativeElements/UploadContractTemplate";
import { useAdministrative } from "../../../hooks/useAdministrative";
import {
    validateAPECode,
    validateDPAE,
    validateDesignation,
    validatePhoneNum,
    validateSiret,
    validateStreet,
    validateTown,
    validateURSSAFCode,
    validateZipCode,
    validateContractHealthServiceCode,
} from "../../../_helpers/DPAEValidation";
import { useBranch } from "../../../hooks/useBranch";
import { useCollectiveAgreement } from "../../../hooks/useCollectiveAgreement";
import SelectInput from "../../../components/atoms/SelectInput";

export const AdministrativeProps = () => {
    const {
        DPAE,
        getDPAEInformation,
        updateDPAEinformations,
        setSiret,
        setAPECode,
        setZipCode,
        setStreet,
        setDesignation,
        setPhoneNum,
        setUrssafCode,
        setCity,
        setHealthServiceCode,
        saveDPAEInfo,
    } = useDPAE();
    const { administrative, getAdministrative, setAdministrative } =
        useAdministrative();
    const { selectedBranchSettings } = useBranch();
    const {
        tags,
        contractTemplates,
        newContractTemplate,
        getContractTemplateTags,
        getContractTemplates,
    } = useContractTemplate();
    const {
        enableCollectiveAgreement,
        enforceCollectiveAgreement,
        getAllCollectiveAgreements,
        listOfCollectiveAgreements,
        selectedCollectiveAgreement,
        setSelectedCollectiveAgreement,

        setEnableCollectiveAgreement,
        setEnforceCollectiveAgreement,
    } = useCollectiveAgreement();
    const [displayedTab, setDisplayedTab] = React.useState([
        true,
        false,
        false,
    ]);
    const handleDisplayedTab = (index: number) => {
        switch (index) {
            case 0:
                setDisplayedTab([true, false, false]);
                break;
            case 1:
                setDisplayedTab([false, true, false]);
                break;
            case 2:
                setDisplayedTab([false, false, true]);
                break;
            default:
                setDisplayedTab([false, false, false]);
                break;
        }
    };
    React.useEffect(() => {
        getAdministrative();
        getDPAEInformation();
        getContractTemplateTags();
        getContractTemplates();
        getAllCollectiveAgreements();
    }, [selectedBranchSettings._id]);
    React.useEffect(() => {
        getContractTemplates();
    }, [newContractTemplate?.name, newContractTemplate?.type]);
    const InformationField = (elem: any) => {
        const { label, value, fieldName, error } = elem;
        let errorText = "";
        const handleChange = (fieldName: string, newValue: string) => {
            if (fieldName === "siret") {
                setSiret(newValue);
                if (error) {
                    errorText = "Le numéro SIRET est invalide";
                }
            } else if (fieldName === "apeCode") {
                setAPECode(newValue);
                if (error) {
                    errorText = "Le code APE(NAF) est invalide";
                }
            } else if (fieldName === "zipCode") {
                setZipCode(newValue);
                if (error) {
                    errorText = "Le code postal est invalide";
                }
            } else if (fieldName === "address") {
                setStreet(newValue);
                if (error) {
                    errorText = "L'adresse est invalide";
                }
            } else if (fieldName === "enseigne") {
                setDesignation(newValue);
                if (error) {
                    errorText = "La désignation est invalide";
                }
            } else if (fieldName === "phoneNum") {
                setPhoneNum(newValue);
                if (error) {
                    errorText = "Le numéro de téléphone est invalide";
                }
            } else if (fieldName === "urssafCode") {
                setUrssafCode(newValue);
                if (error) {
                    errorText = "Le code URSSAF est invalide";
                }
            } else if (fieldName === "ville") {
                setCity(newValue);
                if (error) {
                    errorText = "La ville est invalide";
                }
            } else if (fieldName === "healthServiceCode") {
                setHealthServiceCode(newValue);
                if (error) {
                    errorText = "Le code du service de santé est invalide";
                }
            }
        };
        const errorMessageAlert = () => {
            if (error) {
                if (fieldName === "siret") {
                    errorText = "Le numéro SIRET est invalide";
                    return errorText;
                } else if (fieldName === "apeCode") {
                    errorText = "Le code APE(NAF) est invalide";
                    return errorText;
                } else if (fieldName === "zipCode") {
                    errorText = "Le code postal est invalide";
                    return errorText;
                } else if (fieldName === "address") {
                    errorText = "L'adresse est invalide";
                    return errorText;
                } else if (fieldName === "enseigne") {
                    errorText = "La désignation est invalide";
                    return errorText;
                } else if (fieldName === "phoneNum") {
                    errorText = "Le numéro de téléphone est invalide";
                    return errorText;
                } else if (fieldName === "urssafCode") {
                    errorText = "Le code URSSAF est invalide";
                    return errorText;
                } else if (fieldName === "ville") {
                    errorText = "La ville est invalide";
                    return errorText;
                } else if (fieldName === "healthServiceCode") {
                    errorText = "Le code du service de santé est invalide";
                    return errorText;
                }
            }
        };
        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    flexDirection: "column",
                }}
            >
                <TextData
                    variant="label"
                    width={undefined}
                    // className={["title"]}
                    size="14px"
                    style={{
                        color: "#6f78ab",
                    }}
                >
                    {label}:
                </TextData>
                <Input
                    type="text"
                    captionText=""
                    placeholder=""
                    onChange={(data?: any) => {
                        handleChange(fieldName, data);
                    }}
                    borderWidth={1}
                    borderColor="#6f78ab"
                    textSize={14}
                    textColor="#555"
                    inputRadius="4px"
                    inputWidth="200px"
                    style={{
                        inputStyle: {
                            marginRight: "10px",
                        },
                    }}
                    value={value}
                    errorIsActivated={error}
                    errorText={errorMessageAlert()}
                    errorColor="#7D2828"
                    errorSize={10}
                />
            </div>
        );
    };
    const [dpaeStatus, setdpaeStatus] = React.useState({
        siret: false,
        enseigne: false,
        apeCode: false,
        urssafCode: false,
        zipCode: false,
        phoneNum: false,
        address: false,
        ville: false,
        healthServiceCode: false,
    });
    React.useEffect(() => {
        if (DPAE) {
            let dpaeState = {
                siret: false,
                enseigne: false,
                apeCode: false,
                urssafCode: false,
                zipCode: false,
                phoneNum: false,
                address: false,
                ville: false,
                healthServiceCode: false,
            };
            dpaeState.siret = !validateDPAE(validateSiret, DPAE.siret);
            dpaeState.enseigne = !validateDPAE(
                validateDesignation,
                DPAE.enseigne,
            );
            dpaeState.apeCode = !validateDPAE(validateAPECode, DPAE.apeCode);
            dpaeState.urssafCode = !validateDPAE(
                validateURSSAFCode,
                DPAE.urssafCode,
            );
            dpaeState.zipCode = !validateDPAE(validateZipCode, DPAE.zipCode);
            dpaeState.phoneNum = !validateDPAE(validatePhoneNum, DPAE.phoneNum);
            dpaeState.address = !validateDPAE(validateStreet, DPAE.address);
            dpaeState.ville = !validateDPAE(validateTown, DPAE.ville);
            dpaeState.healthServiceCode = !validateDPAE(
                validateContractHealthServiceCode,
                DPAE.healthServiceCode,
            );

            setdpaeStatus(dpaeState);
        }
    }, [DPAE]);

    const InformationCardDPAE = () => {
        return (
            <>
                <div className="information-card-dpae-box">
                    <div style={{ display: "flex", width: "100%" }}>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns:
                                    "repeat(auto-fill, minmax(300px, 1fr))",
                                gap: "20px",
                                width: "100%",
                            }}
                        >
                            {InformationField({
                                label: "Désignation",
                                fieldName: "enseigne",
                                value: DPAE?.enseigne,
                                error: dpaeStatus.enseigne,
                            })}

                            {InformationField({
                                label: "Numéro de siret",
                                fieldName: "siret",
                                value: DPAE?.siret,
                                error: dpaeStatus.siret,
                            })}
                            {InformationField({
                                label: "Numéro de téléphone",
                                fieldName: "phoneNum",
                                value: DPAE?.phoneNum,
                                error: dpaeStatus.phoneNum,
                            })}
                        </div>
                        <div className="elementContainer">
                            {InformationField({
                                label: "Adresse",
                                fieldName: "address",
                                value: DPAE?.address,
                                error: dpaeStatus.address,
                            })}
                            {InformationField({
                                label: "Code postal",
                                fieldName: "zipCode",
                                value: DPAE?.zipCode,
                                error: dpaeStatus.zipCode,
                            })}
                            {InformationField({
                                label: "Ville",
                                fieldName: "ville",
                                value: DPAE?.ville,
                                error: dpaeStatus.ville,
                            })}
                        </div>
                        <div className="elementContainer">
                            {InformationField({
                                label: "Code APE",
                                fieldName: "apeCode",
                                value: DPAE?.apeCode,
                                error: dpaeStatus.apeCode,
                            })}
                            {InformationField({
                                label: "Code URSSAF",
                                fieldName: "urssafCode",
                                value: DPAE?.urssafCode,
                                error: dpaeStatus.urssafCode,
                            })}
                            {InformationField({
                                label: "Service de santé du travail",
                                fieldName: "healthServiceCode",
                                value: DPAE?.healthServiceCode,
                                error: dpaeStatus.healthServiceCode,
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const DPAE_options = () => {
        if (displayedTab[0]) {
            if (administrative.enableDPAE) {
                return [
                    {
                        optionTitle:
                            "Réaliser la déclaration préalable à l'embauche (DPAE)",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Un bouton sera disponible sur le dossier de chaque employé pour effectuer automatiquement la déclaration préalable à l’embauche (DPAE). ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    setAdministrative({ enableDPAE: false })
                                }
                                isChecked={administrative.enableDPAE}
                            />
                        ),
                        optionInput2: InformationCardDPAE(),
                    },
                ];
            } else {
                return [
                    {
                        optionTitle:
                            "Réaliser la déclaration préalable à l'embauche (DPAE)",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Un bouton sera disponible sur le dossier de chaque employé pour effectuer automatiquement la déclaration préalable à l’embauche (DPAE). ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    setAdministrative({ enableDPAE: true })
                                }
                                isChecked={administrative.enableDPAE}
                            />
                        ),
                    },
                ];
            }
        } else {
            return [];
        }
    };

    const ListOfTemplates = () => {
        return (
            <div className="list-of-templates">
                {contractTemplates &&
                    contractTemplates.map((template: any) => (
                        <ContractTemplateWidget
                            key={template.id}
                            id={template.id}
                            title={template.name}
                            type={template.type}
                            fileType={template.fileType}
                            tags={template.fields}
                            url={template.url}
                        />
                    ))}
            </div>
        );
    };

    const Contract_options = () => {
        if (displayedTab[1]) {
            if (administrative.enableContractTemplates) {
                return [
                    {
                        optionTitle:
                            "Activer la génération des contrats de travail",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Un bouton sera disponible sur le dossier de chaque employé pour générer automatiquement le contrat en respectant le modèle fourni au préalable. ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    setAdministrative({
                                        enableContractTemplates: false,
                                    })
                                }
                                isChecked={
                                    administrative.enableContractTemplates
                                }
                            />
                        ),
                    },
                    {
                        optionTitle: "Liste des tags",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Consultez la liste des balises disponibles à utiliser lors de la génération automatique des contrats pour personnaliser les informations spécifiques à chaque employé. ",
                        optionInput: null,
                        optionInput2: (
                            <div className="tags-list">
                                {tags &&
                                    tags.map((elem: any) => {
                                        return (
                                            <Ticket
                                                showEdit={false}
                                                showClose={false}
                                                handleClick={() => {
                                                    navigator.clipboard
                                                        .writeText(elem.tag)
                                                        .then(() => {
                                                            Swal.fire({
                                                                icon: "success",
                                                                title: "Copied!",
                                                                text: "Text copied to clipboard",
                                                                showConfirmButton:
                                                                    false,
                                                                timer: 700,
                                                            });
                                                        });
                                                }}
                                                text={elem.designation}
                                                style={{
                                                    container: {
                                                        margin: "0 5px",
                                                        marginBottom: "10px",
                                                    },
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        ),
                    },
                    {
                        optionTitle: "Importer un nouveau modèle de contrat",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Permet de télécharger un nouveau modèle de contrat pour être utilisé lors de la génération automatique des contrats pour les employés. ",
                        optionInput: <UploadContractTemplate />,
                    },
                    {
                        optionTitle: "Liste des modèles de contrat de travail",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Consultez la liste des modèles de contrat que vous avez préalablement téléchargés et qui sont disponibles pour la génération automatique des contrats pour les employés. ",
                        optionInput: null,
                        optionInput2: ListOfTemplates(),
                    },
                ];
            } else {
                return [
                    {
                        optionTitle:
                            "Activer la génération des contrats de travail",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    setAdministrative({
                                        enableContractTemplates: true,
                                    })
                                }
                                isChecked={
                                    administrative.enableContractTemplates
                                }
                            />
                        ),
                    },
                ];
            }
        } else {
            return [];
        }
    };

    const handleSelectedCollectiveAgreement = (selectedOption: any) => {
        setSelectedCollectiveAgreement(selectedOption.value);
    };
    const ChooseCollectiveAgreement = () => {
        let output = null;
        if (selectedCollectiveAgreement) {
            if (listOfCollectiveAgreements.length > 0) {
                output = (
                    <div className="collective-agreement-list-container">
                        <SelectInput
                            options={listOfCollectiveAgreements.map(
                                (collectiveAgreement: any) => {
                                    return {
                                        label: collectiveAgreement.designation,
                                        value: collectiveAgreement._id,
                                    };
                                },
                            )}
                            selectedValue={{
                                label: selectedCollectiveAgreement.designation,
                                value: selectedCollectiveAgreement._id,
                            }}
                            style={{ width: "auto" }}
                            title="Les Conventions Collective Disponibles"
                            onSelectOption={handleSelectedCollectiveAgreement}
                        />
                    </div>
                );
            }
            return (
                <div className="collective-agreement-container">
                    {output}
                    <div className="selected-collective-agreement-container">
                        <div className="collective-agreement-item-container">
                            <TextData
                                fontWeight="700"
                                size="22px"
                                color="#2A346D"
                            >
                                Convention :
                            </TextData>
                            <TextData
                                fontWeight="700"
                                size="22px"
                                style={{
                                    marginLeft: "5px",
                                }}
                                color="#2A346D"
                            >
                                {selectedCollectiveAgreement.designation}
                            </TextData>
                        </div>
                        <div className="collective-agreement-item-container">
                            <div className="item-header">
                                <TextData size="14px" fontWeight="700">
                                    Temps de travail quotidien maximal
                                </TextData>
                                <TextData size="12px" color="#2a346d">
                                    Un employé ne peut normalement pas
                                    travailler plus de
                                    {` ${selectedCollectiveAgreement.max_daily_work_hours}`}
                                    h dans une journée. Il existe des cas
                                    particuliers : dans le cas de la modulation
                                    du temps de travail par exemple.
                                </TextData>
                            </div>
                            <TextData fontWeight="700" color="#2A346D">
                                {
                                    selectedCollectiveAgreement.max_daily_work_hours
                                }
                            </TextData>
                        </div>
                        <div className="collective-agreement-item-container">
                            <div className="item-header">
                                <TextData size="14px" fontWeight="700">
                                    Temps de travail maximal sans pause
                                </TextData>
                                <TextData size="12px" color="#2a346d">
                                    Pensez à intégrer une pause dans les shifts
                                    longs.
                                </TextData>
                            </div>
                            <TextData fontWeight="700" color="#2A346D">
                                {
                                    selectedCollectiveAgreement.max_consecutive_work_hours_without_rest
                                }
                            </TextData>
                        </div>
                        <div className="collective-agreement-item-container">
                            <div className="item-header">
                                <TextData size="14px" fontWeight="700">
                                    Repos consécutif entre deux journées
                                </TextData>
                                <TextData size="12px" color="#2a346d">
                                    Si un salarié termine son travail à 23h, il
                                    ne peut pas reprendre le lendemain avant
                                    {` ${
                                        selectedCollectiveAgreement.min_consecutive_rest_hours -
                                        1
                                    }`}
                                    h.
                                </TextData>
                            </div>
                            <TextData fontWeight="700" color="#2A346D">
                                {
                                    selectedCollectiveAgreement.min_consecutive_rest_hours
                                }
                            </TextData>
                        </div>
                        <div className="collective-agreement-item-container">
                            <div className="item-header">
                                <TextData size="14px" fontWeight="700">
                                    Jours consécutifs de travail maximal
                                </TextData>
                                <TextData size="12px" color="#2a346d">
                                    Un salarié ne peut pas travailler plus de
                                    {` ${selectedCollectiveAgreement.max_consecutive_work_days} `}
                                    jours consécutifs.
                                </TextData>
                            </div>
                            <TextData fontWeight="700" color="#2A346D">
                                {
                                    selectedCollectiveAgreement.max_consecutive_work_days
                                }
                            </TextData>
                        </div>
                        <div className="collective-agreement-item-container">
                            <div className="item-header">
                                <TextData size="14px" fontWeight="700">
                                    Temps de travail hebdomadaire maximal
                                </TextData>
                                <TextData size="12px" color="#2a346d">
                                    Un salarié ne peut pas travailler plus de
                                    {` ${selectedCollectiveAgreement.max_weekly_work_hours}`}
                                    h dans la semaine.
                                </TextData>
                            </div>
                            <TextData fontWeight="700" color="#2A346D">
                                {
                                    selectedCollectiveAgreement.max_weekly_work_hours
                                }
                            </TextData>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    };

    const ConventionCollective_options = () => {
        if (displayedTab[2]) {
            if (enableCollectiveAgreement) {
                return [
                    {
                        optionTitle: "Activer la convention collective",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Activez cette option pour exiger que le manager respecte les règles de la convention collective avant de publier un planning. ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    setEnableCollectiveAgreement(
                                        !enableCollectiveAgreement,
                                    )
                                }
                                isChecked={enableCollectiveAgreement}
                            />
                        ),
                    },
                    {
                        optionTitle: "Enforcer la convention collective",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    setEnforceCollectiveAgreement(
                                        !enforceCollectiveAgreement,
                                    )
                                }
                                isChecked={enforceCollectiveAgreement}
                            />
                        ),
                    },
                    {
                        optionTitle: "",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription: "",
                        optionInput: null,
                        optionInput2: <ChooseCollectiveAgreement />,
                    },
                ];
            } else {
                return [
                    {
                        optionTitle: "Activer la convention collective",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Activez cette option pour exiger que le manager respecte les règles de la convention collective avant de publier un planning.",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    setEnableCollectiveAgreement(
                                        !enableCollectiveAgreement,
                                    )
                                }
                                isChecked={enableCollectiveAgreement}
                            />
                        ),
                    },
                ];
            }
        } else {
            return null;
        }
    };

    return [
        {
            groupOnClick: () => {
                handleDisplayedTab(0);
            },
            headerTitle: "DPAE",
            headerDescription:
                "Cette formalité est obligatoire pour chaque salarié que vous avez l'intention de recruter. Elle doit être réalisée dans un délai de 8 jours avant l'embauche.",
            configOptions: DPAE_options(),
        },
        {
            groupOnClick: () => {
                handleDisplayedTab(1);
            },
            headerTitle: "Activer l'option contrat ",
            headerDescription:
                "Un bouton sera disponible sur le dossier de chaque employé pour générer automatiquement le contrat en respectant le modèle fourni au préalable. ",
            configOptions: Contract_options(),
        },
        {
            groupOnClick: () => {
                handleDisplayedTab(2);
            },
            headerTitle: "La Convention Collective",
            headerDescription:
                "désigne un accord conclu entre les représentants des employeurs et des salariés d’un secteur d’activité donné. Elle fixe les conditions de travail et les droits des salariés au sein de ce secteur ",
            configOptions: ConventionCollective_options(),
        },
    ];
};
