import React from "react";
import Select from "react-select";
import Theme from "../../../tailwind-theme";
import { ChevronDown, Close, User } from "../../../Icons";
import { FormattedMessage } from "react-intl";

const DownChevron = () => (
    <div>
        <ChevronDown color={Theme.colors["n-500"]} />
    </div>
);
const UpChevron = () => (
    <div className="rotate-180">
        <ChevronDown color={Theme.colors["n-500"]} />
    </div>
);

export default function SelectInput({
    options = [],
    value = null,
    isMulti = false,
    placeholder,
    onChange,
    controlCssStyles = {},
    error,
    touched,
    onBlur,
    containerClasses = "",
    isDisabled = false,
    menuPlacement = "bottom",
}: SelectInputProps) {
    const divRef = React.useRef<HTMLDivElement>(null);
    const selectRef = React.useRef<any>(null);
    const getParentElement = (node: HTMLElement): HTMLElement | null => {
        while (node.parentNode) {
            if (node.classList.contains("modalBody")) {
                return node;
            }
            node = node.parentNode as HTMLElement;
        }
        return null;
    };

    const [yPos, setYPos] = React.useState(0);

    React.useEffect(() => {
        if (divRef.current) {
            const handleScroll = (
                target: HTMLElement | null = divRef.current,
            ) => {
                if (target) {
                    let y =
                        divRef.current?.offsetTop! -
                        (target.scrollTop ? target.scrollTop : 0);
                    setYPos(y || 0);
                }
            };

            const parentRef = getParentElement(divRef.current);
            if (parentRef) {
                parentRef.addEventListener("scroll", () => {
                    handleScroll(parentRef);
                });
            } else {
                window.addEventListener("scroll", () => {
                    if (divRef.current) {
                        let top = divRef.current!?.getBoundingClientRect().top!;
                        setYPos(top);
                    } else {
                        let top = 0;
                        setYPos(top);
                    }
                });
            }
        }
        const y = divRef.current?.offsetTop;
        setYPos(y || 0);
    }, [divRef]);

    const getMenuPlacement = (): number => {
        const windowHeight = window.innerHeight;
        const spaceAbove = divRef.current!.getBoundingClientRect().top!;
        const spaceBelow =
            windowHeight - divRef.current!.getBoundingClientRect().bottom!;
        if (
            spaceBelow >= divRef.current!.getBoundingClientRect().height ||
            spaceAbove < divRef.current!.getBoundingClientRect().height
        ) {
            return yPos + divRef.current!.getBoundingClientRect().height!;
        } else {
            return yPos - 250;
        }
    };

    const [isOpen, setIsOpen] = React.useState(false);
    const customStyles: any = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderRadius: "8px",
            borderColor:
                touched && error && error !== ""
                    ? Theme.colors["error"]
                    : state.isFocused
                      ? Theme.colors["n-400"]
                      : Theme.colors["n-200"],
            boxShadow: "none",
            "&:hover": {
                borderColor: state.isFocused
                    ? Theme.colors["n-400"]
                    : Theme.colors["n-200"],
            },
            ...controlCssStyles,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? Theme.colors["n-0"] : "white",
            color: Theme.colors["n-700"],
            fontWeight: "500",
            fontSize: "12px",
            cursor: "pointer",
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: Theme.colors["n-700"],
            fontWeight: "500",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: Theme.colors["n-700"],
            fontWeight: "500",
            fontSize: "12px",
        }),
        multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: Theme.colors["n-400"],
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            color: "white",
        }),
        // menu: (provided: any) => ({
        //     ...provided,
        //     marginTop: "0px", // Adjust the top margin as needed
        //     zIndex: 9999,
        //     position: "fixed",
        //     width: divRef.current!.getBoundingClientRect().width!,
        //     top: getMenuPlacement(),
        // }),
    };

    return (
        <div ref={divRef} className={containerClasses}>
            <Select
                maxMenuHeight={250}
                ref={selectRef}
                isDisabled={isDisabled}
                className="_select-input"
                isMulti={isMulti}
                options={options}
                noOptionsMessage={() => "Aucune option disponible"}
                value={value}
                styles={customStyles}
                formatOptionLabel={(option) => (
                    <div className="flex items-center gap-1">
                        {option.icon} {option.label}
                    </div>
                )}
                menuPlacement={menuPlacement}
                components={{
                    DropdownIndicator: isOpen ? UpChevron : DownChevron,
                    IndicatorSeparator: null,
                    Option: ({ data, innerProps, selectProps, ...props }) => {
                        const { label, icon } = data;
                        return (
                            <div
                                {...innerProps}
                                className="flex items-center gap-1 py-[4px] min-h-[30px] px-[10px] cursor-pointer hover:bg-n-0 text-n-700 font-medium  text-base  "
                            >
                                {icon ? (
                                    <div className="min-w-[24px]">{icon}</div>
                                ) : (
                                    <></>
                                )}
                                {label}
                            </div>
                        );
                    },
                    MultiValueRemove: ({ innerProps }) => {
                        return (
                            <div {...innerProps} className="px-1">
                                <Close width={12} height={12} color="white" />
                            </div>
                        );
                    },
                    ClearIndicator: ({ innerProps }) => {
                        return (
                            <div {...innerProps}>
                                <Close width={12} height={12} />
                            </div>
                        );
                    },
                }}
                onMenuOpen={() => setIsOpen(true)}
                onMenuClose={() => setIsOpen(false)}
                placeholder={placeholder}
                onChange={(val) => onChange && onChange(val)}
                onBlur={() => {
                    onBlur && onBlur();
                }}
            />
            <div>
                {touched && error && (
                    <span className="text-error text-sm font-light w-full max-w-[20px]">
                        {error}
                    </span>
                )}
            </div>
        </div>
    );
}

interface Option {
    label: string | React.ReactNode;
    value: any;
    icon?: React.ReactNode;
}

interface SelectInputProps {
    options?: Option[];
    value?: any;
    isMulti?: boolean;
    placeholder?: string | React.ReactNode;
    onChange?: (val: any) => void;
    onBlur?: () => void;
    controlCssStyles?: React.CSSProperties;
    error?: string;
    touched?: boolean;
    containerClasses?: string;
    isDisabled?: boolean;
    menuPlacement?: "top" | "bottom";
}
