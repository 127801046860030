import Button from "../../atoms/ZButton";
import DatepickerInput from "../../atoms/DatepickerInput";
import SelectInput from "../../molecules/SelectInput";
import { useHoliday } from "../../../hooks/useHolidays";
import PopUpCard from "../../molecules/PopUpCard";
import Modal from "../../atoms/Modal";
import Loading from "../../atoms/Loading";
import { ROUTER } from "../../../constants/env";
import TextData from "../../atoms/TextData";
import TimingComponent from "../../molecules/TimingComponent";
import moment from "moment";
import React from "react";
import { calculateDays } from "../../../_helpers/Functions";
import ProfilePicture from "../../molecules/ProfilePicture";
import { generatePicUrl } from "../../../_helpers/PictureHelpers";

const UserInfo = ({
    employee,
    createdAt,
}: {
    employee: any;
    createdAt: any;
}) => {
    const pictureProps = {
        imgPath: ROUTER.STATIC_IMG(employee?.picture),
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };
    return (
        <div
            style={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
                margin: "0 20px",
                padding: "5px",
            }}
        >
            <TextData
                variant="label"
                width="auto"
                size="22px"
                fontWeight="700"
                color="#2A346D"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "fit-content",
                        height: "fit-content",
                    }}
                >
                    <ProfilePicture
                        profileImage={
                            employee?.picture &&
                            generatePicUrl(employee?.picture)
                        }
                        firstName={employee?.fname || " "}
                        lastName={employee?.lname || " "}
                        pictureProps={pictureProps}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div>
                        {employee?.fname} {employee?.lname}
                    </div>
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "400",
                        }}
                    >
                        Ajouté le{" "}
                        {moment(createdAt, "YYYY-MM-DD")
                            .locale("fr")
                            .format("DD/MM/YYYY")}
                    </div>
                </div>
            </TextData>
        </div>
    );
};

const UpdateCongeCard = ({
    onOpen,
    onChange,
    onBlur,
    congeData,
    congeTypes,
    onSubmit,
    onReset,
    conge,
}: UpdateCongeCardProps) => {
    const { loading } = useHoliday();
    const [days, setDays] = React.useState<any>();
    React.useEffect(() => {
        if (conge) {
            const calculatedDays = calculateDays(
                conge?.fromDay,
                conge?.fromHour,
                conge?.toDay,
                conge?.toHour,
            );
            setDays(calculatedDays);
        }
    }, [conge]);
    const checkError = () => {
        let error = false;
        Object?.keys(congeData)?.forEach((key) => {
            if (congeData[key]?.hasError) {
                error = true;
            }
        });
        return error;
    };

    const handleSubmit = () => {
        if (!checkError()) {
            onSubmit();
        }
    };
    if (!onOpen) {
        return null;
    }

    return (
        <div>
            <Modal modalEnabled={onOpen} onClose={onReset}>
                <div className="px-12">
                    <PopUpCard
                        containerWidth={"560"}
                        display={onOpen}
                        header={"Modifier la demande de congé"}
                        onClose={onReset}
                        isCloseIcon={true}
                        activateFooter={false}
                    >
                        {loading ? (
                            <div style={{ height: 400 }}>
                                <Loading width="400px" height="200px" />
                            </div>
                        ) : (
                            <div className="w-full  min-h-[470px]">
                                <div className="emp-selection-container px-6 flex flex-col gap-4 mb-5 w-full">
                                    <UserInfo
                                        employee={conge?.user}
                                        createdAt={conge?.created_at}
                                    />
                                    <div className="flex justify-center w-full">
                                        <TimingComponent
                                            startTime={moment(
                                                conge?.fromDay,
                                            )?.format("DD/MM/YY")}
                                            endTime={moment(
                                                conge?.toDay,
                                            )?.format("DD/MM/YY")}
                                            hasPause={days}
                                            dateType={
                                                days > 1 ? " jours" : " jour"
                                            }
                                        />
                                    </div>
                                    <div className="">
                                        <div>
                                            <label className="text-n-400 font-medium text-base">
                                                Type
                                            </label>
                                            <span className="text-error font-semibold">
                                                *
                                            </span>
                                        </div>

                                        <SelectInput
                                            placeholder="Sélectionnner le type du congé"
                                            controlCssStyles={{
                                                height: "48px",
                                            }}
                                            options={congeTypes}
                                            onChange={(option) => {
                                                onChange(
                                                    "typeConge",
                                                    option.value,
                                                );
                                            }}
                                            value={congeTypes.find(
                                                (option: any) =>
                                                    option.value ===
                                                    congeData?.typeConge?.value,
                                            )}
                                            onBlur={() => {
                                                onBlur("typeConge");
                                            }}
                                            touched={
                                                congeData?.typeConge?.touched
                                            }
                                            error={congeData?.typeConge?.error}
                                        />
                                    </div>

                                    <DatepickerInput
                                        required={true}
                                        label="À partir de"
                                        field={congeData?.startDate}
                                        onUpdate={(value) => {
                                            onChange("startDate", value);
                                        }}
                                    />
                                    <DatepickerInput
                                        required={true}
                                        label="Jusqu’au"
                                        field={congeData?.endDate}
                                        onUpdate={(value) => {
                                            onChange("endDate", value);
                                        }}
                                    />
                                </div>
                                <div className="flex justify-end space-x-2 border border-t-[1px] rounded-b-[20px] p-4">
                                    <Button
                                        title={"Annuler"}
                                        onClick={onReset}
                                    />
                                    <Button
                                        loading={loading}
                                        variant="primary"
                                        title={"Modifier"}
                                        disabled={checkError()}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        )}
                    </PopUpCard>
                </div>
            </Modal>
        </div>
    );
};

interface UpdateCongeCardProps {
    congeData: any;
    onOpen?: boolean;
    congeTypes: any;
    conge: any;
    onClose?: () => void;
    onSubmit: () => void;
    onReset: () => void;
    onChange: (field: string, value: any) => void;
    onBlur: (field: string) => void;
}

export default UpdateCongeCard;
