import * as React from "react";
import moment, { Moment } from "moment";

import "./style.scss";
import { ReactComponent as ArrowsSVG } from "./arrows.svg";
import { ReactComponent as CopySVG } from "./copy.svg";
import { ReactComponent as TrashSVG } from "./trash.svg";
import { ReactComponent as EditSVG } from "./edit.svg";
import { ReactComponent as ChevronSVG } from "./Chevron.svg";

import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import Picture from "../../atoms/Picture";
import Calendar from "../../organism/Calendar";
import ReportDisplayOption from "../../molecules/ReportDisplayOption";

import {
    getCurrentWeekNumberAndDays,
    getPlanningCustomFormattedDate,
    getPlanningCustomFormattedWeekNumberAndYear,
    convertMinutesToHours,
    formatTime,
    fomatStartEndTime,
} from "../../../_helpers/Functions";
import CustomOrderCard from "../../organism/CustomOrderCard";
import DuplicatePlanningCard from "../../organism/DuplicatePlanningCard";
import PlanningDeletionCard from "../../organism/PlanningDeletionCard";
import PlanningTableConfiguration from "../../molecules/PlanningTableConfiguration";

import { usePlanning } from "../../../hooks/usePlanning";
export default function PlanningTableHeader(props: any) {
    const {
        orderClick,
        setOrderClick,

        period,
        setPeriod,

        startCellHour,
        numberOfHourCellsInDay,
        selectedDay,
        setSelectedDay,
        isFullScreen,
    } = props;
    const {
        planningOptions,
        planningLoading,
        planningStartOfWeek,
        getPlanningOptionsFromLocalStorage,
        setHideNotes,
        setHideAlerts,
        setHideAbsences,
        setHideDisponibility,
        setHidePlansEmployees,
        setHideNoPlansEmployees,
        setHideTotalTimeDepartment,
        setHideFixedShifts,

        setIsPlanningLoading,
    } = usePlanning();

    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const [field, setDateField] = React.useState<any>({
        name: "date",
        value: moment(),
    });
    const [stepDay, setStep] = React.useState(0);
    const { week, year, setPlanningProperties } = usePlanning();
    const [TheWeekDays, setWeekDays] = React.useState<string[]>([]);

    React.useEffect(() => {
        const fieldVal = field.value.format("YYYY-MM-DD");
        if (selectedDay != fieldVal) {
            setSelectedDay(field.value.format("YYYY-MM-DD"));
        }
    }, [field]);
    const changeTheWeek = (dateValue: any = moment()) => {
        const { weekNumber, weekDays } = getCurrentWeekNumberAndDays(
            dateValue.add(stepDay, "weeks"),
            [7],
        );
        setPlanningProperties({
            week: weekNumber,

            startOfWeek: weekDays[0],
            endOfWeek: weekDays[6],
        });
        setWeekDays(weekDays);
    };
    React.useEffect(() => {
        changeTheWeek();
    }, [stepDay]);
    const handleOptionActions: any = [
        setHideNotes,
        setHideAlerts,
        setHideAbsences,
        setHideDisponibility,
        setHidePlansEmployees,
        setHideNoPlansEmployees,
        setHideTotalTimeDepartment,
        setHideFixedShifts,
    ];
    React.useEffect(() => {
        getPlanningOptionsFromLocalStorage();
    }, []);

    const [options, setOptions] = React.useState([
        {
            text: "Cacher les notes",
            checked: false,
        },
        {
            text: "Cacher les alertes",
            checked: false,
        },
        {
            text: "Cacher les absences",
            checked: false,
        },
        {
            text: "Cacher les indisponibilités",
            checked: false,
        },
        {
            text: "Cacher les employés planifiés",
            checked: false,
        },
        {
            text: "Cacher les employés non-planifiés",
            checked: false,
        },
        {
            text: "Cacher le total des heurs par département",
            checked: false,
        },
        {
            text: "Cacher les shifts prédéfinis",
            checked: false,
        },
    ]);
    React.useEffect(() => {
        setOptions((prev) => {
            const updatedOptions = [...prev];
            updatedOptions[0].checked = planningOptions.hideNotes || false;
            updatedOptions[1].checked = planningOptions.hideAlerts || false;
            updatedOptions[2].checked = planningOptions.hideAbsences || false;
            updatedOptions[3].checked =
                planningOptions.hideDisponibility || false;
            updatedOptions[4].checked =
                planningOptions.hidePlansEmployees || false;
            updatedOptions[5].checked =
                planningOptions.hideNoPlansEmployees || false;
            updatedOptions[6].checked =
                planningOptions.hideTotalTimeDepartment || false;
            updatedOptions[7].checked =
                planningOptions.hideFixedShifts || false;
            return updatedOptions;
        });
    }, [planningOptions]);
    const handleOptionChange = (selectedOption: any) => {
        setOptions((prevOptions) => {
            const newOptions = prevOptions.map((option, index) => {
                if (option.text === selectedOption.text) {
                    handleOptionActions[index](selectedOption.checked);
                    return {
                        ...option,
                        checked: selectedOption.checked,
                    };
                }
                return option;
            });
            return newOptions;
        });
    };
    const widthPercentage = 15;
    const [thisTimeByMinutes, setThisTimeByMinutes] = React.useState(
        moment().minutes() + moment().hours() * 60,
    );
    React.useEffect(() => {
        const updateInterval = setInterval(() => {
            if (period === "day") {
                setThisTimeByMinutes(
                    moment().minutes() +
                        moment().hours() * 60 +
                        Math.trunc(moment().seconds() / 60),
                );
            }
        }, 60000);
        return () => clearInterval(updateInterval);
    }, [period]);

    //stepDirection : -1 OR 1
    const handleStep = (stepDirection: number) => {
        if (period === "week") {
            setIsPlanningLoading(true);

            const val = moment(field.value).add(stepDirection, "weeks");
            setStep((prev) => prev + stepDirection);
            setDateField((prev: any) => ({
                ...prev,
                value: val,
            }));
        } else {
            const val = moment(field.value).add(stepDirection, "day");
            const clonedVal = val.clone();
            const stepWeek = clonedVal
                .startOf("week")
                .diff(moment().startOf("week"), "week");
            if (stepWeek !== stepDay) {
                setIsPlanningLoading(true);
                setStep(stepWeek);
            }
            setDateField((prev: any) => ({
                ...prev,
                value: val,
            }));
        }
    };
    return (
        <>
            <tr className="table-header-container">
                <th
                    rowSpan={2}
                    style={{
                        width: `${widthPercentage}%`,
                        borderTopLeftRadius: "12px",
                    }}
                >
                    <div className="header-emp">
                        <div onClick={() => setOrderClick(true)}>
                            <ArrowsSVG />
                            <TextData
                                variant="regular"
                                color="#2A346D"
                                width="90px"
                                size="16px"
                                fontWeight="700"
                                style={{ textAlign: "left" }}
                            >
                                Tri et ordre personnalisé
                            </TextData>
                        </div>
                    </div>
                </th>
                <th
                    colSpan={7}
                    className={period === "day" ? "without-border-b" : ""}
                    style={{
                        maxWidth: `calc(85% - ${widthPercentage}%)`,
                        width: `calc(85% - ${widthPercentage}%)`,
                    }}
                >
                    <div className="header-date">
                        <div className="action-buttons">
                            {/* {planning?.planningInfo !== null && (
                                <Button
                                    backgroundColor='#fff'
                                    width='auto'
                                    padding='8px 16px'
                                    title='Supprimer'
                                    style={{
                                        borderRadius: "28px",
                                        boxShadow:
                                            "1px 1px 4px 0px rgba(17, 16, 110, 0.10)",
                                        marginRight: "18px",
                                    }}
                                    onClick={() => setOpenDelete(true)}
                                >
                                    <TrashSVG />
                                </Button>
                            )}
                            {planning?.planningInfo?.published && (
                                <Button
                                    backgroundColor='#fff'
                                    width='auto'
                                    padding='8px 16px'
                                    title='Modifier'
                                    style={{
                                        borderRadius: "28px",
                                        boxShadow:
                                            "1px 1px 4px 0px rgba(17, 16, 110, 0.10)",
                                        marginRight: "18px",
                                    }}
                                    onClick={() => setOpenEdit(true)}
                                >
                                    <EditSVG />
                                </Button>
                            )}
                            {planning?.planningInfo !== null && (
                                <Button
                                    backgroundColor='#fff'
                                    width='auto'
                                    padding='8px 16px'
                                    title='Dupliquer'
                                    style={{
                                        borderRadius: "28px",
                                        boxShadow:
                                            "1px 1px 4px 0px rgba(17, 16, 110, 0.10)",
                                    }}
                                    onClick={() => setOpenDuplicate(true)}
                                >
                                    <CopySVG />
                                </Button>
                            )}
                            */}
                        </div>
                        <div className="date-info">
                            {period !== "month" && (
                                <div
                                    className="date-range"
                                    // ref={calenderWrapperRef}
                                >
                                    <ChevronSVG
                                        style={{
                                            cursor: "pointer",
                                            minHeight: "30px",
                                            minWidth: "30px",
                                        }}
                                        height={30}
                                        width={30}
                                        onClick={handleStep.bind(null, -1)}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <TextData
                                            color="#2A346D"
                                            size="16px"
                                            fontWeight="600"
                                            style={{
                                                fontFamily: "DM Sans",
                                                cursor: "pointer",
                                                textTransform:
                                                    period === "day"
                                                        ? "capitalize"
                                                        : "initial",
                                            }}
                                            onClick={(e) =>
                                                setShowDatePicker(
                                                    (prev) => !prev,
                                                )
                                            }
                                        >
                                            {period === "week"
                                                ? getPlanningCustomFormattedWeekNumberAndYear(
                                                      planningStartOfWeek,
                                                      "YYYY-MM-DD",
                                                  )
                                                : moment(
                                                      field.value,
                                                      "YYYY-MM-DD",
                                                  ).format("dddd D MMMM")}
                                        </TextData>
                                        <TextData
                                            color="#6F78AB"
                                            size="12px"
                                            fontWeight="400"
                                            style={{
                                                fontFamily: "DM Sans",
                                                cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                                setShowDatePicker(
                                                    (prev) => !prev,
                                                )
                                            }
                                        >
                                            Semaine {week} - {year}
                                        </TextData>
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "calc(100% + 10px)",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            borderRadius: "4px",
                                            boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                        }}
                                        className={`fixed   z-50`}
                                    >
                                        <Calendar
                                            calenderContainerClasses="border _shaddow"
                                            open={showDatePicker}
                                            close={setShowDatePicker.bind(
                                                null,
                                                false,
                                            )}
                                            onChange={(value: Moment) => {
                                                const stepWeek = value
                                                    .startOf("week")
                                                    .diff(
                                                        moment().startOf(
                                                            "week",
                                                        ),
                                                        "week",
                                                    );
                                                setStep(stepWeek);
                                                setDateField((prev: any) => ({
                                                    ...prev,
                                                    value: value,
                                                }));
                                                setShowDatePicker(false);
                                            }}
                                            value={moment(
                                                field.value &&
                                                    field.value !== ""
                                                    ? moment(field.value)
                                                    : undefined,
                                            )}
                                            showWeekCount={true}
                                        />
                                    </div>
                                    <ChevronSVG
                                        style={{
                                            transform: "rotate(180deg)",
                                            cursor: "pointer",
                                            minHeight: "30px",
                                            minWidth: "30px",
                                        }}
                                        height={30}
                                        width={30}
                                        onClick={handleStep.bind(null, 1)}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="view-buttons">
                            <ReportDisplayOption
                                options={options}
                                handleOptionChange={handleOptionChange}
                            />
                            <Button
                                padding="8px 16px"
                                width="80px"
                                textColor={
                                    period === "day" ? "white" : "#6f78ab"
                                }
                                backgroundColor={
                                    period === "day" ? "#6f78ab" : "white"
                                }
                                height="32px"
                                style={{
                                    border: "0.5px solid #6f78ab",
                                    borderRight: "0px none",
                                    borderRadius: " 4px 0px 0px 4px",
                                }}
                                onClick={() => setPeriod("day")}
                            >
                                <TextData
                                    color={
                                        period === "day" ? "white" : "#6f78ab"
                                    }
                                    size="12px"
                                    fontWeight="500"
                                    width="auto"
                                >
                                    Jour
                                </TextData>
                            </Button>

                            <Button
                                padding="8px 16px"
                                width="80px"
                                textColor={
                                    period === "week" ? "white" : "#6f78ab"
                                }
                                backgroundColor={
                                    period === "week" ? "#6f78ab" : "white"
                                }
                                height="32px"
                                style={{
                                    border: "0.5px solid #6f78ab",
                                    // borderRadius: "0px",
                                    borderLeft: "none",
                                    borderRadius: "0px 4px 4px 0px",
                                }}
                                onClick={setPeriod.bind(null, "week")}
                            >
                                <TextData
                                    color={
                                        period === "week" ? "white" : "#6f78ab"
                                    }
                                    size="12px"
                                    fontWeight="500"
                                    width="auto"
                                >
                                    Semaine
                                </TextData>
                            </Button>
                            {/* <Button
                                padding="8px 16px"
                                width="80px"
                                textColor={
                                    period === "month" ? "white" : "#6f78ab"
                                }
                                backgroundColor={
                                    period === "month" ? "#6f78ab" : "white"
                                }
                                height="32px"
                                style={{
                                    border: "0.5px solid #6f78ab",
                                    borderLeft: "none",
                                    borderRadius: "0px 4px 4px 0px",
                                }}
                                onClick={() => setPeriod("month")}
                            >
                                <TextData
                                    color={
                                        period === "month" ? "white" : "#6f78ab"
                                    }
                                    size="12px"
                                    fontWeight="500"
                                    width="auto"
                                >
                                    Mois
                                </TextData>
                            </Button> */}
                        </div>
                    </div>
                </th>
            </tr>

            <tr className="table-subheader-container">
                {period === "week"
                    ? TheWeekDays?.map((day, key) => {
                          return (
                              <th
                                  key={key}
                                  id={`col-${key}`}
                                  style={{
                                      width: `${
                                          (100 - widthPercentage - 15) /
                                          TheWeekDays.length
                                      }%`,
                                  }}
                              >
                                  <TextData
                                      variant="div"
                                      width=""
                                      style={{
                                          padding: "7px 0",
                                          textTransform: "capitalize",
                                          color: `${
                                              moment(day).format(
                                                  "YYYY-MM-DD",
                                              ) === selectedDay
                                                  ? "#2A8BAB"
                                                  : "#2A346D"
                                          }`,
                                      }}
                                      size="14px"
                                      fontWeight="600"
                                      color="#2A346D"
                                  >
                                      {/* {getPlanningCustomFormattedDate(day)} */}
                                      {moment(day).format("dddd")}{" "}
                                      {moment(day).format("YYYY-MM-DD") ===
                                      selectedDay ? (
                                          <span
                                              style={{
                                                  color: "#FFF",
                                                  backgroundColor: "#2A8BAB",
                                                  padding: "2px",
                                                  borderRadius: "3px",
                                              }}
                                          >
                                              {moment(day).format("D")}
                                          </span>
                                      ) : (
                                          moment(day).format("D")
                                      )}
                                  </TextData>
                              </th>
                          );
                      })
                    : !planningLoading &&
                      period === "day" && (
                          <th colSpan={7}>
                              <div className="day-hour-parent">
                                  {/* <div
                                      className='opening-time'
                                      style={{
                                          left: `calc(calc(100% / ${
                                              4 * numberOfHourCellsInDay
                                          }) * ${
                                              (openingTime -
                                                  startCellHour * 60 +
                                                  30) /
                                              15
                                          })`,
                                      }}
                                  >
                                      {fomatStartEndTime(openingTime)}
                                  </div>
                                  <div
                                      className='time-range-line'
                                      style={{
                                          width: `calc(calc(100% / ${
                                              4 * numberOfHourCellsInDay
                                          }) * ${
                                              (closingTime - openingTime) / 15
                                          })`,
                                          left: `calc(calc(calc(100% / ${
                                              4 * numberOfHourCellsInDay
                                          }) * ${
                                              (openingTime -
                                                  startCellHour * 60 +
                                                  30) /
                                              15
                                          }) - 1px)`,
                                      }}
                                  ></div>
                                  <div
                                      className='closing-time'
                                      style={{
                                          left: `calc(calc(calc(100% / ${
                                              4 * numberOfHourCellsInDay
                                          }) * ${
                                              (openingTime -
                                                  startCellHour * 60 +
                                                  30) /
                                              15
                                          }) + calc(calc(100% / ${
                                              4 * numberOfHourCellsInDay
                                          }) * ${
                                              (closingTime - openingTime) / 15
                                          }))`,
                                      }}
                                  >
                                      {fomatStartEndTime(closingTime)}
                                  </div> */}

                                  {thisTimeByMinutes >
                                      startCellHour * 60 - 30 &&
                                      thisTimeByMinutes <
                                          (startCellHour +
                                              numberOfHourCellsInDay) *
                                              60 -
                                              30 && ( // 07h in minutes
                                          <>
                                              <div
                                                  className="time-line-circle"
                                                  style={{
                                                      left: `calc(calc(calc(100% / ${
                                                          4 *
                                                          numberOfHourCellsInDay
                                                      }) * ${
                                                          (thisTimeByMinutes -
                                                              startCellHour *
                                                                  60 +
                                                              30) /
                                                          15
                                                      }) - 5px)`,
                                                  }}
                                              ></div>
                                              <div
                                                  className="time-line"
                                                  style={{
                                                      height: `calc(100vh - ${
                                                          !isFullScreen
                                                              ? 235
                                                              : 175
                                                      }px)`,
                                                      left: `calc(calc(calc(100% / ${
                                                          4 *
                                                          numberOfHourCellsInDay
                                                      }) * ${
                                                          (thisTimeByMinutes -
                                                              startCellHour *
                                                                  60 +
                                                              30) /
                                                          15
                                                      }) - 1px)`,
                                                  }}
                                              ></div>
                                          </>
                                      )}
                                  {Array(numberOfHourCellsInDay)
                                      .fill(0)
                                      .map((item, index) => (
                                          <div
                                              className="day-hour-child"
                                              style={{
                                                  width: `calc(100% / ${numberOfHourCellsInDay})`,
                                              }}
                                          >
                                              {/* <div className='time-range-line'></div> */}
                                              <div className="day-hour-lines">
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                              </div>
                                              <div>
                                                  {/* {(startCellHour + index) * 60} */}
                                                  {(startCellHour + index) *
                                                      60 ===
                                                  0
                                                      ? "00h"
                                                      : formatTime(
                                                            ((startCellHour +
                                                                index) *
                                                                60) %
                                                                1440,
                                                            true,
                                                            true,
                                                        )}
                                              </div>
                                          </div>
                                      ))}
                              </div>
                          </th>
                      )}
            </tr>
        </>
    );
}
PlanningTableHeader.defaultProps = {};
