import React from "react";
import "./style.scss";
// import Holidays from "../../../components/templates/EmployeesEdit/Holidays";
// import { useEmployees } from "../../../hooks/useEmployees";
import TextData from "../../../components/atoms/TextData";
import Button from "../../../components/atoms/Button";
import { ReactComponent as CalendarSVG } from "./calendar.svg";
import { ReactComponent as TickSVG } from "./tick-valid.svg";
import { ReactComponent as DeclineSVG } from "./decline.svg";
import { ReactComponent as Warn } from "./warn.svg";
import Select from "react-select";
import SelectInput from "../../../components/atoms/SelectInput";
import moment from "moment";
import ColoredCircle from "../../../components/atoms/ColoredCircle";
import { useLeaveBalance } from "../../../hooks/useLeaveBalance";
import { useEmployees } from "../../../hooks/useEmployees";
import { useHoliday } from "../../../hooks/useHolidays";
import Loading from "../../../components/atoms/Loading";
import { congeTypes } from "../../../_helpers/Functions";

// TOFIX: next time we'll clean all this stuff and their dependencies
// export interface FilterProps {
//     year?: string;
//     type: "pending" | "accepted" | "rejected";
// }
// export default function HolidaysPage() {
// const { getEmployeeHolidays, employeeForEdit, holidays } = useEmployees();

// const [filter, setFilter] = React.useState<FilterProps>(() => ({
//     type: "pending",
//     year: undefined,
// }));

// const handeFilterUpdate = (vals: FilterProps) => {
//     setFilter(vals);
// };

// React.useEffect(() => {
//     //getEmployeeHolidays({ employeeId: employeeForEdit._id, year: filter.year });
// }, [filter.year]);
// return (
// <Holidays
//   holidays={holidays}
//   filter={filter}
//   onFilterUpdate={handeFilterUpdate}
// />
// );
// }

const TimingComponent = (props: any) => {
    return (
        <div className="timing-container">
            <div className="timing-decorator" style={{ display: "flex" }}>
                <ColoredCircle
                    color="#2A8BAB"
                    size={3}
                    outlineSize={3}
                    outlineColor="#A6D3E2"
                    isChecked={true}
                />
                <TextData
                    width="auto"
                    variant="label"
                    style={{
                        whiteSpace: "nowrap",
                        background:
                            "linear-gradient(90deg, #2a8bab 0%, #2d2aad 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                    }}
                >
                    <pre>--------</pre>
                </TextData>
                <ColoredCircle
                    color="#2D2AAD"
                    size={3}
                    outlineSize={3}
                    outlineColor="#A8A7E4"
                    isChecked={true}
                />
            </div>
        </div>
    );
};
export default function HolidaysPage() {
    const { employeeForEdit } = useEmployees();
    const [timeOffBalance, setTimeOffBalance] = React.useState({
        earnedLeave: 0,
        takenLeave: 0,
        remainingLeave: 0,
    });
    const [selectedYear, setSelectedYear] = React.useState(moment().year());
    const { holidays, userHolidays, getUserHistory } = useHoliday();
    const {
        setSelectedUserLeaveBalanceHistory,
        getUserLeaveBalanceHistory,
        UserLeaveBalanceHistory,
    } = useLeaveBalance();

    React.useEffect(() => {
        if (employeeForEdit._id) {
            setSelectedUserLeaveBalanceHistory(employeeForEdit._id);
        }
    }, []);

    React.useEffect(() => {
        if (UserLeaveBalanceHistory.userID) {
            getUserLeaveBalanceHistory();
        }
    }, [UserLeaveBalanceHistory.userID]);

    React.useEffect(() => {
        let balance = {
            earnedLeave: 0,
            takenLeave: 0,
            remainingLeave: 0,
        };
        if (UserLeaveBalanceHistory?.history) {
            const { earnedLeave, takenLeave } =
                UserLeaveBalanceHistory.history.reduce(
                    (accumulator: any, leaveItem: any) => {
                        if (leaveItem.leaveCount > 0) {
                            accumulator.earnedLeave += leaveItem.leaveCount;
                        } else {
                            accumulator.takenLeave -= leaveItem.leaveCount;
                        }
                        return accumulator;
                    },
                    { earnedLeave: 0, takenLeave: 0 },
                );
            balance.earnedLeave = earnedLeave;
            balance.takenLeave = takenLeave;
            balance.remainingLeave = earnedLeave - takenLeave;
        }
        setTimeOffBalance(balance);
    }, [UserLeaveBalanceHistory.history]);

    const [statusBtn, setStatusBtn] = React.useState([
        {
            textValue: "Tous",
            selected: true,
        },
        {
            textValue: "En attente",
            selected: false,
        },
        {
            textValue: "Approuvé",
            selected: false,
        },
        {
            textValue: "Rejeté",
            selected: false,
        },
    ]);
    React.useEffect(() => {
        if (employeeForEdit._id) {
            const startYear = moment(selectedYear, "YYYY-MM-DD")
                .startOf("year")
                .toDate();
            const endYear = moment(selectedYear, "YYYY-MM-DD")
                .endOf("year")
                .toDate();
            getUserHistory(employeeForEdit._id, startYear, endYear);
        }
    }, [employeeForEdit._id, selectedYear]);

    // =========== > Components
    const StatusFilterElement = () => {
        const handleClick = (e: any) => {
            const status = parseInt(e.target.id);
            setStatusBtn(
                statusBtn.map((value, index) => ({
                    ...value,
                    selected: index === status,
                })),
            );
        };
        return (
            <div className="status-filter-container">
                {statusBtn.map((buttton, index) => {
                    return (
                        <Button
                            width="100px"
                            onClick={handleClick}
                            id={`${index}`}
                            key={index}
                            active={buttton.selected}
                            backgroundColor={
                                buttton.selected ? "#EDEFF5" : "#FFFFFF"
                            }
                            textColor={
                                buttton.selected ? "#101844" : "#1018447f"
                            }
                        >
                            {buttton.textValue}
                        </Button>
                    );
                })}
            </div>
        );
    };
    const YearFilterElement = () => {
        const currentYear = selectedYear;
        const startYear = currentYear + 1;
        const endYear = 2018;

        const years = Array.from(
            { length: startYear - endYear + 1 },
            (_, index) => ({
                label: startYear - index,
                value: startYear - index,
            }),
        );
        const handleItemSelection = (data: any) => {
            setSelectedYear(data.value);
        };
        return (
            <div className="year-filter-container">
                <SelectInput
                    id="branchSelection"
                    isSearchable={false}
                    options={years}
                    menuPlacement="auto"
                    selectedValue={{ label: selectedYear, value: selectedYear }}
                    title=""
                    style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 8,
                        border: "1px solid #BABFDB",
                        width: "auto",
                        minWidth: "100px",
                        color: "#2A346D",
                    }}
                    styleOption={{
                        color: "#2A346D",
                    }}
                    onSelectOption={handleItemSelection}
                />
            </div>
        );
    };
    const LeaveHistoryContent = () => {
        let dataBasedOnStatus = [
            ...userHolidays.enAttente,
            ...userHolidays.valide,
            ...userHolidays.refuse,
        ];
        const statusMessages = {
            enAttente: "Aucune demande de congé en attente.",
            valide: "Aucune demande de congé approuvée.",
            refuse: "Aucune demande de congé rejetée.",
        };
        let noDataMessage = "Aucun historique de congés disponible.";
        if (statusBtn[1].selected) {
            dataBasedOnStatus = userHolidays.enAttente;
            noDataMessage = statusMessages.enAttente;
        } else if (statusBtn[2].selected) {
            dataBasedOnStatus = userHolidays.valide;
            noDataMessage = statusMessages.valide;
        } else if (statusBtn[3].selected) {
            dataBasedOnStatus = userHolidays.refuse;
            noDataMessage = statusMessages.refuse;
        }

        const res = dataBasedOnStatus.map((leave, index) => {
            const fromDate = moment(leave.fromDay).format("DD/MM/YYYY");
            const endDate = moment(leave.toDay).format("DD/MM/YYYY");
            const defineType = (type: any) => {
                if (typeof type === "string") {
                    return type;
                } else if (typeof type === "object") {
                    return type.designation;
                } else {
                    return "-------";
                }
            };
            const typeConge = defineType(leave?.type);

            const type = congeTypes?.find(
                (type) => type.value === typeConge?.toLowerCase()?.trim(),
            );
            const statusCheck = () => {
                switch (leave.status) {
                    case 0:
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#facc1559",
                                    height: "24px",
                                    width: "120px",
                                    padding: "6px 7px",
                                    borderRadius: "6px",
                                }}
                            >
                                <Warn
                                    color="#eab308"
                                    width="30px"
                                    height="16px"
                                />
                                <TextData
                                    variant="div"
                                    size="13px"
                                    color="#eab308"
                                    fontWeight="600"
                                >
                                    En Attente
                                </TextData>
                            </div>
                        );
                    case 1:
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#21cb881a",
                                    height: "24px",
                                    width: "120px",
                                    padding: "6px 7px",
                                    borderRadius: "6px",
                                }}
                            >
                                <TickSVG
                                    width="16px"
                                    height="16px"
                                    style={{ marginRight: 5 }}
                                />
                                <TextData
                                    variant="div"
                                    size="13px"
                                    color="#15803d"
                                    fontWeight="600"
                                >
                                    Approuvé
                                </TextData>
                            </div>
                        );
                    case 2:
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#fb364033",
                                    height: "24px",
                                    width: "120px",
                                    padding: "6px 7px",
                                    borderRadius: "6px",
                                }}
                            >
                                <DeclineSVG
                                    color="#E4514D"
                                    width="30px"
                                    height="16px"
                                />
                                <TextData
                                    variant="div"
                                    size="13px"
                                    color="#E4514D"
                                    fontWeight="600"
                                >
                                    Rejeté
                                </TextData>
                            </div>
                        );

                    default:
                        return "Unconnu";
                }
            };
            const fromDatePeriodIdentification = (
                fromDate: any,
                fromHour: any,
            ) => {
                const combinedDateTime = moment(
                    `${fromDate} ${fromHour}`,
                    "YYYY-MM-DD HH:mm",
                );
                const hourOfDay = combinedDateTime.hour();
                if (hourOfDay >= 0 && hourOfDay < 12) {
                    return "Matin";
                } else if (hourOfDay >= 12 && hourOfDay < 18) {
                    return "Après-midi";
                } else if (hourOfDay > 18) {
                    return "Soir";
                } else {
                    return "";
                }
            };
            const calculatePeriod = (
                fromDay: any,
                fromHour: any,
                toDay: any,
                toHour: any,
            ) => {
                const fromDate = moment(
                    `${fromDay} ${fromHour}`,
                    "DD/MM/YYYY HH:mm",
                );
                const toDate = moment(`${toDay} ${toHour}`, "DD/MM/YYYY HH:mm");

                const hoursDiff = toDate.diff(fromDate, "hours");

                // Convert hours to days
                const days = Math.round((hoursDiff / 24) * 2) / 2;

                return days;
            };
            const status = statusCheck();
            return (
                <div
                    className="history-item-container align-center"
                    key={index}
                >
                    <div
                        className="history-created-date-container "
                        style={{ width: "10%" }}
                    >
                        <TextData color="#2A346D" size="12px" fontWeight="400">
                            Ajouter le :
                        </TextData>
                        <TextData color="#6F78AB" size="14px" fontWeight="500">
                            {moment(leave.created_at).format("DD/MM/YYYY")}
                        </TextData>
                    </div>
                    <div
                        className="history-date-container justify-center"
                        style={{ width: "50%" }}
                    >
                        <div>
                            <TextData
                                color="#2A346D"
                                size="14px"
                                fontWeight="700"
                            >
                                {fromDate}
                            </TextData>
                            <TextData
                                color="#2A346D"
                                size="12px"
                                fontWeight="400"
                            >
                                {fromDatePeriodIdentification(
                                    fromDate,
                                    leave.fromHour,
                                )}
                            </TextData>
                        </div>
                        <div>
                            <TimingComponent />
                        </div>
                        <div>
                            <TextData
                                color="#2A346D"
                                size="14px"
                                fontWeight="700"
                            >
                                {endDate}
                            </TextData>
                            <TextData
                                color="#2A346D"
                                size="12px"
                                fontWeight="400"
                            >
                                {fromDatePeriodIdentification(
                                    endDate,
                                    leave.toHour,
                                )}
                            </TextData>
                        </div>
                    </div>

                    <div className="text-center " style={{ width: "20%" }}>
                        <TextData size="14px" color="#3FA0C1" fontWeight="700">
                            {type ? type?.name : "pas de type"}
                        </TextData>
                        <TextData size="12px" color="#2A8BAB" fontWeight="400">
                            {calculatePeriod(
                                fromDate,
                                leave.fromHour,
                                endDate,
                                leave.toHour,
                            )}{" "}
                            {calculatePeriod(
                                fromDate,
                                leave.fromHour,
                                endDate,
                                leave.toHour,
                            ) > 1
                                ? "jours"
                                : "jour"}
                        </TextData>
                    </div>
                    <div className="flex justify-end" style={{ width: "20%" }}>
                        {status}
                    </div>
                </div>
            );
        });
        if (res.length > 0) {
            return <div className="history-info-container">{res}</div>;
        } else {
            return (
                <div className="history-info-container">
                    <TextData size="17px">{noDataMessage}</TextData>
                </div>
            );
        }
    };

    return (
        <div className="vacation-page-container">
            <div className="vacation-history-container">
                {/* page d'info des conges  */}
                <div className="vacation-filter-container">
                    {/*  partie header */}
                    <StatusFilterElement />
                    <YearFilterElement />
                </div>
                {/*  partie contenu */}
                {holidays.loading ? (
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "50vh",
                        }}
                    >
                        <Loading />
                    </div>
                ) : (
                    <LeaveHistoryContent />
                )}
            </div>
            <div className="leave-balance-container">
                {/*  partie compteur des conges */}
                <div className="leave-balance-box">
                    <div className="leave-balance-header">
                        <CalendarSVG />
                        <TextData
                            variant="div"
                            width="auto"
                            size="22px"
                            color="#2A346D"
                            fontWeight="700"
                        >
                            Compteur des congés
                        </TextData>
                    </div>
                    <div className="leave-balance-content">
                        <div className="leave-balance-info-box">
                            <TextData
                                variant="div"
                                color="#2A346D"
                                size="14px"
                                fontWeight="700"
                            >
                                Acquis
                            </TextData>
                            <TextData
                                variant="div"
                                size="16px"
                                color="#2A346D"
                                fontWeight="400"
                                style={{
                                    backgroundColor: "#6628f10d",
                                    padding: "5px 12px",
                                    borderRadius: "8px",
                                }}
                            >
                                <b>{timeOffBalance.earnedLeave}</b> jrs
                            </TextData>
                        </div>
                        <div className="leave-balance-info-box">
                            <TextData
                                variant="div"
                                color="#2A346D"
                                size="14px"
                                fontWeight="700"
                            >
                                Pris
                            </TextData>
                            <TextData
                                variant="div"
                                size="16px"
                                color="#2A346D"
                                fontWeight="400"
                                style={{
                                    backgroundColor: "#FB36400d",
                                    padding: "5px 12px",
                                    borderRadius: "8px",
                                }}
                            >
                                <b>{timeOffBalance.takenLeave}</b> jrs
                            </TextData>
                        </div>
                        <div className="leave-balance-info-box">
                            <TextData
                                variant="div"
                                color="#2A346D"
                                size="14px"
                                fontWeight="700"
                            >
                                Restant
                            </TextData>
                            <TextData
                                variant="div"
                                size="16px"
                                color="#2A346D"
                                fontWeight="400"
                                style={{
                                    backgroundColor: "#00A42E0d",
                                    padding: "5px 12px",
                                    borderRadius: "8px",
                                }}
                            >
                                <b>{timeOffBalance.remainingLeave}</b> jrs
                            </TextData>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
